import {Component} from '@angular/core';
import {DashboardService} from '../../../services/api/dashboard.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent {
  constructor(public dashboard: DashboardService) {
    this.dashboard.loadAllProducts();
    this.dashboard.loadAllFiles();
    this.dashboard.loadAllTaxClasses();
    this.dashboard.loadAllCategories();
    this.dashboard.loadAllAddonLists();
    this.dashboard.loadAllAdditives();
  }
}
