import {Component} from '@angular/core';
import {DashboardService} from '../../../services/api/dashboard.service';

@Component({
  selector: 'app-shipping-methods',
  templateUrl: './shipping-methods.component.html',
  styleUrls: ['./shipping-methods.component.css']
})
export class ShippingMethodsComponent {
  constructor(public dashboard: DashboardService) {
    this.dashboard.loadAllShippingMethods();
    this.dashboard.loadAllShippingAreas();
  }
}
