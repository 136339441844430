import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Shop, ShopUpdate} from '../../models/shop.model';
import {Response} from '../../models/response.model';
import {Category, CategoryCreate} from '../../models/category.model';
import {ApiConfig} from './api.config';
import {catchError, first} from 'rxjs/operators';
import {TaxClass} from '../../models/taxclass.model';
import {CodeList} from '../../models/codelist.model';
import {ShippingMethod} from '../../models/shippingmethod.model';
import {Product} from '../../models/product.model';
import {PaymentMethod} from '../../models/payment-method.model';
import {Fee} from '../../models/fee.model';
import {Order} from '../../models/order.model';
import {ShopHour} from '../../models/shophour.model';
import {ToastrService} from 'ngx-toastr';
import {ImageFile} from '../../models/file.model';
import {Addon, AddonList} from "../../models/addon.model";
import {Additive} from "../../models/additive.model";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  sub: any;
  selectedShop: Shop;

  orders: Order[] = [];
  additives: Additive[] = [];
  products: Product[] = [];
  categories: Category[] = [];
  addonLists: AddonList[] = [];
  taxClasses: TaxClass[] = [];
  shippingMethods: ShippingMethod[] = [];
  shippingAreas: CodeList[] = [];
  paymentMethods: PaymentMethod[] = [];
  paymentFees: Fee[] = [];
  files: ImageFile[] = [];

  responses = {
    metadata: false,
    files: false,
    orders: false,
    additives: false,
    products: false,
    categories: false,
    addonLists: false,
    taxClasses: false,
    shippingMethods: false,
    shippingAreas: false,
    paymentMethods: false,
    paymentFees: false
  };

  constructor(private http: HttpClient, private config: ApiConfig, private alert: ToastrService) {
  }

  get currencySymbol() {
    return this.selectedShop?.currencySymbol ?? '';
  }

  dashboardChange() {
    this.responses = {
      metadata: false,
      files: false,
      orders: false,
      additives: false,
      products: false,
      categories: false,
      addonLists: false,
      taxClasses: false,
      shippingMethods: false,
      shippingAreas: false,
      paymentMethods: false,
      paymentFees: false
    };
  }

  loadAllFiles() {
    if (this.responses.files && !!this.files?.length) return;

    this.getAllFiles()
      .pipe(first())
      .subscribe(
        result => {
          this.responses.files = true;
          this.files = result.reverse();
        },
        error => {
          this.responses.files = true;
          this.alert.error(error);
          this.files = [];
        }
      );
  }

  loadAllPaymentFees() {
    if (this.responses.paymentFees && !!this.paymentFees?.length) return;

    this.getAllPaymentFees()
      .pipe(first())
      .subscribe(
        result => {
          this.paymentFees = result;
          this.responses.paymentFees = true;
        },
        error => {
          this.alert.error(error);
          this.responses.paymentFees = true;
          this.paymentFees = [];
        }
      );
  }

  loadAllAddonLists() {
    if (this.responses.addonLists && !!this.addonLists?.length) return;

    this.getAllAddonLists()
      .pipe(first())
      .subscribe(
        result => {
          this.addonLists = result;
          this.responses.addonLists = true;
        },
        error => {
          this.alert.error(error);
          this.responses.addonLists = true;
          this.addonLists = [];
        }
      );
  }

  loadAllTaxClasses() {
    if (this.responses.taxClasses && !!this.taxClasses?.length) return;

    this.getAllTaxClasses()
      .pipe(first())
      .subscribe(
        result => {
          this.taxClasses = result;
          this.responses.taxClasses = true;
        },
        error => {
          this.alert.error(error);
          this.responses.taxClasses = true;
          this.taxClasses = [];
        }
      );
  }

  loadAllShippingAreas() {
    if (this.responses.shippingAreas && !!this.shippingAreas?.length) return;

    this.getAllCodeLists()
      .pipe(first())
      .subscribe(
        result => {
          this.shippingAreas = result;
          this.responses.shippingAreas = true;
        },
        error => {
          this.alert.error(error);
          this.responses.shippingAreas = true;
          this.shippingAreas = [];
        }
      );
  }

  loadAllOrders() {
    if (this.responses.orders && !!this.orders?.length) return;

    this.getAllOrders()
      .pipe(first())
      .subscribe(
        result => {
          this.orders = result;
          this.responses.orders = true;
        },
        error => {
          this.alert.error(error);
          this.responses.orders = true;
          this.orders = [];
        }
      );
  }

  loadAllAdditives() {
    if (this.responses.additives && !!this.additives?.length) return;

    this.getAllAdditives()
      .pipe(first())
      .subscribe(
        result => {
          this.additives = result;
          this.responses.additives = true;
        },
        error => {
          this.alert.error(error);
          this.responses.additives = true;
          this.additives = [];
        }
      );
  }

  loadAllProducts() {
    if (this.responses.products && !!this.products?.length) return;

    this.getAllProducts()
      .pipe(first())
      .subscribe(
        result => {
          this.products = result;
          this.responses.products = true;
        },
        error => {
          this.alert.error(error);
          this.responses.products = true;
          this.products = [];
        }
      );
  }

  loadAllCategories() {
    if (this.responses.categories && !!this.categories?.length) return;

    this.getAllCategories()
      .pipe(first())
      .subscribe(
        result => {
          this.categories = result;
          this.responses.categories = true;
        },
        error => {
          this.alert.error(error);
          this.responses.categories = true;
          this.categories = [];
        }
      );
  }

  loadAllShippingMethods() {
    if (this.responses.shippingMethods && !!this.shippingMethods?.length) return;

    this.getAllShippingMethods()
      .pipe(first())
      .subscribe(
        result => {
          this.shippingMethods = result;
          this.responses.shippingMethods = true;
        },
        error => {
          this.alert.error(error);
          this.responses.shippingMethods = true;
          this.shippingMethods = [];
        }
      );
  }

  loadAllPaymentMethods() {
    if (this.responses.paymentMethods && !!this.paymentMethods?.length) return;

    this.getAllPaymentMethods()
      .pipe(first())
      .subscribe(
        result => {
          this.paymentMethods = result;
          this.responses.paymentMethods = true;
        },
        error => {
          this.alert.error(error);
          this.responses.paymentMethods = true;
          this.paymentMethods = [];
        }
      );
  }

  getDay(input): string {
    switch (input) {
      case '0':
        return 'Sonntag';
      case '1':
        return 'Montag';
      case '2':
        return 'Dienstag';
      case '3':
        return 'Mittwoch';
      case '4':
        return 'Donnerstag';
      case '5':
        return 'Freitag';
      case '6':
        return 'Samstag';
    }
  }

  filterArrayObjects(array: any[], search: string): any[] {
    let filtered = [];

    if (search === '') return array;

    for (let element of array) {
      if (typeof element === 'object' && JSON.stringify(element).includes(search) && !filtered.includes(element)) filtered.push(element);
    }

    return filtered;
  }

  /* Dashboard Shop Functions */

  updateShop(update: ShopUpdate) {
    return this.http.post<Shop>(this.config.apiUrl + 'shop/' + this.selectedShop.uuid + '/update/', update).pipe(catchError(this.config.handleError));
  }

  updateOpeningHour(update: ShopHour) {
    return this.http.post<ShopHour>(this.config.apiUrl + 'shop/' + this.selectedShop.uuid + '/update-hour/', update).pipe(catchError(this.config.handleError));
  }

  createCut(options) {
    return this.http.post<Response>(this.config.apiUrl + 'shop/' + this.selectedShop.uuid + '/create-cut/', options).pipe(catchError(this.config.handleError));
  }

  /* Dashboard Media Functions */
  getAllFiles() {
    return this.http.get<ImageFile[]>(this.config.apiUrl + 'file/list/' + this.selectedShop.uuid).pipe(catchError(this.config.handleError));
  }

  deleteFile(file: ImageFile) {
    return this.http.delete<ImageFile>(this.config.apiUrl + 'file/delete/' + file.uuid).pipe(catchError(this.config.handleError));
  }

  uploadFile(file) {
    const formData: FormData = new FormData();
    formData.append('upload', file, file.name);
    return this.http.post<ImageFile>(this.config.apiUrl + 'file/upload/' + this.selectedShop.uuid, formData).pipe(catchError(this.config.handleError));
  }


  /* Dashboard Order Functions */

  getAllOrders() {
    return this.http.get<Order[]>(this.config.apiUrl + 'shop/' + this.selectedShop.uuid + '/orders/').pipe(catchError(this.config.handleError));
  }

  updateOrderStatus(order) {
    return this.http.put<Order>(this.config.apiUrl + 'order/update/', order).pipe(catchError(this.config.handleError));
  }

  /* Dashboard PaymentFee Functions */

  getAllPaymentFees() {
    return this.http.get<Fee[]>(this.config.apiUrl + 'shop/' + this.selectedShop.uuid + '/fees/').pipe(catchError(this.config.handleError));
  }

  createPaymentFee(fee: Fee) {
    return this.http.post<Fee>(this.config.apiUrl + 'fee/create/', fee).pipe(catchError(this.config.handleError));
  }

  deletePaymentFee(fee: Fee) {
    return this.http.delete<Fee>(this.config.apiUrl + 'fee/delete/' + fee.uuid).pipe(catchError(this.config.handleError));
  }

  updatePaymentFee(fee: Fee) {
    return this.http.put<Fee>(this.config.apiUrl + 'fee/update/' + fee.uuid, fee).pipe(catchError(this.config.handleError));
  }

  /* Dashboard PaymentMethod Functions */

  getAllPaymentMethods() {
    return this.http.get<PaymentMethod[]>(this.config.apiUrl + 'shop/' + this.selectedShop.uuid + '/payment-methods/').pipe(catchError(this.config.handleError));
  }

  createPaymentMethod(method: PaymentMethod) {
    return this.http.post<PaymentMethod>(this.config.apiUrl + 'payment-method/create/', method).pipe(catchError(this.config.handleError));
  }

  deletePaymentMethod(method: PaymentMethod) {
    return this.http.delete<PaymentMethod>(this.config.apiUrl + 'payment-method/delete/' + method.uuid).pipe(catchError(this.config.handleError));
  }

  updatePaymentMethod(method: PaymentMethod) {
    return this.http.put<PaymentMethod>(this.config.apiUrl + 'payment-method/update/' + method.uuid, method).pipe(catchError(this.config.handleError));
  }

  /* Dashboard Product Functions */

  getAllProducts() {
    return this.http.get<Product[]>(this.config.apiUrl + 'shop/' + this.selectedShop.uuid + '/products/').pipe(catchError(this.config.handleError));
  }

  createProduct(product: Product) {
    return this.http.post<Product>(this.config.apiUrl + 'product/create/', product).pipe(catchError(this.config.handleError));
  }

  deleteProduct(product: Product) {
    return this.http.delete<Product>(this.config.apiUrl + 'product/delete/' + product.uuid).pipe(catchError(this.config.handleError));
  }

  updateProduct(product: Product) {
    return this.http.put<Product>(this.config.apiUrl + 'product/update/' + product.uuid, product).pipe(catchError(this.config.handleError));
  }

  /* Dashboard AddonList Functions */

  getAllAddonLists() {
    return this.http.get<AddonList[]>(this.config.apiUrl + 'shop/' + this.selectedShop.uuid + '/addonlists/').pipe(catchError(this.config.handleError));
  }

  createAddonList(list: AddonList) {
    return this.http.post<AddonList>(this.config.apiUrl + 'addonlist/create/', list).pipe(catchError(this.config.handleError));
  }

  updateAddonList(list: AddonList) {
    return this.http.put<AddonList>(this.config.apiUrl + 'addonlist/update/' + list.uuid, list).pipe(catchError(this.config.handleError));
  }

  deleteAddonList(list: Addon) {
    return this.http.delete<Addon>(this.config.apiUrl + 'addonlist/delete/' + list.uuid).pipe(catchError(this.config.handleError));
  }

  addAddonToAddonList(addon: Addon, list: AddonList) {
    return this.http.post<Addon>(this.config.apiUrl + 'addonlist/add/' + list.uuid, addon).pipe(catchError(this.config.handleError));
  }

  removeAddonFromAddonList(addon: Addon, list: AddonList) {
    return this.http.delete<Addon>(this.config.apiUrl + 'addonlist/remove/' + list.uuid + '/' + addon.uuid).pipe(catchError(this.config.handleError));
  }

  /* Dashboard ShippingMethod Functions */

  getAllShippingMethods() {
    return this.http.get<ShippingMethod[]>(this.config.apiUrl + 'shop/' + this.selectedShop.uuid + '/shipping-methods/').pipe(catchError(this.config.handleError));
  }

  createShippingMethod(method: ShippingMethod) {
    return this.http.post<ShippingMethod>(this.config.apiUrl + 'shipping-method/create/', method).pipe(catchError(this.config.handleError));
  }

  deleteShippingMethod(method: ShippingMethod) {
    return this.http.delete<ShippingMethod>(this.config.apiUrl + 'shipping-method/delete/' + method.uuid).pipe(catchError(this.config.handleError));
  }

  updateShippingMethod(method: ShippingMethod) {
    return this.http.put<ShippingMethod>(this.config.apiUrl + 'shipping-method/update/' + method.uuid, method).pipe(catchError(this.config.handleError));
  }

  /* Dashboard CodeList Functions */

  getAllCodeLists() {
    return this.http.get<CodeList[]>(this.config.apiUrl + 'shop/' + this.selectedShop.uuid + '/codelists/').pipe(catchError(this.config.handleError));
  }

  createCodeList(list: CodeList) {
    return this.http.post<CodeList>(this.config.apiUrl + 'codelist/create/', list).pipe(catchError(this.config.handleError));
  }

  updateCodeList(list: CodeList) {
    return this.http.put<CodeList>(this.config.apiUrl + 'codelist/update/' + list.uuid, list).pipe(catchError(this.config.handleError));
  }

  deleteCodeList(list: CodeList) {
    return this.http.delete<CodeList>(this.config.apiUrl + 'codelist/delete/' + list.uuid).pipe(catchError(this.config.handleError));
  }

  addCodeToCodeList(code: string, list: CodeList) {
    return this.http.post<CodeList>(this.config.apiUrl + 'codelist/add/' + list.uuid, {code: code}).pipe(catchError(this.config.handleError));
  }

  removeCodeFromCodeList(code: string, list: CodeList) {
    return this.http.delete<CodeList>(this.config.apiUrl + 'codelist/remove/' + list.uuid + '/' + code).pipe(catchError(this.config.handleError));
  }

  /* Dashboard TaxClass Functions */

  createTaxClass(taxclass: TaxClass) {
    return this.http.post<TaxClass>(this.config.apiUrl + 'taxclass/create/', taxclass).pipe(catchError(this.config.handleError));
  }

  updateTaxclass(taxclass: TaxClass) {
    return this.http.put<TaxClass>(this.config.apiUrl + 'taxclass/update/' + taxclass.uuid, taxclass).pipe(catchError(this.config.handleError));
  }

  deleteTaxClass(taxclass: TaxClass) {
    return this.http.delete<TaxClass>(this.config.apiUrl + 'taxclass/delete/' + taxclass.uuid).pipe(catchError(this.config.handleError));
  }

  getAllTaxClasses() {
    return this.http.get<TaxClass[]>(this.config.apiUrl + 'shop/' + this.selectedShop.uuid + '/taxclasses/').pipe(catchError(this.config.handleError));
  }


  /* Dashboard Category Functions */

  createCategory(category: CategoryCreate) {
    return this.http.post<Category>(this.config.apiUrl + 'category/create/', category).pipe(catchError(this.config.handleError));
  }

  updateCategory(uuid, category: CategoryCreate) {
    return this.http.put<Category>(this.config.apiUrl + 'category/update/' + uuid, category).pipe(catchError(this.config.handleError));
  }

  deleteCategory(category: Category) {
    return this.http.delete<Category>(this.config.apiUrl + 'category/delete/' + category.uuid).pipe(catchError(this.config.handleError));
  }

  getAllCategories() {
    return this.http.get<Category[]>(this.config.apiUrl + 'shop/' + this.selectedShop.uuid + '/categories/').pipe(catchError(this.config.handleError));
  }

  /* Dashboard Additives Functions */
  getAllAdditives(){
    return this.http.get<Additive[]>(this.config.apiUrl + 'shop/' + this.selectedShop.uuid + '/additives/').pipe(catchError(this.config.handleError));
  }

  deleteAdditive(additive: Additive){
    return this.http.delete<Additive>(this.config.apiUrl + 'additive/delete/' + additive.uuid).pipe(catchError(this.config.handleError));
  }

  createAdditive(additive: Additive){
    return this.http.post<Additive>(this.config.apiUrl + 'additive/create/', additive).pipe(catchError(this.config.handleError));
  }

  updateAdditive(additive: Additive){
    return this.http.put<Additive>(this.config.apiUrl + 'additive/update/' + additive.uuid, additive).pipe(catchError(this.config.handleError));
  }
}
