export class Additive {
  uuid: string;
  shop: string;
  name: string;
  allergen: boolean;

  constructor(uuid: string, shop: string, name: string, allergen: boolean) {
    this.uuid = uuid;
    this.shop = shop;
    this.name = name;
    this.allergen = allergen;
  }
}
