import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/api/auth.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  constructor(public auth: AuthService, private titleService: Title, private metaService: Meta) {
  }

  ngOnInit() {
    this.titleService.setTitle('Ordermotion | Account');
  }
}
