import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpInterceptor} from '@angular/common/http';
import {AuthService} from './auth.service';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private injector: Injector, private auth: AuthService) {
  }

  intercept(req, next) {
    const authService = this.injector.get(AuthService);
    const tokenizedRequest = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authService.getToken()}`
      }
    });

    return next.handle(tokenizedRequest).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 401) this.auth.logout();
        return throwError(response);
      }));
  }
}
