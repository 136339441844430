import {Component, Input, OnChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {first} from 'rxjs/operators';
import {PaymentMethod} from '../../../../models/payment-method.model';
import {Fee} from '../../../../models/fee.model';
import {ToastrService} from 'ngx-toastr';
import {updateObject} from '../../../../helper/object.helper';

@Component({
  selector: 'app-create-payment-method',
  templateUrl: './create-payment-method.component.html',
  styleUrls: ['./create-payment-method.component.css']
})
export class CreatePaymentMethodComponent implements OnChanges {
  @Input() selectedPaymentMethod: PaymentMethod;

  methodForm: UntypedFormGroup;
  loading = false;

  constructor(private router: Router, private formBuilder: UntypedFormBuilder, public dashboard: DashboardService, private alert: ToastrService) {
  }

  get form(): any {
    return this.methodForm.controls;
  }

  ngOnChanges(): void {
    this.methodForm = this.formBuilder.group({
      name: [this.selectedPaymentMethod?.name ?? '', Validators.required],
      description: [this.selectedPaymentMethod?.description ?? '', Validators.required],
      fee: [this.selectedPaymentMethod?.fee?.uuid ?? ''],
      paypal: [this.selectedPaymentMethod?.paypal ?? ''],
      client: [this.selectedPaymentMethod?.client ?? ''],
      secret: [this.selectedPaymentMethod?.secret ?? '']
    });
  }

  createPaymentMethod(): void {
    if (this.methodForm.invalid) return;

    this.loading = true;

    const paymentFee = new Fee(this.dashboard.selectedShop.uuid, this.form.fee.value, undefined, undefined, undefined, undefined);
    const update = new PaymentMethod(this.dashboard.selectedShop.uuid, this.selectedPaymentMethod?.uuid ?? undefined, this.form.name.value, this.form.description.value, paymentFee, !!this.form.paypal.value, this.form.client.value, this.form.secret.value);

    if (!this.selectedPaymentMethod) {
      this.dashboard.createPaymentMethod(update)
        .pipe(first())
        .subscribe(
          result => {
            this.loading = false;
            this.methodForm.reset();
            this.alert.success('Die Zahlungsmethode wurde erfolgreich erstellt.');
            this.dashboard.paymentMethods.push(result);
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    } else {
      this.dashboard.updatePaymentMethod(update)
        .pipe(first())
        .subscribe(
          result => {
            this.loading = false;
            updateObject(this.selectedPaymentMethod, result);
            this.alert.success('Die Zahlungsmethode wurde erfolgreich aktualisiert.');
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    }
  }
}
