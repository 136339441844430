import {Component} from '@angular/core';
import {DashboardService} from '../../../services/api/dashboard.service';

@Component({
  selector: 'app-fees',
  templateUrl: './fees.component.html',
  styleUrls: ['./fees.component.css']
})
export class FeesComponent {
  constructor(public dashboard: DashboardService) {
    this.dashboard.loadAllPaymentFees();
  }
}
