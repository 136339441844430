import {Component, OnInit} from '@angular/core';
import {ShopService} from '../../../../services/api/shop.service';

@Component({
  selector: 'app-legal-revocation',
  templateUrl: './legal-revocation.component.html',
  styleUrls: ['./legal-revocation.component.css']
})
export class LegalRevocationComponent implements OnInit {

  constructor(public shop: ShopService) {
  }

  ngOnInit(): void {
  }

}
