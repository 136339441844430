<form (ngSubmit)="addCodeToList()" [formGroup]="codeForm" class="row g-3">
  <div class="col-md-6">
    <div class="form-group">
      <input class="form-control" formControlName="code" placeholder="Postleitzahl" type="text">
    </div>
  </div>
  <div class="col-6">
    <button [ngClass]="{'disabled' : codeForm.invalid}" class="btn btn-success btn-block text-uppercase mb-2 shadow-sm"
            type="submit"><i class="fas fa-plus-circle"></i></button>
  </div>
</form>
<p *ngIf="!selectedList.codes.length" class="text-muted mb-2">Keine Postleitzahlen zugewiesen.</p>
<p *ngIf="selectedList.codes.length" class="text-muted mb-2">Zugewiesene Postleitzahlen</p>
<div *ngFor="let code of selectedList.codes;" class="btn-group me-2 mb-2" role="group">
  <button class="btn btn-sm btn-light" type="button">{{code}}</button>
  <button (click)="removeCodeFromList(code)" class="btn btn-sm btn-danger" type="button"><i class="fas fa-trash"></i>
  </button>
</div>



