import {Component} from '@angular/core';
import {ShopService} from '../../../services/api/shop.service';

@Component({
  selector: 'app-configuration-error',
  templateUrl: './configuration-error.component.html',
  styleUrls: ['./configuration-error.component.css']
})
export class ConfigurationErrorComponent {
  constructor(public shop: ShopService) {
  }
}
