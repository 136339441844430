import {Component} from '@angular/core';
import {DashboardService} from '../../../services/api/dashboard.service';

@Component({
  selector: 'app-addons',
  templateUrl: './addons.component.html',
  styleUrls: ['./addons.component.css']
})
export class AddonsComponent {
  constructor(public dashboard: DashboardService) {
    this.dashboard.loadAllAddonLists();
  }
}
