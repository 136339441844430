<app-loading *ngIf="loading; else updateUserTemplate"></app-loading>
<ng-template #updateUserTemplate>
  <div class="row">
    <div class="col-12">
      <h3 class="fw-light">Benutzer Bearbeiten</h3>
      <p class="text-muted mb-4">Bearbeiten Sie den Benutzer "{{selectedUser?.name}} {{selectedUser?.surname}}".</p>
    </div>
  </div>
  <form (ngSubmit)="updateUser()" [formGroup]="userForm" class="row g-3">
    <div class="col-md-6">
      <div class="form-floating">
        <input class="form-control" formControlName="name" type="text">
        <label>Vorname</label>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-floating">
        <input class="form-control" formControlName="surname" type="text">
        <label>Nachname</label>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-floating">
        <input class="form-control" formControlName="email" type="text">
        <label>E-Mail</label>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-floating">
        <input class="form-control" formControlName="phone" type="text">
        <label>Telefonnummer</label>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="form-floating">
        <select class="form-select" formControlName="type">
          <option value="">Auswählen...</option>
          <option [ngValue]="'admin'" [selected]="selectedUser?.type === 'admin'">Administrator</option>
          <option [ngValue]="'user'" [selected]="selectedUser?.type === 'user'">Benutzer</option>
        </select>
        <label>Benutzertyp</label>
      </div>
    </div>
    <div class="col-md-6">
      <input autocomplete="off" class="btn-check" formControlName="active" id="active" type="checkbox">
      <label class="btn btn-outline-success w-100" for="active"><i class="fas fa-check-circle"></i> Aktiviert</label>
    </div>
    <div class="col-12">
      <a class="btn btn-danger btn-block text-uppercase mb-2 py-2 shadow-sm float-end"
         data-bs-dismiss="modal">Abbrechen</a>
      <button [ngClass]="{'disabled' : userForm.invalid}"
              class="btn btn-success btn-block text-uppercase mb-2 py-2 me-2 shadow-sm float-end" type="submit">
        Speichern
      </button>
    </div>
  </form>
</ng-template>
