import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '../services/api/auth.service';
import {Shop} from '../models/shop.model';
import {HttpClient} from '@angular/common/http';
import {ApiConfig} from '../services/api/api.config';
import {catchError, map, switchMap} from 'rxjs/operators';
import {User} from '../models/user.model';
import {DashboardService} from '../services/api/dashboard.service';


@Injectable({
  providedIn: 'root'
})
export class DashboardGuard  {

  shopname: string;

  constructor(private auth: AuthService, private router: Router, private http: HttpClient, private config: ApiConfig, public dashboard: DashboardService) {
  }

  canActivate(activatedRoute: ActivatedRouteSnapshot): Observable<boolean> {
    this.shopname = activatedRoute.params['shopname'];
    this.dashboard.responses.metadata = false;

    return this.http.get<Shop>(this.config.apiUrl + 'shop/' + this.shopname)
      .pipe(catchError(error => of(false)), switchMap((shop: Shop) => {
        return this.auth.getAuthenticatedUser(true).pipe(catchError(error => of(false)), map((user: User) => {
          if (shop && user) {
            if (shop.owner === user.uuid || user.admin) {
              this.dashboard.dashboardChange();
              this.dashboard.selectedShop = shop;
              this.dashboard.responses.metadata = true;
              return true;
            }
          }

          this.auth.redirectToLogin().then(() => {
          });
          return false;
        }));
      }));
  }
}
