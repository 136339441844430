<app-loading *ngIf="loading; else createForm"></app-loading>
<ng-template #createForm>
  <h3 class="fw-light">{{selectedFee ? 'Zahlungsgebühr Bearbeiten' : 'Neue Zahlungsgebühr'}}</h3>
  <p
    class="text-muted mb-4">{{selectedFee ? ('Bearbeiten Sie die Zahlungsgebühr ' + selectedFee?.name + '.') : 'Erstellen Sie eine neue Zahlungsgebühr.'}}</p>
  <form (ngSubmit)="createFee()" [formGroup]="feeForm" class="row g-3">
    <div class="col-md-6">
      <div class="form-floating">
        <input class="form-control" formControlName="name" type="text">
        <label>Name</label>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-floating">
        <input class="form-control" formControlName="description" type="text">
        <label>Beschreibung</label>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-floating">
        <input class="form-control" formControlName="value" type="text">
        <label *ngIf="!!form.fixed.value; else percentage">Festpreis €</label>
        <ng-template #percentage><label>Prozentsatz %</label></ng-template>
      </div>
    </div>
    <div class="col-md-6">
      <input autocomplete="off" class="btn-check" formControlName="fixed" id="fixed-create" type="checkbox">
      <label class="btn btn-outline-success w-100 align-middle" for="fixed-create">Festpreis €</label>
    </div>

    <div class="col-12">
      <button class="btn btn-danger btn-block text-uppercase mb-2 py-2 shadow-sm float-end" data-bs-dismiss="modal"
              type="button">Abbrechen
      </button>
      <button [ngClass]="{'disabled' : feeForm.invalid}"
              class="btn btn-success btn-block text-uppercase mb-2 py-2 shadow-sm float-end me-2"
              type="submit">Speichern
      </button>
    </div>
  </form>
</ng-template>
