<app-loading *ngIf="loading; else recoveryTemplate"></app-loading>
<ng-template #recoveryTemplate>
  <h3 class="display-4">Passworthilfe</h3>
  <p class="text-muted mb-4">Bitte geben Sie Ihre E-Mail Adresse ein.</p>
  <form (ngSubmit)="createRecovery()" [formGroup]="recoveryForm">
    <div class="form-group mb-3">
      <input autofocus class="form-control border-0 shadow-sm px-4 py-2" formControlName="email" placeholder="E-Mail"
             required
             type="email">
    </div>
    <div class="form-group mb-3">
      <a class="link-success" routerLink="/auth/login">Zurück zum Login</a>
    </div>
    <button [ngClass]="{'disabled' : recoveryForm.invalid}"
            class="btn btn-success btn-block text-uppercase mb-2 py-2 shadow-sm"
            type="submit">Absenden
    </button>
  </form>
  <div class="mt-5 border-top py-4">
    <p><a [routerLink]="['/impressum']" class="link text-secondary">Impressum</a> | <a [routerLink]="['/datenschutz']"
                                                                                       class="link text-secondary">Datenschutz</a>
    </p>
  </div>
</ng-template>

