import { Component } from '@angular/core';
import {DashboardService} from "../../../services/api/dashboard.service";

@Component({
  selector: 'app-additives',
  templateUrl: './additives.component.html',
  styleUrls: ['./additives.component.css']
})
export class AdditivesComponent{
  constructor(public dashboard: DashboardService) {
    this.dashboard.loadAllAdditives();
  }
}
