import {Component, OnInit} from '@angular/core';
import {ShopService} from '../../../../services/api/shop.service';

@Component({
  selector: 'app-legal-agb',
  templateUrl: './legal-agb.component.html',
  styleUrls: ['./legal-agb.component.css']
})
export class LegalAgbComponent implements OnInit {

  constructor(public shop: ShopService) {
  }

  ngOnInit(): void {
  }

}
