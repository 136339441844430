import {Fee} from './fee.model';

export class PaymentMethod {
  shop: string;
  uuid: string;
  name: string;
  description: string;
  fee: Fee;
  paypal: boolean;
  client: string;
  secret: string;

  constructor(shop: string, uuid: string, name: string, description: string, fee: Fee, paypal: boolean, client: string, secret: string) {
    this.shop = shop;
    this.uuid = uuid;
    this.name = name;
    this.description = description;
    this.fee = fee;
    this.paypal = paypal;
    this.client = client;
    this.secret = secret;
  }
}
