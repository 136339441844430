import {throwError} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiConfig {

  production = true;

  frontend = this.production ? 'https://order.motion-circle.com' : 'http://localhost:4200';
  fileUrl = this.production ? 'https://api.motion-circle.com' : 'http://localhost:8080';
  apiUrl = this.production ? 'https://api.motion-circle.com/api/' : 'http://localhost:8080/api/';

  handleError(error) {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      errorMessage += error.error.message;
    } else {
      if ([0, 404].includes(error.status)) {
        errorMessage += 'Verbindung zur Ordermotion-API fehlgeschlagen. Bitte überprüfen Sie Ihre Internetverbindung.';
      } else {
        errorMessage += error.error.message;
      }
    }

    return throwError(errorMessage);
  }
}
