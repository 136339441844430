import {Component, Input, OnChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {first} from 'rxjs/operators';
import {CodeList} from '../../../../models/codelist.model';
import {ToastrService} from 'ngx-toastr';
import {updateObject} from '../../../../helper/object.helper';

@Component({
  selector: 'app-create-code-list',
  templateUrl: './create-code-list.component.html',
  styleUrls: ['./create-code-list.component.css']
})
export class CreateCodeListComponent implements OnChanges {
  @Input() selectedList: CodeList;

  listForm: UntypedFormGroup;
  loading = false;

  constructor(private router: Router, private formBuilder: UntypedFormBuilder, public dashboard: DashboardService, private alert: ToastrService) {
  }

  get form(): any {
    return this.listForm.controls;
  }

  ngOnChanges(): void {
    this.listForm = this.formBuilder.group({
      name: [this.selectedList?.name ?? '', Validators.required],
      description: [this.selectedList?.description ?? '', Validators.required]
    });
  }

  createCodeList(): void {
    if (this.listForm.invalid) return;

    this.loading = true;

    const update = new CodeList(this.dashboard.selectedShop.uuid, this.selectedList?.uuid ?? undefined, this.form.name.value, this.form.description.value, this.selectedList?.codes ?? undefined);

    if (!this.selectedList) {
      this.dashboard.createCodeList(update)
        .pipe(first())
        .subscribe(
          result => {
            this.loading = false;
            this.listForm.reset();
            this.dashboard.shippingAreas.push(result);
            this.alert.success('Das Liefergebiet wurde erfolgreich erstellt.');
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    } else {
      this.dashboard.updateCodeList(update)
        .pipe(first())
        .subscribe(
          result => {
            this.loading = false;
            updateObject(this.selectedList, result);
            this.alert.success('Das Liefergebiet wurde erfolgreich aktualisiert.');
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    }
  }
}
