import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {AuthService} from '../services/api/auth.service';
import {User} from '../models/user.model';
import {catchError, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountGuard  {

  constructor(private auth: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (!this.auth.userHasToken()) return of(false);
    return this.auth.getAuthenticatedUser(true).pipe(catchError(error => of(false)), map((user: User) => {
      if (!user || (user && !user.active)) {
        this.auth.logout();
        return false;
      }

      if (!user.admin && route.data.admin) {
        this.auth.redirectToAccount().then(() => {
        });
        return false;
      }

      this.auth.responses.user = true;
      this.auth.user = user;
      return true;
    }));
  }
}
