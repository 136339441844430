import {Component, Input} from '@angular/core';
import {AddonList} from "../../../../models/addon.model";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {DashboardService} from "../../../../services/api/dashboard.service";
import {ToastrService} from "ngx-toastr";
import {first} from "rxjs/operators";
import {updateObject} from "../../../../helper/object.helper";
import {Additive} from "../../../../models/additive.model";

@Component({
  selector: 'app-create-additive',
  templateUrl: './create-additive.component.html',
  styleUrls: ['./create-additive.component.css']
})
export class CreateAdditiveComponent{
  @Input() selectedAdditive: Additive;

  additiveForm: UntypedFormGroup;
  loading = false;

  constructor(private router: Router, private formBuilder: UntypedFormBuilder, public dashboard: DashboardService, private alert: ToastrService) {}

  get form(): any {
    return this.additiveForm.controls;
  }

  ngOnChanges(): void {
    this.additiveForm = this.formBuilder.group({
      name: [this.selectedAdditive?.name ?? '', Validators.required],
      allergen: [this.selectedAdditive?.allergen ?? '']
    });
  }

  createAdditive(): void {
    if (this.additiveForm.invalid) return;

    this.loading = true;

    const update = new Additive(this.selectedAdditive?.uuid ?? undefined, this.dashboard.selectedShop.uuid, this.form.name.value, !!this.form.allergen.value);

    if (!this.selectedAdditive) {
      this.dashboard.createAdditive(update)
        .pipe(first())
        .subscribe(
          result => {
            this.additiveForm.reset();
            this.loading = false;
            this.dashboard.additives.push(result);
            this.alert.success('Der Zusatzstoff wurde erfolgreich erstellt.');
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    } else {
      this.dashboard.updateAdditive(update)
        .pipe(first())
        .subscribe(
          result => {
            this.loading = false;
            updateObject(this.selectedAdditive, result);
            this.alert.success('Der Zusatzstoff wurde erfolgreich aktualisiert.');
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    }
  }
}
