import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '../services/api/auth.service';
import {HttpClient} from '@angular/common/http';
import {ApiConfig} from '../services/api/api.config';
import {Shop} from '../models/shop.model';
import {catchError, map, switchMap} from 'rxjs/operators';
import {User} from '../models/user.model';
import {ShopService} from '../services/api/shop.service';

@Injectable({
  providedIn: 'root'
})
export class ShopGuard  {

  shopname: string;

  constructor(private auth: AuthService, private router: Router, private http: HttpClient, private config: ApiConfig, private shop: ShopService) {
  }

  canActivate(activatedRoute: ActivatedRouteSnapshot): Observable<boolean> {

    this.shopname = activatedRoute.params['shopname'];
    this.shop.responses.metadata = false;

    return this.http.get<Shop>(this.config.apiUrl + 'shop/' + this.shopname)
      .pipe(catchError(error => of(false)), switchMap((shop: Shop) => {
        return this.auth.getAuthenticatedUser(false).pipe(catchError(error => of(false)), map((user: User) => {

          if (!shop) {
            this.auth.redirectToHome().then(() => {
            });
            return false;
          }

          if (shop.active || (user && (shop.owner == user.uuid || user.admin))) {
            this.shop.current = shop;
            this.shop.responses.metadata = true;

            this.shop.subscribe();
            return true;
          }

          this.auth.redirectToHome().then(() => {
          });
          return false;
        }));
      }));
  }
}
