<app-loading *ngIf="loading; else loginTemplate"></app-loading>
<ng-template #loginTemplate>
  <h3 class="display-4">Login</h3>
  <p class="text-muted mb-4">Melden Sie sich bei ihrem Benutzerkonto an.</p>
  <form (ngSubmit)="loginUser()" [formGroup]="loginForm">
    <div class="form-group mb-3">
      <input autofocus class="form-control border-0 shadow-sm px-4 py-2" formControlName="email" placeholder="E-Mail"
             required
             type="email">
    </div>
    <div class="form-group mb-3">
      <input class="form-control border-0 shadow-sm px-4 py-2 text-success" formControlName="password"
             placeholder="Passwort" required
             type="password">
    </div>
    <div class="form-group mb-3">
      <a class="link-success me-2" routerLink="/auth/recovery">Passwort vergessen?</a>
    </div>
    <button [ngClass]="{'disabled' : loginForm.invalid}"
            class="btn btn-success btn-block text-uppercase mb-2 py-2 shadow-sm"
            type="submit">Anmelden
    </button>
  </form>
  <div class="mt-5 border-top py-4">
    <p><a [routerLink]="['/impressum']" class="link text-secondary">Impressum</a> | <a [routerLink]="['/datenschutz']"
                                                                                       class="link text-secondary">Datenschutz</a>
    </p>
  </div>
</ng-template>


