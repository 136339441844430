import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/api/auth.service';
import {RegisterData} from '../../../models/register.data.model';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {

  registerForm: UntypedFormGroup;
  registrationSuccess = false;
  loading = false;

  constructor(private auth: AuthService, private formBuilder: UntypedFormBuilder, private router: Router, private alert: ToastrService, private titleService: Title, private metaService: Meta) {
  }

  get form(): any {
    return this.registerForm.controls;
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

    this.titleService.setTitle('Registrierung');
    this.metaService.updateTag({
      name: 'description',
      content: 'Erstellen Sie jetzt kostenlos ein Benutzeraccount und beginnen Sie mit der Einrichtung Ihres Onlineshops.'
    });
  }

  registerUser(): void {
    if (this.registerForm.invalid) return;

    this.loading = true;

    this.auth.registerUser(new RegisterData(this.form.name.value, this.form.surname.value, this.form.email.value, this.form.phone.value, this.form.password.value))
      .pipe(first())
      .subscribe(
        result => {
          this.loading = false;
          this.registerForm.reset();
          this.registrationSuccess = true;
        },
        error => {
          this.alert.error(error);
          this.loading = false;
        }
      );
  }
}
