<div class="row pt-2">
  <div class="col-12">
    <button (click)="selectedFee = undefined"
            class="btn btn-success btn-block text-uppercase shadow-sm float-end text-center"
            data-bs-target="#createModal" data-bs-toggle="modal"><i class="fas fa-plus"></i> Neue
      Zahlungsgebühr
    </button>
  </div>
</div>

<app-loading *ngIf="!dashboard.responses.paymentFees; else feesTemplate"></app-loading>
<ng-template #feesTemplate>
  <ng-container *ngIf="dashboard.paymentFees.length; else nothingFound">
    <ul class="list-group mt-5 mb-5">
      <li *ngFor="let fee of dashboard.paymentFees;" class="list-group-item">
        <div class="row">
          <div class="col-8 p-0 py-2">
            <h4>{{fee.name}}</h4>
            <p>{{fee.description}}</p>
            <p *ngIf="!fee.fixed" class="mb-2"><i class="fas fa-percentage"></i> {{fee.value}}</p>
            <p *ngIf="fee.fixed" class="mb-2"><i class="fas fa-tags"></i> {{fee.value}} {{dashboard.currencySymbol}}</p>
          </div>
          <div class="col-4 p-0">
            <button (click)="selectedFee = fee"
                    class="btn btn-dark btn-block text-uppercase shadow-sm float-end text-center"
                    data-bs-target="#createModal" data-bs-toggle="modal"><i
              class="fas fa-sliders-h"></i></button>
            <button (click)="deleteFee(fee)"
                    class="btn btn-danger btn-block text-uppercase shadow-sm float-end text-center me-2"><i
              class="fas fa-trash"></i></button>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>
  <ng-template #nothingFound>
    <app-nothing-found></app-nothing-found>
  </ng-template>
</ng-template>

<div aria-hidden="true" class="modal fade" id="createModal" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="container p-5 pt-0">
        <app-create-fee [selectedFee]="selectedFee"></app-create-fee>
      </div>
    </div>
  </div>
</div>
