import {Component, Input, OnChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {first} from 'rxjs/operators';
import {Fee} from '../../../../models/fee.model';
import {ToastrService} from 'ngx-toastr';
import {updateObject} from '../../../../helper/object.helper';

@Component({
  selector: 'app-create-fee',
  templateUrl: './create-fee.component.html',
  styleUrls: ['./create-fee.component.css']
})
export class CreateFeeComponent implements OnChanges {
  @Input() selectedFee: Fee;

  feeForm: UntypedFormGroup;
  loading = false;

  constructor(private router: Router, private formBuilder: UntypedFormBuilder, public dashboard: DashboardService, private alert: ToastrService) {
  }

  get form(): any {
    return this.feeForm.controls;
  }

  ngOnChanges(): void {
    this.feeForm = this.formBuilder.group({
      name: [this.selectedFee?.name ?? '', Validators.required],
      description: [this.selectedFee?.description ?? '', Validators.required],
      value: [this.selectedFee?.value ?? '', Validators.required],
      fixed: [this.selectedFee?.fixed ?? '']
    });
  }

  createFee(): void {
    if (this.feeForm.invalid) return;

    this.loading = true;

    const update = new Fee(this.dashboard.selectedShop.uuid, this.selectedFee?.uuid ?? undefined, this.form.name.value, this.form.description.value, !!this.form.fixed.value, this.form.value.value);

    if (!this.selectedFee) {
      this.dashboard.createPaymentFee(update)
        .pipe(first())
        .subscribe(
          result => {
            this.loading = false;
            this.feeForm.reset();
            this.dashboard.paymentFees.push(result);
            this.alert.success('Die Zahlungsgebühr wurde erfolgreich erstellt.');
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    } else {
      this.dashboard.updatePaymentFee(update)
        .pipe(first())
        .subscribe(
          result => {
            this.loading = false;
            updateObject(this.selectedFee, result);
            this.alert.success('Die Zahlungsgebühr wurde erfolgreich aktualisiert.');
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    }
  }
}
