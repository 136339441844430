import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-legal-policy',
  templateUrl: './legal-policy.component.html',
  styleUrls: ['./legal-policy.component.css']
})
export class LegalPolicyComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {
  }

  ngOnInit() {
    this.titleService.setTitle('Datenschutz');
    this.metaService.updateTag({name: 'robots', content: 'noindex, nofollow'});
  }
}
