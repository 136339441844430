import {Component} from '@angular/core';
import {ImageFile} from '../../../../models/file.model';
import {first} from 'rxjs/operators';
import {ApiConfig} from '../../../../services/api/api.config';
import {ToastrService} from 'ngx-toastr';
import {DashboardService} from '../../../../services/api/dashboard.service';

@Component({
  selector: 'app-file-grid',
  templateUrl: './file-grid.component.html',
  styleUrls: ['./file-grid.component.css']
})
export class FileGridComponent {

  deleted: ImageFile[] = [];

  constructor(public dashboard: DashboardService, private alert: ToastrService, public config: ApiConfig) {
  }

  deleteFile(file) {
    if (this.deleted.includes(file)) return;

    this.deleted.push(file);

    this.dashboard.deleteFile(file)
      .pipe(first())
      .subscribe(
        result => {
          this.deleted.splice(this.deleted.indexOf(file), 1);
          this.dashboard.files.splice(this.dashboard.files.indexOf(file), 1);
          this.alert.success('Die Datei ' + file.name + ' wurde erfolgreich gelöscht.');
        },
        error => {
          this.deleted.splice(this.deleted.indexOf(file), 1);
          this.alert.error(error);
        }
      );
  }
}
