import {Component, Input, OnChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {updateObject} from '../../../../helper/object.helper';
import {AddonList} from "../../../../models/addon.model";

@Component({
  selector: 'app-create-addon-list',
  templateUrl: './create-addon-list.component.html',
  styleUrls: ['./create-addon-list.component.css']
})
export class CreateAddonListComponent implements OnChanges {
  @Input() selectedList: AddonList;

  listForm: UntypedFormGroup;
  loading = false;

  constructor(private router: Router, private formBuilder: UntypedFormBuilder, public dashboard: DashboardService, private alert: ToastrService) {
  }

  get form(): any {
    return this.listForm.controls;
  }

  ngOnChanges(): void {
    this.listForm = this.formBuilder.group({
      name: [this.selectedList?.name ?? '', Validators.required],
      multiselect: [this.selectedList?.multiselect ?? '']
    });
  }

  createAddonList(): void {
    if (this.listForm.invalid) return;

    this.loading = true;

    const update = new AddonList(this.dashboard.selectedShop.uuid, this.selectedList?.uuid ?? undefined, this.form.name.value, undefined, !!this.form.multiselect.value);

    if (!this.selectedList) {
      this.dashboard.createAddonList(update)
        .pipe(first())
        .subscribe(
          result => {
            this.listForm.reset();
            this.loading = false;
            this.dashboard.addonLists.push(result);
            this.alert.success('Die Addonliste wurde erfolgreich erstellt.');
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    } else {
      this.dashboard.updateAddonList(update)
        .pipe(first())
        .subscribe(
          result => {
            this.loading = false;
            updateObject(this.selectedList, result);
            this.alert.success('Die Addonliste wurde erfolgreich aktualisiert.');
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    }
  }
}
