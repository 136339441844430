import {Component, Input} from '@angular/core';
import {DashboardService} from '../../../../services/api/dashboard.service';

@Component({
  selector: 'optgroup',
  templateUrl: './file-options.component.html',
  styleUrls: ['./file-options.component.css']
})
export class FileOptionsComponent {
  @Input() selected: string;

  constructor(public dashboard: DashboardService) {
  }
}
