import {TaxClass} from './taxclass.model';
import {Category} from './category.model';
import {ImageFile} from './file.model';
import {Addon, AddonList} from './addon.model';
import {Additive} from "./additive.model";

export class Product {
  shop: string;
  uuid: string;
  name: string;
  description: string;
  available: boolean;
  quantity: number;
  price: number;
  taxclass: TaxClass;
  addonlists: AddonList[];
  additives: Additive[];
  category: Category;
  image: ImageFile;

  selectedAddons: Addon[];

  constructor(shop: string, uuid: string, name: string, description: string, price: number, taxclass: TaxClass, available: boolean, addonlists: AddonList[], additives: Additive[], category: Category, image: ImageFile) {
    this.shop = shop;
    this.uuid = uuid;
    this.name = name;
    this.description = description;
    this.price = price;
    this.taxclass = taxclass;
    this.available = available;
    this.addonlists = addonlists;
    this.additives = additives;
    this.category = category;
    this.image = image;
  }
}

