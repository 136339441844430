<div class="container-fluid">
  <div class="row no-gutter">
    <div class="col-md-4 d-none d-md-flex bg-image"></div>
    <div class="col-md-8 bg-light">
      <div class="split d-flex align-items-center py-5">
        <div class="container">
          <div class="row">
            <div class="col-xl-10 col-xl-7 mx-auto">
              <app-loading *ngIf="!auth.responses.user; else routerOutlet"></app-loading>
              <ng-template #routerOutlet>
                <router-outlet></router-outlet>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
