export class LoginData {
  email: string;
  password: string;

  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }
}

export class LoginResponse {
  error: boolean;
  message: string;
  token: string;
}

