import {Component, Input} from '@angular/core';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {TaxClass} from '../../../../models/taxclass.model';
import {Category} from '../../../../models/category.model';
import {Product} from '../../../../models/product.model';
import {ImageFile} from '../../../../models/file.model';
import {ToastrService} from 'ngx-toastr';
import {updateObject} from '../../../../helper/object.helper';

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.css']
})
export class CreateProductComponent {
  @Input() selectedProduct: Product;

  productForm: UntypedFormGroup;
  loading = false;

  selectedAddonLists = [];
  selectedAdditives = [];

  dropdownSettings = {
    singleSelection: false,
    idField: 'uuid',
    textField: 'name',
    selectAllText: 'Alle Auswählen',
    unSelectAllText: 'Alle Entfernen',
    allowSearchFilter: false
  };

  constructor(private router: Router, private formBuilder: UntypedFormBuilder, public dashboard: DashboardService, private alert: ToastrService) {
  }

  get form(): any {
    return this.productForm.controls;
  }

  ngOnChanges(): void {
    this.selectedAddonLists = this.selectedProduct?.addonlists;
    this.selectedAdditives = this.selectedProduct?.additives;

    this.productForm = this.formBuilder.group({
      name: [this.selectedProduct?.name ?? '', Validators.required],
      description: [this.selectedProduct?.description ?? '', Validators.required],
      price: [this.selectedProduct?.price ?? '', Validators.required],
      image: [this.selectedProduct?.image?.uuid ?? ''],
      available: [this.selectedProduct?.available ?? ''],
      taxclass: [this.selectedProduct?.taxclass?.uuid, Validators.required],
      category: [this.selectedProduct?.category?.uuid, Validators.required]
    });
  }

  updateProduct(): void {
    if (this.productForm.invalid) return;

    this.loading = true;

    const taxclass = new TaxClass(undefined, this.form.taxclass.value, undefined, undefined);
    const category = new Category(undefined, this.form.category.value, undefined, undefined, undefined);
    const image = new ImageFile(this.form.image.value, undefined, undefined, undefined);

    const update = new Product(this.dashboard.selectedShop.uuid, this.selectedProduct?.uuid ?? undefined, this.form.name.value, this.form.description.value, this.form.price.value, taxclass, !!this.form.available.value, this.selectedAddonLists ?? [], this.selectedAdditives ?? [], category, image)

    if (!this.selectedProduct) {
      this.dashboard.createProduct(update)
        .pipe(first())
        .subscribe(
          result => {
            this.productForm.reset();
            this.dashboard.products.push(result);
            this.selectedAddonLists = [];
            this.selectedAdditives = [];
            this.loading = false;
            this.alert.success('Das Produkt wurde erfolgreich erstellt.');
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    } else {
      this.dashboard.updateProduct(update)
        .pipe(first())
        .subscribe(
          result => {
            this.loading = false;
            updateObject(this.selectedProduct, result);
            this.alert.success('Das Produkt wurde erfolgreich aktualisiert.');
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    }
  }
}
