import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ShopCreate} from '../../../models/shop.model';
import {Address} from '../../../models/address.model';
import {first} from 'rxjs/operators';
import {AuthService} from '../../../services/api/auth.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-create-shop',
  templateUrl: './create-shop.component.html',
  styleUrls: ['./create-shop.component.css']
})
export class CreateShopComponent implements OnInit {

  shopForm: UntypedFormGroup;
  loading = false;

  constructor(private auth: AuthService, private router: Router, private formBuilder: UntypedFormBuilder, private alert: ToastrService) {
  }

  get form(): any {
    return this.shopForm.controls;
  }

  ngOnInit(): void {
    this.shopForm = this.formBuilder.group({
      name: ['', Validators.required],
      shortname: ['', [Validators.required, Validators.minLength(6)]],
      description: ['', Validators.required],
      address_name: ['', Validators.required],
      address_surname: ['', Validators.required],
      company: ['', Validators.required],
      street: ['', Validators.required],
      number: ['', Validators.required],
      code: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      currency: ['', Validators.required]
    });
  }

  createShop(): void {
    if (this.shopForm.invalid) return;

    const addressModel = new Address(this.form.address_name.value, this.form.address_surname.value, this.form.company.value, this.form.street.value, this.form.number.value, this.form.code.value, this.form.city.value, this.form.country.value, this.form.email.value, this.form.phone.value);
    const shopModel = new ShopCreate(this.form.name.value, this.form.description.value, this.form.shortname.value, addressModel, this.form.currency.value);

    this.loading = true;

    this.auth.createShop(shopModel)
      .pipe(first())
      .subscribe(
        result => {
          this.auth.redirectToAccount().then(() => {
          });
          this.auth.user.shops.push(result);
          this.loading = false;
        },
        error => {
          this.alert.error(error);
          this.loading = false;
        }
      )
  }
}
