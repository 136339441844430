<div class="row pt-2">
  <div class="col-12">
    <button (click)="selectedMethod = undefined"
            class="btn btn-success btn-block text-uppercase shadow-sm float-end text-center"
            data-bs-target="#createModal" data-bs-toggle="modal"><i class="fas fa-plus"></i> Neue
      Liefermethode
    </button>
  </div>
</div>

<app-loading *ngIf="!dashboard.responses.shippingMethods; else shippingMethodsTemplate"></app-loading>
<ng-template #shippingMethodsTemplate>
  <ng-container *ngIf="dashboard.shippingMethods.length; else nothingFound">
    <ul class="list-group mt-5 mb-5">
      <li *ngFor="let method of dashboard.shippingMethods;" class="list-group-item">
        <div class="row">
          <div class="col-6 p-0 py-2">
            <h4>{{method.name}}</h4>
            <p>{{method.description}}</p>
            <div class="btn-group" role="group">
              <button *ngIf="method.codelist" class="btn btn-light" type="button"><i
                class="fas fa-map-marked-alt"></i> {{method.codelist.name}}</button>
              <button *ngIf="method.pickup" class="btn btn-light" type="button"><i class="fas fa-map-marked-alt"></i>
                Abholung
              </button>
              <button class="btn btn-light" type="button"><i
                class="fas fa-shopping-cart"></i> {{method.minordervalue}}{{dashboard.currencySymbol}}</button>
              <button class="btn btn-light" type="button"><i
                class="fas fa-tags"></i> {{method.price}}{{dashboard.currencySymbol}}</button>
            </div>
          </div>
          <div class="col-6 p-0">
            <button (click)="selectedMethod = method"
                    class="btn btn-dark btn-block text-uppercase shadow-sm float-end text-center"
                    data-bs-target="#createModal" data-bs-toggle="modal"><i
              class="fas fa-sliders-h"></i></button>
            <button (click)="deleteMethod(method)"
                    class="btn btn-danger btn-block text-uppercase shadow-sm float-end text-center me-2"><i
              class="fas fa-trash"></i></button>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>
  <ng-template #nothingFound>
    <app-nothing-found></app-nothing-found>
  </ng-template>
</ng-template>

<div aria-hidden="true" class="modal fade" id="createModal" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="container p-5 pt-0">
        <app-create-shipping-method [selectedMethod]="selectedMethod"></app-create-shipping-method>
      </div>
    </div>
  </div>
</div>
