import {Component, OnInit} from '@angular/core';
import {Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private metaService: Meta) {
  }

  ngOnInit() {
    this.metaService.updateTag({name: 'robots', content: 'index, follow'});
  }
}
