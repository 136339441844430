import {Component} from '@angular/core';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {ApiConfig} from '../../../../services/api/api.config';
import {Order} from '../../../../models/order.model';

@Component({
  selector: 'app-list-orders',
  templateUrl: './list-orders.component.html',
  styleUrls: ['./list-orders.component.css']
})
export class ListOrdersComponent {

  selectedOrder: Order;
  pageOfItems: Order[];

  constructor(public dashboard: DashboardService, public config: ApiConfig) {
  }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }
}
