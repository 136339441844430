<div class="container p-4 pt-0 p-lg-1">
  <div class="row mt-4 mb-4 d-flex">
    <div class="col m-auto">
      <div *ngIf="shop.current.logo" class="logo">
        <img class="img-fluid" src="{{config.fileUrl}}{{shop.current.logo.url}}">
      </div>
      <div *ngIf="!shop.current.logo" class="logo">
        <img src="/assets/images/motion-circle-logo-dark.png">
      </div>
    </div>
    <div class="col m-auto">
      <div class="btn-group float-end" role="group" aria-label="Basic example">
        <button class="btn btn-success" data-bs-target="#cartModal" data-bs-toggle="modal"><i
          class="fas fa-shopping-cart"></i> {{shop.getCartLength()}}</button>
        <button class="btn btn-dark" data-bs-target="#legalModal" data-bs-toggle="modal"><i
          class="fas fa-store"></i></button>
      </div>
    </div>
  </div>
  <div class="container my-3">
    <div class="row">
      <div class="col-12 bg-light description">
        <h2 class="fw-light">{{shop.current.name}}</h2>
        <p class="text-secondary">{{shop.current.description}}</p>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!shop.availableDeliverySlots && shop.responses.deliverySlots">
    <div class="container">
      <div class="row mb-3">
        <div class="col-12 p-3 bg-warning description">
          <p class="text-dark m-auto"><i class="fas fa-clock"></i> Aktuell ist keine Lieferung oder Abholung möglich.
            Bitte beachte unsere <a class="text-dark fw-bolder" data-bs-target="#legalModal"
                                              data-bs-toggle="modal">Öffnungszeiten</a>.</p>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!shop.current.open">
    <div class="container">
      <div class="row">
        <div class="col-12 p-3 bg-warning description">
          <p class="text-dark m-auto"><i class="fas fa-clock"></i> Unser Onlineshop ist zurzeit leider geschlossen. Bitte versuche es zu einem späteren Zeitpunkt erneut.</p>
        </div>
      </div>
    </div>
  </ng-container>
  <app-loading
    *ngIf="(!shop.responses.products || !shop.responses.categories || !shop.responses.shippingMethods || !shop.responses.paymentMethods ||!shop.responses.deliverySlots); else shopProducts"></app-loading>
  <ng-template #shopProducts>
    <app-product-grid></app-product-grid>
    <app-footer></app-footer>
  </ng-template>
</div>

<!-- Shop Legal Modal -->
<div aria-hidden="true" class="modal fade" id="legalModal" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="container p-5 pt-0">
        <div class="row">
          <div class="col-lg-6">
            <h5 class="mb-0 fw-light">Kontaktdaten</h5>
            <p class="text-muted mb-3 mt-0">Adresse und Ansprechpartner</p>
            <p>
              {{shop.current.address.name}} {{shop.current.address.surname}}<br>
              {{shop.current.address.company}}
            </p>
            <p>
              {{shop.current.address.street}} {{shop.current.address.number}}<br>
              {{shop.current.address.code}} {{shop.current.address.city}}<br>
              {{shop.current.address.country}}
            </p>
            <a class="link-dark" href="mailto:{{shop.current.address.email}}"><i
              class="fas fa-envelope"></i> {{shop.current.address.email}}</a>
            <br>
            <a class="link-dark" href="phone:{{shop.current.address.phone}}"><i
              class="fas fa-phone-square"></i> {{shop.current.address.phone}}</a>
            <br>
            <br>
            <a class="link-dark fw-bold" data-bs-target="#revocationModal" data-bs-toggle="modal">Widerrufsbelehrung</a>
            <br>
            <a class="link-dark fw-bold" data-bs-target="#agbModal" data-bs-toggle="modal">Allg. Geschäftsbedingungen</a>
          </div>
          <div class="col-lg-6 mt-5 mt-lg-0">
            <h5 class="mb-0 fw-light">Öffnungszeiten</h5>
            <p class="text-muted mb-3 mt-0">Liefer-/Abholzeit ca. {{shop.current.fullfillment}} Min.</p>
            <div class="mt-2">
              <ul class="list-group">
                <li *ngFor="let hour of shop.current.hours;"
                    class="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                  {{shop.getDay(hour.day)}}
                  <ng-container *ngIf="hour.closed; else openingHours">
                    <span class="badge bg-danger p-2">Geschlossen</span>
                  </ng-container>
                  <ng-template #openingHours>
                    <ng-container *ngIf="hour.continuous; else showSlots">
                        <span class="badge bg-light p-2 text-dark">{{hour.slots[0].open}}
                          - {{hour.slots[0].close}}</span>
                    </ng-container>
                    <ng-template #showSlots>
                          <span class="badge bg-light p-2 text-dark">
                            <ng-container *ngFor="let slot of hour.slots">
                              {{slot.open}} - {{slot.close}}
                            </ng-container>
                          </span>
                    </ng-template>
                  </ng-template>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Cart Modal -->
<div aria-hidden="true" class="modal fade" id="cartModal" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="container p-5 pt-0">
        <app-cart></app-cart>
      </div>
    </div>
  </div>
</div>

<!-- AGB Modal -->
<div aria-hidden="true" class="modal fade" id="agbModal" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="container p-5 pt-0">
        <app-legal-agb></app-legal-agb>
      </div>
    </div>
  </div>
</div>

<!-- Revocation Modal -->
<div aria-hidden="true" class="modal fade" id="revocationModal" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="container p-5 pt-0">
        <app-legal-revocation></app-legal-revocation>
      </div>
    </div>
  </div>
</div>

