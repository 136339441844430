import {Component} from '@angular/core';
import {DashboardService} from '../../../services/api/dashboard.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent {
  constructor(public dashboard: DashboardService) {
    this.dashboard.loadAllOrders();
  }
}
