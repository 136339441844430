import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Addon, AddonList} from "../../../../models/addon.model";

@Component({
  selector: 'app-addon-manager',
  templateUrl: './addon-manager.component.html',
  styleUrls: ['./addon-manager.component.css']
})
export class AddonManagerComponent implements OnInit {

  @Input() selectedList: AddonList;

  addonForm: UntypedFormGroup;

  constructor(private router: Router, private formBuilder: UntypedFormBuilder, public dashboard: DashboardService, private alert: ToastrService) {
  }

  get form(): any {
    return this.addonForm.controls;
  }

  ngOnInit(): void {
    this.addonForm = this.formBuilder.group({
      name: ['', Validators.required],
      price: ['', Validators.required]
    });
  }

  addAddonToList(): void {
    if (this.addonForm.invalid) return;
    this.dashboard.addAddonToAddonList(new Addon(undefined, undefined, this.form.name.value, this.form.price.value), this.selectedList)
      .pipe(first())
      .subscribe(
        result => {
          this.addonForm.reset();
          this.selectedList.addons.push(result);
        },
        error => {
          this.alert.error(error);
        }
      );
  }

  removeAddonFromList(addon): void {
    this.dashboard.removeAddonFromAddonList(addon, this.selectedList)
      .pipe(first())
      .subscribe(
        result => {
          this.selectedList.addons.splice(this.selectedList.addons.indexOf(addon), 1);
        },
        error => {
          this.alert.error(error);
        }
      );
  }
}
