import {Component, Input, OnChanges} from '@angular/core';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {TaxClass} from '../../../../models/taxclass.model';
import {ToastrService} from 'ngx-toastr';
import {updateObject} from '../../../../helper/object.helper';

@Component({
  selector: 'app-create-tax-class',
  templateUrl: './create-tax-class.component.html',
  styleUrls: ['./create-tax-class.component.css']
})
export class CreateTaxClassComponent implements OnChanges {
  @Input() selectedTaxClass: TaxClass;

  taxClassForm: UntypedFormGroup;
  loading = false;

  constructor(private router: Router, private formBuilder: UntypedFormBuilder, public dashboard: DashboardService, private alert: ToastrService) {
  }

  get form(): any {
    return this.taxClassForm.controls;
  }

  ngOnChanges(): void {
    this.taxClassForm = this.formBuilder.group({
      name: [this.selectedTaxClass?.name ?? '', Validators.required],
      percentage: [this.selectedTaxClass?.percentage ?? '', [Validators.required]],
    });
  }

  createTaxClass(): void {
    if (this.taxClassForm.invalid) return;

    this.loading = true;

    const update = new TaxClass(this.dashboard.selectedShop.uuid, this.selectedTaxClass?.uuid ?? undefined, this.form.name.value, this.form.percentage.value);

    if (!this.selectedTaxClass) {
      this.dashboard.createTaxClass(update)
        .pipe(first())
        .subscribe(
          result => {
            this.loading = false;
            this.taxClassForm.reset();
            this.dashboard.taxClasses.push(result);
            this.alert.success('Die Steuerklasse wurde erfolgreich erstellt.');
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    } else {
      this.dashboard.updateTaxclass(update)
        .pipe(first())
        .subscribe(
          result => {
            this.loading = false;
            updateObject(this.selectedTaxClass, result);
            this.alert.success('Die Steuerklasse wurde erfolgreich aktualisiert.');
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    }
  }
}
