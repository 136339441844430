<ng-container *ngIf="state === StateEnum.cart">

  <ng-container *ngIf="!shop.cart.length; else cartTemplate">
    <h2 class="text-center fw-light">Warenkorb</h2>
    <p class="text-muted text-center">Du hast noch keine Produkte in den Warenkorb gelegt.</p>
    <img class="img-fluid checkout-image mx-auto d-block" src="assets/images/cart-empty.png">
  </ng-container>

  <ng-template #cartTemplate>
    <div class="row">
      <h2 class="fw-light p-0">Warenkorb</h2>
      <h5 class="text-muted fw-light mb-3 p-0">{{shop.current.name}}</h5>
      <span class="text-success p-0"><i class="fas fa-clock"></i> Liefer-/Abholzeit ca. {{shop.current.fullfillment}} Min.</span>
    </div>
      <div *ngFor="let product of shop.cart; let i = index" class="row mt-4 cart-item">
      <div class="col-lg-4 mt-2">
        <b>{{product.quantity}} <i class="fas fa-times"></i> {{product.name}}</b><br>
        <span class="text-muted fs-6">{{shop.getProductTotal(product) | number:'1.2-2'}}{{shop.currencySymbol}} inkl. {{product.taxclass.name}}</span>
      </div>
      <div class="col-lg-4 mt-2">
        <ng-container *ngIf="product.selectedAddons?.length">
          <ng-container *ngFor="let addonlist of shop.getSortedProductAddons(product); let i = index">
            <span class="text-muted">{{addonlist.title}}</span><br>
            <ng-container *ngFor="let addon of addonlist.addons">
              <span>{{addon.name}} | {{addon.price | number:'1.2-2'}}{{shop.currencySymbol}}</span><br>
            </ng-container>
            <hr *ngIf="i !== shop.getSortedProductAddons(product).length - 1">
          </ng-container>
        </ng-container>
      </div>
      <div class="col-lg-4 mt-2">
        <div class="btn-group float-end w-50" role="group">
          <button (click)="shop.updateProductCartQuantity(i,+1)" class="btn btn-dark"><i class="fas fa-plus"></i>
          </button>
          <button (click)="shop.updateProductCartQuantity(i,-1)" class="btn btn-danger"><i class="fas fa-minus"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-sm-8"></div>
      <div class="col-lg-4 bg-dark text-light cart-sum text-end">
        <b>Gesamt {{shop.getCartSum() | number:'1.2-2'}} {{shop.currencySymbol}}</b>
        <br><span class="text-muted">inkl. Umsatzsteuer</span>
        <button (click)="state = StateEnum.checkout" class="btn btn-light w-100 mt-2"><i
          class="fas fa-shopping-cart"></i> Kasse
        </button>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-container *ngIf="state === StateEnum.checkout">

  <h2 class="fw-light">Kasse</h2>
  <h5 class="text-muted fw-light">{{shop.current.name}}</h5>

  <a (click)="state = StateEnum.cart" class="text-success">Zurück zum Warenkorb</a>

  <app-loading
    *ngIf="(!shop.responses.products || !shop.responses.categories || !shop.responses.shippingMethods || !shop.responses.paymentMethods ||!shop.responses.deliverySlots); else checkoutPage"></app-loading>

  <ng-template #checkoutPage>
    <ng-container *ngIf="!shop.canPerformOrder; else checkoutForm">
      <app-configuration-error></app-configuration-error>
    </ng-container>
  </ng-template>

  <ng-template #checkoutForm>
    <form (ngSubmit)="submitOrder()" [formGroup]="cartForm" class="row g-3 mt-4">
      <h5 class="mb-0">Kontaktdaten</h5>
      <p class="text-muted mb-0 mt-0">Wie können wir dich erreichen?</p>
      <div class="col-lg-6">
        <div class="form-floating">
          <input class="form-control" formControlName="name" type="text">
          <label>Vor- und Nachname</label>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-floating">
          <input class="form-control" formControlName="company" type="text">
          <label>Firmenname</label>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="form-floating">
          <input class="form-control" formControlName="street" type="text">
          <label>Straße</label>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="form-floating">
          <input class="form-control" formControlName="number" type="text">
          <label>Nummer</label>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-floating">
          <input class="form-control" formControlName="code" type="text">
          <label>Postleitzahl</label>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-floating">
          <input class="form-control" formControlName="city" type="text">
          <label>Stadt</label>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-floating">
          <input class="form-control" formControlName="email" type="text">
          <label>E-Mail</label>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-floating">
          <input class="form-control" formControlName="phone" type="text">
          <label>Telefonnummer</label>
        </div>
      </div>

      <h5 class="mb-0 mt-5">Persönliche Nachricht</h5>
      <p class="text-muted mt-0">Möchtest du uns noch etwas mitteilen?</p>
      <div class="col-12 mt-0">
        <div class="form-floating">
          <textarea class="form-control" formControlName="note" style="height: 75px;"></textarea>
          <label>Nachricht</label>
        </div>
      </div>

      <div class="col-lg-6">
        <h5 class="mb-0 mt-5">Liefer-/Abholmethode</h5>
        <p class="text-muted mt-0">Welche Option ist dir am liebsten?</p>
        <ng-container *ngIf="!shop.canActivateAnyShippingMethod(form.code.value); else shippingMethods">
          <div class="alert alert-warning mt-2" role="alert">
            Für Ihre aktuelle Adresse sind keine Liefer- oder Abholmethoden verfügbar.
          </div>
        </ng-container>

        <ng-template #shippingMethods>
          <ng-container *ngFor="let method of shop.shippingMethods; let i = index;">
            <div *ngIf="shop.canActivateShippingMethod(form.code.value,method)" class="col-md-12 mt-2">
              <input (change)="shop.selectShipping(method)" [value]="method.uuid" class="btn-check"
                     formControlName="shipping" id="shipping-active-{{i}}" type="radio">
              <label class="btn btn-outline-dark w-100" for="shipping-active-{{i}}"><i
                [ngClass]="{'fa-store' : method.pickup, 'fa-shipping-fast': !method.pickup}"
                class="fas"></i> {{method.name}}{{method.price ? ' | ' + (method.price  | number:'1.2-2') + shop.currencySymbol : ''}}
              </label>
            </div>
          </ng-container>
        </ng-template>

        <ng-container *ngIf="shop.selectedShippingMethod && shop.canActivateAnyShippingMethod(form.code.value)">
          <div class="col-12 mt-2">
            <div class="form-floating">
              <select class="form-control" formControlName="fullfillment">
                <option value="">Auswählen...</option>
                <option *ngFor="let slot of shop.deliverySlots" [value]="slot.timestamp">{{slot.title}}</option>
              </select>
              <label>{{shop.selectedShippingMethod.pickup ? 'Abholzeit' : 'Lieferzeit'}}</label>
            </div>
          </div>
        </ng-container>

        <div class="col-md-12 mt-2">
          <small *ngIf="!form.code.value; else shippingMethodHints" class="mt-3">
            <i class="fas fa-map-marked-alt"></i> Bitte gib deine Postleitzahl ein, um alle verfügbaren Liefer- und Abholmethoden anzuzeigen.
          </small>

          <ng-template #shippingMethodHints>
            <ng-container *ngFor="let method of shop.getDeliveryMethodHints(form.code.value)">
              <small class="text-secondary"><i class="fad fa-info-circle"></i> {{method.name}} ab {{method.minordervalue | number: '1.2-2'}}{{shop.currencySymbol}}</small>
            </ng-container>
          </ng-template>
        </div>
      </div>

      <div class="col-lg-6">
        <h5 class="mb-0 mt-5">Zahlungsmethode</h5>
        <p class="text-muted mt-0">Wie möchtest du bezahlen?</p>
        <div *ngFor="let payment of shop.paymentMethods; let i = index;" class="col-md-12 mt-2">
          <input (change)="shop.selectPayment(payment)" [value]="payment.uuid" class="btn-check"
                 formControlName="payment" id="payment-{{i}}"
                 type="radio">
          <label class="btn btn-outline-dark w-100" for="payment-{{i}}"><i
            [ngClass]="{ 'fab' : payment.paypal, 'fa-paypal' : payment.paypal, 'fas' : !payment.paypal, 'fa-wallet': !payment.paypal}"></i> {{payment.name}}{{payment.fee && payment.fee.fixed ? ' | Gebühr ' + payment.fee.value + ' ' + shop.currencySymbol : ''}}{{payment.fee && !payment.fee.fixed ? ' | Gebühr +' + payment.fee.value + '%' : ''}}
          </label>
        </div>
      </div>

      <div class="col-12 mt-5">
        <button [ngClass]="{'disabled' : cartForm.invalid}"
                class="btn btn-success btn-block mb-2 py-2 text-uppercase shadow-sm w-100" type="submit">Jetzt Bestellen
          | {{shop.getCartSum() | number:'1.2-2'}} {{shop.currencySymbol}}</button>
      </div>
    </form>
  </ng-template>
</ng-container>

<ng-container *ngIf="state === StateEnum.loading">
  <app-loading></app-loading>
</ng-container>

<ng-container *ngIf="state === StateEnum.success">
  <h2 class="text-center">Vielen Dank für deine Bestellung!</h2>
  <p class="text-muted text-center">Eine ausführliche Rechnung erhälst du automatisch per E-Mail.</p>
  <img class="img-fluid checkout-image mx-auto d-block" src="assets/images/checkout-success.png">
</ng-container>

<ng-container *ngIf="state === StateEnum.paypal">
  <h2 class="text-center">Weiterleitung...</h2>
  <p class="text-muted text-center">
    Du wirst automatisch zu PayPal weitergeleitet.<br>
    <a class="text-success text-center"
       href="{{response && response.link ? response.link : ''}}">{{response && response.link ? response.link : ''}}</a>
  </p>
  <img class="img-fluid checkout-image mx-auto d-block" src="assets/images/checkout-redirection.png">
</ng-container>

