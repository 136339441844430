<ng-container *ngIf="selectedProduct">
  <div class="row">
    <h2 class="fw-light">{{selectedProduct.name}}</h2>
    <p class="text-muted">{{this.selectedProduct.description}}</p>
    <div class="col-lg-6">
      <ng-container *ngIf="selectedProduct.addonlists.length">
        <ng-container *ngFor="let addonlist of selectedProduct.addonlists">
          <h5 class="text-muted mt-4 fw-light">{{addonlist.name}}</h5>
          <div class="btn-group-sm" role="group">
            <ng-container *ngFor="let addon of addonlist.addons; let i = index">
              <ng-container *ngIf="addonlist.multiselect; else singleSelect">
                <input (change)="addonSelectChange(addon,false)" [value]="addon.uuid" autocomplete="off"
                       class="btn-check"
                       id="addon-multi-{{addonlist.uuid}}-{{i}}" type="checkbox">
                <label [ngClass]="{'product-addon-even' : (i + 1) % 2 == 0}"
                       class="btn btn-outline-dark product-addon fw-light mt-2"
                       for="addon-multi-{{addonlist.uuid}}-{{i}}"><b>{{addon.name}}</b></label>
              </ng-container>
              <ng-template #singleSelect>
                <input (change)="addonSelectChange(addon,true)" [name]="addonlist.uuid" [value]="addon.uuid"
                       autocomplete="off"
                       class="btn-check" id="addon-single-{{addonlist.uuid}}-{{i}}" type="radio">
                <label [ngClass]="{'product-addon-even' : (i + 1) % 2 == 0}"
                       class="btn btn-outline-dark product-addon fw-light mt-2"
                       for="addon-single-{{addonlist.uuid}}-{{i}}"><b>{{addon.name}}</b></label>
              </ng-template>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <div class="btn-group float-end w-100 mt-4" role="group">
        <button class="btn btn-light">{{selectedProduct.quantity}} <i class="fas fa-times"></i></button>
        <button (click)="setProductQuantity(+1)" class="btn btn-dark"><i class="fas fa-plus"></i></button>
        <button (click)="setProductQuantity(-1)" class="btn btn-danger"><i class="fas fa-minus"></i></button>
      </div>
      <a (click)="shop.addProductToCart(this.selectedProduct);"
         [ngClass]="{'disabled' : !shop.productHasValidAddons(this.selectedProduct)}"
         class="btn btn-success add-to-cart mt-4 w-100"
         data-bs-dismiss="modal"><i class="fas fa-cart-plus"></i> | {{shop.getProductTotal(this.selectedProduct) | number:'1.2-2'}}{{shop.currencySymbol}}</a>
      <div class="additive-information me-2" *ngIf="sortedAdditiveStrings.allergens.length"><i class="fas fa-notes-medical"></i> Allergene
        <span class="additive-information-text">{{sortedAdditiveStrings.allergens}}</span>
      </div>
      <div class="additive-information" *ngIf="sortedAdditiveStrings.additives.length"><i class="fas fa-plus-circle"></i> Zusatzstoffe
        <span class="additive-information-text">{{sortedAdditiveStrings.additives}}</span>
      </div>
    </div>
    <div *ngIf="selectedProduct.image?.url" class="col-lg-6 mt-5 mt-md-0">
      <img class="mx-auto d-block w-100" src="{{config.fileUrl}}{{selectedProduct.image?.url}}"
           style="border-radius: 25px;">
    </div>
  </div>
</ng-container>
