<app-loading *ngIf="loading; else recoveryCompleteTemplate"></app-loading>
<ng-template #recoveryCompleteTemplate>
  <h3 class="display-4">Neues Passwort</h3>
  <p class="text-muted mb-4">Bitte vergeben Sie ein neues Passwort.</p>
  <form (ngSubmit)="createRecovery()" [formGroup]="recoveryForm">
    <div class="form-group mb-3">
      <input autofocus class="form-control border-0 shadow-sm px-4 py-2" formControlName="password"
             placeholder="Passwort" required
             type="password">
    </div>
    <div class="form-group mb-3">
      <input autofocus class="form-control border-0 shadow-sm px-4 py-2" formControlName="password_repeat"
             placeholder="Passwort Wiederholen" required
             type="password">
    </div>
    <button [ngClass]="{'disabled' : recoveryForm.invalid}"
            class="btn btn-success btn-block text-uppercase mb-2 py-2 shadow-sm"
            type="submit">Speichern
    </button>
  </form>
  <div class="mt-5 border-top py-4">
    <p><a [routerLink]="['/impressum']" class="link text-secondary">Impressum</a> | <a [routerLink]="['/datenschutz']"
                                                                                       class="link text-secondary">Datenschutz</a>
    </p>
  </div>
</ng-template>

