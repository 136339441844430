import {Address} from './address.model';
import {Product} from "./product.model";

export class Order {
  shop: string;
  uuid: string;
  sequential: number;
  status: string;
  address: Address;
  shipping: OrderShipping;
  payment: OrderPayment;
  products: OrderProduct[];
  note: string;
  fullfillment: string;
  taxclass: OrderTaxClass[];
  taxtotal: number;
  total: number;
  createdAt: string;
  updatedAt: string;
}

export class OrderTaxClass {
  name: string;
  percentage: number;
  uuid: string;
  proportion: number
  tax: number;
}

export class OrderShipping {
  title: string;
  pickup: boolean;
  taxclass: string;
  tax: number;
  total: number;
}

export class OrderPayment {
  title: string;
  paypal: {
    paypal: boolean;
    transaction: string;
    capture: string;
  };
  fee: {
    title: string;
    taxclass: string;
    tax: number;
    total: number;
  };
  voucher: {};
}

export class OrderProduct {
  name: string;
  quantity: number;
  tax: number;
  taxclass: string;
  total: number;
  addons: OrderAddonList[];
}

class OrderAddonList {
  title: string;
  addons: OrderAddon[];
}

export class OrderAddon {
  name: string;
  price: number;
}

export class OrderCreationResponse {
  error: boolean;
  message: string;
  paypal: boolean;
  link: string;
  transaction: string;
}

export class OrderCreate {
  shop: string;
  address: Address;
  shippingmethod: string;
  paymentmethod: string;
  fullfillment: Date;
  note: string;
  total: number;
  products: Product[];

  constructor(shop: string, address: Address, shippingmethod: string, paymentmethod: string, fullfillment: Date, note: string, products: Product[], total: number) {
    this.shop = shop;
    this.address = address;
    this.shippingmethod = shippingmethod;
    this.paymentmethod = paymentmethod;
    this.fullfillment = fullfillment;
    this.note = note;
    this.products = products;
    this.total = total;
  }
}



