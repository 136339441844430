import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {first} from 'rxjs/operators';
import {ShopService} from '../../../services/api/shop.service';
import {Response} from '../../../models/response.model';

@Component({
  selector: 'app-capture-payment',
  templateUrl: './capture-payment.component.html',
  styleUrls: ['./capture-payment.component.css']
})
export class CapturePaymentComponent implements OnInit {

  response: Response;

  constructor(public activatedRoute: ActivatedRoute, public shop: ShopService) {
  }

  ngOnInit(): void {
    const token = this.activatedRoute.snapshot.queryParams['token'] ?? '';
    this.shop.capturePayment(token)
      .pipe(first())
      .subscribe(
        result => {
          this.response = result;
        },
        error => {
          this.response = new Response(true, error);
        }
      );
  }
}
