import {Component, Input, OnChanges} from '@angular/core';
import {User, UserUpdate} from '../../../models/user.model';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/api/auth.service';
import {first} from 'rxjs/operators';
import {updateObject} from '../../../helper/object.helper';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnChanges {
  @Input() selectedUser: User;

  userForm: UntypedFormGroup;
  loading = false;

  constructor(private formBuilder: UntypedFormBuilder, private auth: AuthService, private alert: ToastrService) {
  }

  get form(): any {
    return this.userForm.controls;
  }

  ngOnChanges(): void {
    this.userForm = this.formBuilder.group({
      name: [this.selectedUser?.name ?? '', Validators.required],
      surname: [this.selectedUser?.surname ?? '', Validators.required],
      type: [this.selectedUser?.type ?? '', Validators.required],
      active: [this.selectedUser?.active ?? '', Validators.required],
      email: [this.selectedUser?.email ?? '', [Validators.required, Validators.email]],
      phone: [this.selectedUser?.phone ?? '', Validators.required]
    });
  }

  updateUser() {
    this.loading = true;
    const update = new UserUpdate(this.selectedUser.uuid, this.form.name.value, this.form.surname.value, this.form.email.value, this.form.phone.value, this.form.active.value, this.form.type.value);

    this.auth.updateUser(update)
      .pipe(first())
      .subscribe(
        result => {
          this.alert.success('Das Benutzerkonto wurde erfolgreich aktualisiert.');
          updateObject(this.selectedUser, result);
          this.loading = false;
        },
        error => {
          this.alert.error(error);
          this.loading = false;
        }
      );
  }
}
