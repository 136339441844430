import {Component} from '@angular/core';
import {User} from '../../models/user.model';
import {AuthService} from '../../services/api/auth.service';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})

export class AdminDashboardComponent {

  pageOfItems: User[];
  selectedUser: User;

  constructor(public auth: AuthService, private titleService: Title, private metaService: Meta) {
    this.auth.loadAllUsers();
  }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

  ngOnInit() {
    this.titleService.setTitle('Ordermotion | Admin-Dashboard');
  }
}
