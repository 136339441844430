import { Component } from '@angular/core';
import {DashboardService} from "../../../../services/api/dashboard.service";
import {ApiConfig} from "../../../../services/api/api.config";
import {ToastrService} from "ngx-toastr";
import {first} from "rxjs/operators";
import {Additive} from "../../../../models/additive.model";

@Component({
  selector: 'app-list-additives',
  templateUrl: './list-additives.component.html',
  styleUrls: ['./list-additives.component.css']
})
export class ListAdditivesComponent {

  selectedAdditive: Additive;
  loading = false;

  constructor(public dashboard: DashboardService, public config: ApiConfig, private alert: ToastrService) {}

  deleteAdditive(additive) {
    this.dashboard.deleteAdditive(additive)
      .pipe(first())
      .subscribe(
        result => {
          this.dashboard.additives.splice(this.dashboard.additives.indexOf(additive), 1);
          this.alert.success('Der Zusatzstoff wurde erfolgreich gelöscht.');
        },
        error => {
          this.alert.error(error);
        }
      );
  }
}
