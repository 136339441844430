<div class="row mb-4 d-flex">
  <div class="col-6 m-auto">
    <img class="img-fluid" src="/assets/images/motion-circle-logo-dark.png" style="max-width: 200px;">
  </div>
  <div class="col-6 m-auto">
    <button class="btn btn-dark btn-block mb-2 py-2 shadow-sm mt-3 float-end" routerLink="/account/home" type="button">
      <i class="fas fa-home"></i> Account
    </button>
  </div>
</div>
<div class="row">
  <div class="col-xl-12 mb-5">
    <div class="bg-light shadow p-4 account-box">
      <h2 class="fw-light">{{auth.user.name}} {{auth.user.surname}}</h2>
      <h5 class="fw-light">{{auth.user.email}}</h5>
      <p class="fw-light text-muted">{{auth.user.phone}}</p>
      <button class="btn btn-success btn-block mb-2 py-2 shadow-sm mt-3" data-bs-target="#passwordModal"
              data-bs-toggle="modal"
              type="button">
        <i class="fas fa-lock"></i> Passwort ändern
      </button>
    </div>
  </div>
</div>

<!-- Password Modal -->
<div aria-hidden="true" class="modal fade" id="passwordModal" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="container p-5 pt-0">
        <app-loading *ngIf="loading; else passwordChange"></app-loading>
        <ng-template #passwordChange>
          <h3 class="fw-light">Passwortänderung</h3>
          <p class="text-muted mb-4">Mindestlänge 6 Zeichen.</p>
          <form (ngSubmit)="updatePassword()" [formGroup]="passwordForm" class="row g-3">
            <div class="form-group mb-3">
              <input autofocus class="form-control border-0 shadow-sm px-4 py-2 text-success" formControlName="password"
                     placeholder="Passwort" required
                     type="password">
            </div>
            <div class="form-group mb-3">
              <input class="form-control border-0 shadow-sm px-4 py-2 text-success" formControlName="password_repeat"
                     placeholder="Passwort Wiederholen" required
                     type="password">
            </div>
            <div class="form-group mb-3">
              <a class="btn btn-danger btn-block text-uppercase mb-2 py-2 shadow-sm float-end" data-bs-target="#passwordModal" data-bs-toggle="modal">Abbrechen</a>
              <button [ngClass]="{'disabled' : passwordForm.invalid}"
                      class="btn btn-success btn-block text-uppercase mb-2 me-2 px-4 py-2 shadow-sm float-end"
                      type="submit">Speichern
              </button>
            </div>
          </form>
        </ng-template>
      </div>
    </div>
  </div>
</div>
