export class ShopHour {
  shop: string;
  uuid: string;
  day: number;
  continuous: boolean;
  closed: boolean;
  slots: SlotModel[];

  constructor(shop, uuid, day, continuous, closed, slots) {
    this.shop = shop;
    this.uuid = uuid;
    this.day = day;
    this.continuous = continuous;
    this.closed = closed;
    this.slots = slots;
  }
}

export class SlotModel {
  open: string;
  close: string;

  constructor(open, close) {
    this.open = open;
    this.close = close;
  }
}
