<div class="container mt-5 w-75">
  <app-loading *ngIf="!response; else responseTemplate"></app-loading>
  <ng-template #responseTemplate>
    <ng-container *ngIf="!response.error; else errorTemplate">
      <h2 class="text-center">Vielen Dank für deine Bestellung!</h2>
      <p class="text-muted text-center">{{response.message}}</p>
      <img class="img-fluid checkout-image mx-auto d-block" src="../../../../assets/images/checkout-success.png">
    </ng-container>
    <ng-template #errorTemplate>
      <h2 class="text-center">Etwas ist schiefgelaufen...</h2>
      <p class="text-muted text-center">{{response.message}}</p>
      <img class="img-fluid checkout-image mx-auto d-block" src="../../../../assets/images/payment-error.png">
    </ng-template>
  </ng-template>
</div>
