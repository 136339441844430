import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-legal-imprint',
  templateUrl: './legal-imprint.component.html',
  styleUrls: ['./legal-imprint.component.css']
})
export class LegalImprintComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {
  }

  ngOnInit() {
    this.titleService.setTitle('Impressum');
    this.metaService.updateTag({name: 'robots', content: 'noindex, nofollow'});
  }
}
