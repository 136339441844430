<app-loading *ngIf="loading || !dashboard.responses.paymentFees; else createForm"></app-loading>
<ng-template #createForm>
  <h3 class="fw-light">{{selectedPaymentMethod ? 'Zahlungsmethode Bearbeiten' : 'Neue Zahlungsmethode'}}</h3>
  <p
    class="text-muted mb-4">{{selectedPaymentMethod ? ('Bearbeiten Sie die Zahlungsmethode ' + selectedPaymentMethod?.name + '.') : 'Erstellen Sie eine neue Zahlungsmethode.'}}</p>
  <form (ngSubmit)="createPaymentMethod()" [formGroup]="methodForm" class="row g-3">
    <div class="col-md-4">
      <div class="form-floating">
        <input class="form-control" formControlName="name" type="text">
        <label>Name</label>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-floating">
        <input class="form-control" formControlName="description" type="text">
        <label>Beschreibung</label>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-floating">
        <select class="form-select" formControlName="fee">
          <option [ngValue]="''" [selected]="true">Auswählen...</option>
          <option *ngFor="let fee of dashboard.paymentFees" [ngValue]="fee.uuid"
                  [selected]="selectedPaymentMethod?.fee && selectedPaymentMethod?.fee?.uuid === fee.uuid">{{fee.name}}</option>
        </select>
        <label>Gebühr</label>
      </div>
    </div>
    <div class="col-md-12">
      <input autocomplete="off" class="btn-check" formControlName="paypal" id="paypal-create" type="checkbox">
      <label class="btn btn-outline-success w-100" for="paypal-create"><i class="fab fa-paypal"></i> PayPal</label>
    </div>
    <ng-container *ngIf="form.paypal.value" class="mt-4">
      <span class="text-muted"><i class="fas fa-info-circle"></i> Alle PayPal-Zahlungen von Kunden werden direkt Ihrem PayPal-Konto gutgeschrieben. {{this.selectedPaymentMethod ? 'Aus Sicherheitsgründen werden bereits eingegebene Zugangsdaten nicht angezeigt. Wenn Sie keine Änderungen vornehmen wollen, lassen Sie die folgenden Felder frei.' : ''}}</span>
      <div class="col-md-12">
        <div class="form-floating">
          <input class="form-control" formControlName="client" type="text">
          <label>PayPal API-Client Token</label>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-floating">
          <input class="form-control" formControlName="secret" type="text">
          <label>PayPal API-Secret Token</label>
        </div>
      </div>
    </ng-container>
    <div class="col-12">
      <button class="btn btn-danger btn-block text-uppercase mb-2 py-2 shadow-sm float-end" data-bs-dismiss="modal"
              type="button">Abbrechen
      </button>
      <button [ngClass]="{'disabled' : methodForm.invalid}"
              class="btn btn-success btn-block text-uppercase mb-2 py-2 shadow-sm float-end me-2"
              type="submit">Speichern
      </button>
    </div>
  </form>
</ng-template>
