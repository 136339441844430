<h3 class="fw-light">Öffnungszeiten {{dashboard.getDay(selectedShopHour.day)}}</h3>
<p class="text-muted mb-4">Öffnungszeiten Bearbeiten</p>
<app-loading *ngIf="loading; else editForm"></app-loading>
<ng-template #editForm>
  <form (ngSubmit)="updateHour()" [formGroup]="hourForm" class="row g-3">
    <div class="col-md-3">
      <div class="form-floating">
        <input class="form-control" formControlName="open_first" type="time">
        <label>Von</label>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-floating">
        <input class="form-control" formControlName="close_first" type="time">
        <label>Bis</label>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-floating">
        <input class="form-control" formControlName="open_second" type="time">
        <label>Von</label>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-floating">
        <input class="form-control" formControlName="close_second" type="time">
        <label>Bis</label>
      </div>
    </div>
    <div class="col-md-12">
      <input autocomplete="off" class="btn-check" formControlName="continuous" id="continuous-edit" type="checkbox">
      <label class="btn btn-outline-dark w-100" for="continuous-edit"><i class="fas fa-clock"></i> Durchgehend Geöffnet</label>
    </div>
    <div class="col-md-12">
      <input autocomplete="off" class="btn-check" formControlName="closed" id="closed-edit" type="checkbox">
      <label class="btn btn-outline-danger w-100" for="closed-edit"><i class="fas fa-store-slash"></i>
        Geschlossen</label>
    </div>
    <div class="col-12">
      <button class="btn btn-danger btn-block text-uppercase mb-2 py-2 shadow-sm float-end" data-bs-dismiss="modal"
              type="button">Abbrechen
      </button>
      <button [ngClass]="{'disabled' : hourForm.invalid}"
              class="btn btn-success btn-block text-uppercase mb-2 py-2 shadow-sm me-2 float-end"
              type="submit">Speichern
      </button>
    </div>
  </form>
</ng-template>

