import {Component} from '@angular/core';
import {DashboardService} from '../../../services/api/dashboard.service';

@Component({
  selector: 'app-tax-classes',
  templateUrl: './tax-classes.component.html',
  styleUrls: ['./tax-classes.component.css']
})
export class TaxClassesComponent {
  constructor(public dashboard: DashboardService) {
    this.dashboard.loadAllTaxClasses();
  }
}
