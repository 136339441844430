<app-loading *ngIf="loading; else fileUpload"></app-loading>
<ng-template #fileUpload>
  <form (ngSubmit)="uploadFiles()" [formGroup]="uploadForm" class="row g-3 mt-4">
    <div class="input-group">
      <input (change)="onFileChange($event)" class="form-control file-input" multiple type="file">
    </div>
  </form>
</ng-template>




