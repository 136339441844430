<p><strong>Widerrufsbelehrung</strong></p> <p>Verbraucher haben ein vierzehnt&auml;giges Widerrufsrecht.</p> <p><strong>Widerrufsrecht</strong><br/>
  Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gr&uuml;nden diesen Vertrag zu widerrufen. Die
  Widerrufsfrist betr&auml;gt vierzehn Tage ab dem Tag an dem Sie oder ein von Ihnen benannter Dritter, der nicht der
  Beförderer ist, die Waren in Besitz genommen haben bzw. hat.<br></p><p>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns
  ({{shop.current.name}} ({{ shop.current.address.company}}
  ), {{shop.current.address.street}} {{shop.current.address.number}}
  , {{shop.current.address.code}} {{shop.current.address.city}}, {{shop.current.address.country}}
  , {{shop.current.address.email}}, Telefon: {{shop.current.address.phone}}) mittels
  einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss,
  diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das
  jedoch nicht vorgeschrieben ist. </p><p>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung &uuml;ber
  die Aus&uuml;bung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.  <p><strong>Folgen des
  Widerrufs </strong> <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten
  haben, einschlie&szlig;lich der Lieferkosten (mit Ausnahme der zus&auml;tzlichen Kosten, die sich daraus ergeben, dass
  Sie eine andere Art der Lieferung als die von uns angebotene, g&uuml;nstigste Standardlieferung gew&auml;hlt haben),
  unverz&uuml;glich und sp&auml;testens binnen vierzehn Tagen ab dem Tag zur&uuml;ckzuzahlen, an dem die Mitteilung
  &uuml;ber Ihren Widerruf dieses Vertrags bei uns eingegangen ist. F&uuml;r diese R&uuml;ckzahlung verwenden wir
  dasselbe Zahlungsmittel, das Sie bei der urspr&uuml;nglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
  wurde ausdr&uuml;cklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser R&uuml;ckzahlung Entgelte
  berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den
  Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.<br>
</p><p>Sie haben die Waren unverz&uuml;glich und in jedem Fall sp&auml;testens binnen vierzehn Tagen ab dem Tag, an dem
  Sie uns &uuml;ber den Widerruf dieses Vertrags unterrichten, an uns zur&uuml;ckzusenden oder zu &uuml;bergeben. Die
  Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Sie tragen die unmittelbaren
  Kosten der R&uuml;cksendung der Waren. Sie m&uuml;ssen f&uuml;r einen etwaigen Wertverlust der Waren nur aufkommen,
  wenn dieser Wertverlust auf einen zur Pr&uuml;fung der Beschaffenheit,
  Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zur&uuml;ckzuf&uuml;hren ist. </p><p>Das
  Widerrufsrecht besteht nicht bei den folgenden Vertr&auml;gen:</p>
<ul>
  <li>Vertr&auml;ge zur Lieferung von Waren, die nicht vorgefertigt sind und f&uuml;r deren Herstellung eine
    individuelle Auswahl oder Bestimmung durch den Verbraucher ma&szlig;geblich ist oder die eindeutig auf die pers&ouml;nlichen
    Bed&uuml;rfnisse des Verbrauchers zugeschnitten sind.
  </li>
</ul>
<ul>
  <li>Vertr&auml;ge zur Lieferung von Waren, die schnell verderben k&ouml;nnen oder deren Verfallsdatum schnell &uuml;berschritten
    w&uuml;rde.
  </li>
</ul>
<table border="1" cellpadding="1" cellspacing="1" style="width:100%">
  <tbody>
  <tr>
    <td><p><strong>Muster-Widerrufsformular</strong></p>
      <p><em>(Wenn Sie den Vertrag widerrufen wollen, dann f&uuml;llen Sie bitte dieses Formular aus und senden Sie es
        zur&uuml;ck.)</em></p>
      <p>&ndash; An {{shop.current.name}} ({{ shop.current.address.company}}
        ), {{shop.current.address.street}} {{shop.current.address.number}}
        , {{shop.current.address.code}} {{shop.current.address.city}}, {{shop.current.address.country}}</p>
      <p>&ndash; Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag &uuml;ber den Kauf der
        folgenden<br/> Waren (*)/die Erbringung der folgenden Dienstleistung (*)</p>
      <p>&ndash; Bestellt am (*)/erhalten am (*)</p>
      <p>&ndash; Name des/der Verbraucher(s)</p>
      <p>&ndash; Anschrift des/der Verbraucher(s)</p>
      <p>&ndash; Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</p>
      <p>&ndash; Datum<br/> <br/> <em>(*) Unzutreffendes streichen.</em></p></td>
  </tr>
  </tbody>
</table><br>
