import {Component} from '@angular/core';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {Category} from '../../../../models/category.model';
import {ApiConfig} from '../../../../services/api/api.config';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-list-categories',
  templateUrl: './list-categories.component.html',
  styleUrls: ['./list-categories.component.css']
})

export class ListCategoriesComponent {

  selectedCategory: Category;

  constructor(public dashboard: DashboardService, public config: ApiConfig, private alert: ToastrService) {
  }

  deleteCategory(category: Category) {
    this.dashboard.deleteCategory(category)
      .pipe(first())
      .subscribe(
        result => {
          this.dashboard.categories.splice(this.dashboard.categories.indexOf(category), 1);
          this.alert.success('Die Kategorie wurde erfolgreich gelöscht.');
        },
        error => {
          this.alert.warning(error);
        }
      );
  }
}
