<div class="row p-0">
  <div class="col-12 p-0">
    <h3 class="fw-light">Shopübersicht</h3>
    <p class="text-muted mb-4">{{selectedUser?.name}} {{selectedUser?.surname}}</p>
  </div>
</div>

<ng-container *ngIf="selectedUser?.shops?.length; else noShops">
  <div *ngFor="let shop of selectedUser?.shops; let i = index" class="row mt-2 shop-item">
    <div class="col-md-8 justify-content-center align-self-center">
      {{shop.name}}
    </div>
    <div class="col-md-4 justify-content-center align-self-center">
      <a [routerLink]="['/dashboard', shop.shortname, 'settings']" class="btn btn-success float-end"
         data-bs-dismiss="modal"><i class="fas fa-store"></i> Dashboard</a>
    </div>
  </div>
</ng-container>

<ng-template #noShops>
  <h6 class="fw-light mt-2 text-secondary"><i class="fas fa-exclamation-triangle text-warning"></i> Es konnten keine
    Shops gefunden werden.</h6>
</ng-template>
