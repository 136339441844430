import {ImageFile} from './file.model';

export class Category {
  shop: string;
  uuid: string;
  name: string;
  description: string;
  image: ImageFile;

  constructor(shop: string, uuid: string, name: string, description: string, image: ImageFile) {
    this.shop = shop;
    this.uuid = uuid;
    this.name = name;
    this.description = description;
    this.image = image;
  }
}

export class CategoryCreate {
  shop: string;
  name: string;
  description: string;
  image: string;

  constructor(shop: string, name: string, description: string, image: string) {
    this.shop = shop;
    this.name = name;
    this.description = description;
    this.image = image;
  }
}

