<app-loading *ngIf="!dashboard.responses.files else fileGrid"></app-loading>
<ng-template #fileGrid>
  <ng-container *ngIf="dashboard.files.length; else nothingFound">
    <div class="row mt-5">
      <div *ngFor="let file of dashboard.files;" class="col-md-4 mb-5 d-flex align-items-stretch">
        <div class="card border-0 file-card bg-light text-dark shadow">
          <img class="card-img-top p-3" src="{{config.fileUrl}}{{file.url}}">
          <div class="card-body">
            <p class="card-title">{{file.name}}</p>
          </div>
          <div class="btn-group" role="group">
            <a (click)="deleteFile(file)" class="btn btn-danger file-button"><i class="fas fa-trash"></i> Löschen</a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #nothingFound>
    <app-nothing-found></app-nothing-found>
  </ng-template>
</ng-template>
