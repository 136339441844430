import {Component} from '@angular/core';
import {DashboardService} from '../../../services/api/dashboard.service';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.css']
})
export class PaymentMethodsComponent {
  constructor(public dashboard: DashboardService) {
    this.dashboard.loadAllPaymentMethods();
    this.dashboard.loadAllPaymentFees();
  }
}
