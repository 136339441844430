<app-loading *ngIf="loading; else createForm"></app-loading>
<ng-template #createForm>
  <h3 class="fw-light">{{this.selectedProduct ? 'Produkt Bearbeiten' : 'Neues Produkt'}}</h3>
  <p
    class="text-muted mb-4">{{this.selectedProduct ? ('Bearbeiten Sie das Produkt ' + this.selectedProduct.name + '.') : 'Erstellen Sie ein neues Produkt.'}}</p>
  <form (ngSubmit)="updateProduct()" [formGroup]="productForm" class="row g-3">
    <div class="col-md-4">
      <div class="form-floating">
        <input class="form-control" formControlName="name" type="text">
        <label>Name</label>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-floating">
        <input class="form-control" formControlName="price" type="number">
        <label>Preis</label>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-floating">
        <select class="form-select" formControlName="image">
          <optgroup [selected]="selectedProduct?.image ? selectedProduct?.image?.uuid : form.image.value"
                    label="Hochgeladene Dateien"></optgroup>
        </select>
        <label>Bild</label>
      </div>
    </div>
    <div class="col-12">
      <div class="form-floating">
        <textarea class="form-control" formControlName="description" style="height: 75px;"></textarea>
        <label>Beschreibung</label>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="form-floating">
        <select class="form-select" formControlName="taxclass">
          <option ngValue="''">Auswählen...</option>
          <option *ngFor="let taxclass of dashboard.taxClasses;" [ngValue]="taxclass.uuid"
                  [selected]="selectedProduct?.taxclass?.uuid === taxclass.uuid">{{taxclass.name}}</option>
        </select>
        <label>Steuerklasse</label>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="form-floating">
        <select class="form-select" formControlName="category">
          <option value="">Auswählen...</option>
          <option *ngFor="let category of dashboard.categories;" [ngValue]="category.uuid"
                  [selected]="selectedProduct?.category?.uuid === category.uuid">{{category.name}}</option>
        </select>
        <label>Kategorie</label>
      </div>
    </div>
    <div class="col-md-4">
      <input autocomplete="off" class="btn-check" formControlName="available" id="available-create" type="checkbox">
      <label class="btn btn-outline-success w-100" for="available-create"><i class="fas fa-box"></i> Verfügbar</label>
    </div>
    <div class="col-12 mt-3">
      <ng-multiselect-dropdown
        [settings]="dropdownSettings"
        [(ngModel)]="selectedAddonLists"
        [data]="dashboard.addonLists"
        [ngModelOptions]="{standalone: true}"
        [placeholder]="'Addonlisten Auswählen...'"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-12 mt-3">
      <ng-multiselect-dropdown
        [settings]="dropdownSettings"
        [(ngModel)]="selectedAdditives"
        [data]="dashboard.additives"
        [ngModelOptions]="{standalone: true}"
        [placeholder]="'Zusatzstoffe und Allergene Auswählen...'"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-12">
      <button class="btn btn-danger btn-block text-uppercase mb-2 py-2 shadow-sm float-end" data-bs-dismiss="modal"
              type="button">Abbrechen
      </button>
      <button [ngClass]="{'disabled' : productForm.invalid}"
              class="btn btn-success btn-block text-uppercase mb-2 py-2 shadow-sm me-2 float-end"
              type="submit">Speichern
      </button>
    </div>
  </form>
</ng-template>
