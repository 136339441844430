import {Component} from '@angular/core';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {CodeList} from '../../../../models/codelist.model';
import {ApiConfig} from '../../../../services/api/api.config';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-list-code-lists',
  templateUrl: './list-code-lists.component.html',
  styleUrls: ['./list-code-lists.component.css']
})
export class ListCodeListsComponent {

  selectedList: CodeList;

  constructor(public dashboard: DashboardService, public config: ApiConfig, private alert: ToastrService) {
  }

  deleteList(list) {
    this.dashboard.deleteCodeList(list)
      .pipe(first())
      .subscribe(
        result => {
          this.dashboard.shippingAreas.splice(this.dashboard.shippingAreas.indexOf(list), 1);
          this.alert.success('Das Liefergebiet wurde erfolgreich gelöscht.');
        },
        error => {
          this.alert.warning(error);
        }
      );
  }
}
