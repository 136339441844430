import {Component} from '@angular/core';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {ApiConfig} from '../../../../services/api/api.config';
import {first} from 'rxjs/operators';
import {PaymentMethod} from '../../../../models/payment-method.model';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-list-payment-methods',
  templateUrl: './list-payment-methods.component.html',
  styleUrls: ['./list-payment-methods.component.css']
})
export class ListPaymentMethodsComponent {

  selectedPaymentMethod: PaymentMethod;

  constructor(public dashboard: DashboardService, public config: ApiConfig, private alert: ToastrService) {
  }

  deletePaymentMethod(method) {
    this.dashboard.deletePaymentMethod(method)
      .pipe(first())
      .subscribe(
        result => {
          this.dashboard.paymentMethods.splice(this.dashboard.paymentMethods.indexOf(method), 1);
        },
        error => {
          this.alert.warning(error);
        }
      );
  }
}
