<h1 class="fw-light">Einstellungen</h1>
<h5 class="text-muted fw-light">{{dashboard.selectedShop.name}}</h5>
<div class="row mt-5">
  <div class="col-lg-4 mb-5">
    <div class="shop-toggle p-4 shadow">
      <h5 class="mb-0">Kontaktdaten</h5>
      <p class="text-muted mb-3 mt-0">Adresse und Ansprechpartner</p>
      <a class="link-success" href="{{config.frontend}}/shop/{{dashboard.selectedShop.shortname}}" rel="noreferrer"
         target="_blank">
        <img *ngIf="dashboard.selectedShop.logo" class="mb-3 mx-auto d-block img-fluid"
             src="{{config.fileUrl}}{{dashboard.selectedShop.logo ? dashboard.selectedShop.logo.url : ''}}"
             style="max-width: 250px; max-height: 125px;">
        <img *ngIf="!dashboard.selectedShop.logo" class="mb-3 mx-auto d-block img-fluid"
             src="/assets/images/motion-circle-logo-dark.png"
             style="max-width: 250px; max-height: 125px;">
      </a>
      <p>
        {{dashboard.selectedShop.address.name}} {{dashboard.selectedShop.address.surname}}<br>
        {{dashboard.selectedShop.address.company}}
      </p>
      <p>
        {{dashboard.selectedShop.address.street}} {{dashboard.selectedShop.address.number}}<br>
        {{dashboard.selectedShop.address.code}} {{dashboard.selectedShop.address.city}}<br>
        {{dashboard.selectedShop.address.country}}
      </p>
      <a class="link-success" href="mailto:{{dashboard.selectedShop.address.email}}"><i
        class="fas fa-envelope"></i> {{dashboard.selectedShop.address.email}}</a>
      <br>
      <a class="link-success" href="phone:{{dashboard.selectedShop.address.phone}}"><i
        class="fas fa-phone-square"></i> {{dashboard.selectedShop.address.phone}}</a>
      <br>
      <br>
      <a class="btn btn-small btn-success w-100" data-bs-target="#editShopModal" data-bs-toggle="modal"><i
        class="fas fa-pen"></i> Bearbeiten</a>
    </div>
  </div>
  <div class="col-lg-4 mb-5">
    <div class="shop-toggle p-4 shadow">
      <h5 class="mb-0">Öffnungszeiten | Lieferzeit</h5>
      <p class="text-muted mb-3 mt-0">Öffnungszeiten und Lieferzeit Ihres Shops.</p>
      <app-loading *ngIf="loading.fullfillment; else fullfillmentFormTemplate"></app-loading>
      <ng-template #fullfillmentFormTemplate>
        <form (ngSubmit)="updateFullfillmentTime()" [formGroup]="fullfillmentForm" class="row g-3 mb-4">
          <h6 class="text-muted">Aktuelle Lieferzeit (Min.)</h6>
          <div class="col-9 mt-0">
            <div class="form-group">
              <input class="form-control" formControlName="fullfillment" placeholder="30" type="number">
            </div>
          </div>
          <div class="col-3 mt-0">
            <button [ngClass]="{'disabled' : fullfillmentForm.invalid}"
                    class="btn btn-success btn-block text-uppercase mb-2 shadow-sm w-100"
                    type="submit"><i class="fas fa-check"></i></button>
          </div>
        </form>
      </ng-template>
      <ul class="list-group">
        <li *ngFor="let hour of dashboard.selectedShop.hours;"
            class="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
          <a (click)="selectedShopHour = hour" data-bs-target="#editHourModal" data-bs-toggle="modal"><i
            class="fas fa-cogs text-success"></i> {{dashboard.getDay(hour.day)}}</a>
          <ng-container *ngIf="hour.closed; else openingHours">
            <span class="badge bg-danger rounded-pill p-2">Geschlossen</span>
          </ng-container>
          <ng-template #openingHours>
            <ng-container *ngIf="hour.continuous; else showSlots">
              <span class="badge bg-success rounded-pill p-2">{{hour.slots[0].open}} - {{hour.slots[0].close}}</span>
            </ng-container>
            <ng-template #showSlots>
              <span class="badge bg-success rounded-pill p-2">
                <ng-container *ngFor="let slot of hour.slots">
                  {{slot.open}} - {{slot.close}}
                </ng-container>
              </span>
            </ng-template>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-lg-4 mb-5">
    <app-loading *ngIf="loading.status; else shopStatusTemplate"></app-loading>
    <ng-template #shopStatusTemplate>
      <ng-container *ngIf="dashboard.selectedShop.active; else shopInactive">
        <div class="shop-toggle p-4 shadow">
          <ng-container *ngIf="dashboard.selectedShop.open; else shopClosed">
            <p><i class="fas fa-info-circle"></i> Ihr Shop ist geöffnet.</p>
            <button (click)="updateShopStatus({open:false})" class="btn btn-danger w-100"><i class="fas fa-times"></i>
              Schließen
            </button>
          </ng-container>
          <ng-template #shopClosed>
            <p><i class="fas fa-info-circle"></i> Ihr Shop ist geschlossen.</p>
            <button (click)="updateShopStatus({open:true})" class="btn btn-success w-100"><i class="fas fa-check"></i>
              Öffnen
            </button>
          </ng-template>
          <div class="shop-status shop-active p-4 mt-2 shadow">
            <h4><i class="fas fa-check-circle"></i> Aktiviert</h4>
            <p>Ihr Shop ist aktiviert und zum Verkauf freigegeben. Damit Kunden bei Ihnen bestellen können, muss der
              Shop
              vollständig konfiguriert und geöffnet sein.</p>
            <ng-container *ngIf="auth.user.admin">
              <button (click)="updateShopStatus({active:false})" class="btn btn-dark"><i
                class="fas fa-exclamation-triangle"></i> Deaktivieren
              </button>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-template #shopInactive>
        <div class="shop-status shop-inactive p-4 shadow">
          <h4><i class="fas fa-exclamation-triangle"></i> Deaktiviert</h4>
          <p>Ihr Shop ist derzeit deaktiviert und nicht zum Verkauf freigegeben. Alle neu registrierten Shops werden
            durch unser Team überprüft und anschließend freigegeben.</p>
          <ng-container *ngIf="auth.user.admin">
            <button (click)="updateShopStatus({active:true})" class="btn btn-dark"><i
              class="fas fa-exclamation-triangle"></i> Aktivieren
            </button>
          </ng-container>
        </div>
      </ng-template>
    </ng-template>
  </div>
  <div class="col-lg-4 mb-5">
    <div class="shop-toggle p-4 shadow">
      <h5 class="mb-0">Kassenschnitt</h5>
      <p class="text-muted mb-3 mt-0">Erstellen Sie eine Abrechnung.</p>
      <app-loading *ngIf="loading.cut; else cutFormTemplate"></app-loading>
      <ng-template #cutFormTemplate>
        <form (ngSubmit)="createCut()" [formGroup]="cutForm">
          <div class="form-floating mb-2">
            <input class="form-control" formControlName="start" type="date">
            <label>Startdatum</label>
          </div>
          <div class="form-floating mb-2">
            <input class="form-control" formControlName="end" type="date">
            <label>Enddatum</label>
          </div>
          <p class="text-muted mt-3 mb-3">Der Kassenschitt wird als Beleg an ihr reguläres Empfangsgerät gesendet.</p>
          <button [ngClass]="{'disabled' : cutForm.invalid}"
                  class="btn btn-success btn-block text-uppercase mb-2 py-2 shadow-sm w-100"
                  type="submit"><i class="fas fa-check"></i> Erstellen
          </button>
        </form>
      </ng-template>
    </div>
  </div>
  <div class="col-lg-4 mb-5">
    <div class="shop-toggle p-4 shadow">
      <h5 class="mb-0">Empfangsgerät | API</h5>
      <p class="text-muted mb-3 mt-0">Empfangen Sie Ihre Bestellungen.</p>
      <p class="mt-3 mb-3">Für alle Bestellungen wird automatisch ein Beleg angelegt, der über ein von uns zur Verfügung
        gestelltes
        Empfangsgerät automatisch abgerufen und gedruckt werden kann.</p>
      <div class="bg-light p-3 rounded">
        <span class="text-success">API Token</span><br>
        <small style="font-size: 12px;">{{dashboard.selectedShop.api}}</small>
      </div>
    </div>
  </div>
</div>


<!-- Shop Modal -->
<div aria-hidden="true" class="modal fade" id="editShopModal" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="container p-5 pt-0">
        <app-edit-shop></app-edit-shop>
      </div>
    </div>
  </div>
</div>

<!-- Hour Modal -->
<div aria-hidden="true" class="modal fade" id="editHourModal" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="container p-5 pt-0">
        <app-edit-opening-hour *ngIf="selectedShopHour" [selectedShopHour]="selectedShopHour"></app-edit-opening-hour>
      </div>
    </div>
  </div>
</div>

