import {Component, OnInit} from '@angular/core';
import {ShopService} from '../../../services/api/shop.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {OrderCreate, OrderCreationResponse} from '../../../models/order.model';
import {Address} from '../../../models/address.model';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  cartForm: UntypedFormGroup;

  public response: OrderCreationResponse;

  public StateEnum = State;
  public state = State.cart;

  constructor(public shop: ShopService, public formBuilder: UntypedFormBuilder, private alert: ToastrService) {
  }

  get form(): any {
    return this.cartForm.controls;
  }

  ngOnInit(): void {
    this.cartForm = this.formBuilder.group({
      name: ['', Validators.required],
      company: [''],
      street: ['', Validators.required],
      number: ['', Validators.required],
      code: ['', Validators.required],
      city: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      shipping: ['', Validators.required],
      payment: ['', Validators.required],
      fullfillment: ['', Validators.required],
      note: ['']
    });
  }

  submitOrder(): void {
    if (this.cartForm.invalid) return;

    this.state = State.loading;

    const addressModel = new Address(this.form.name.value, '', this.form.company.value, this.form.street.value, this.form.number.value, this.form.code.value, this.form.city.value, this.shop.current.address.country, this.form.email.value, this.form.phone.value);
    const orderModel = new OrderCreate(this.shop.current.uuid, addressModel, this.form.shipping.value, this.form.payment.value, this.form.fullfillment.value, this.form.note.value, this.shop.cart, this.shop.getCartSum());

    this.shop.createOrder(orderModel)
      .pipe(first())
      .subscribe(
        result => {
          this.response = result;

          if (result.paypal) {
            this.state = State.paypal;
            this.shop.clearCart();
            window.location.href = result.link;
          } else {
            this.state = State.success;
            this.shop.clearCart();
            this.cartForm.reset();
          }
        },
        error => {
          this.state = State.checkout;
          this.alert.error(error);
        }
      );
  }
}

enum State {
  cart,
  checkout,
  success,
  paypal,
  loading
}
