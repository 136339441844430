<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <a class="navbar-brand" routerLink="/admin"><img
      alt="" class="d-inline-block align-top" height="35" src="/assets/images/motion-circle-logo-dark.png"></a>
    <button aria-controls="navbarContent" aria-expanded="false" class="navbar-toggler" data-bs-target="#navbarContent"
            data-bs-toggle="collapse" type="button">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
      <form class="d-flex">
        <a class="btn btn-dark me-2" routerLink="/account"><i class="fas fa-user"></i> Account</a>
      </form>
    </div>
  </div>
</nav>
<div class="container mt-4 mt-lg-5 p-4 p-lg-3">
  <h1 class="fw-light">Benutzer</h1>
  <h5 class="text-muted fw-light">Administrator Dashboard</h5>
  <app-loading *ngIf="!auth.responses.users; else userTable"></app-loading>
  <ng-template #userTable>
    <table class="table mt-5">
      <thead>
      <tr>
        <th scope="col"><i class="fas fa-unlock"></i> Status</th>
        <th scope="col"><i class="fas fa-address-card"></i> Kontakt</th>
        <th scope="col"><i class="fas fa-tasks"></i> Berechtigung</th>
        <th class="text-end" scope="col"><i class="fas fa-store"></i> Onlineshops</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let user of pageOfItems">
        <td class="align-middle">
          <span *ngIf="user.active"><i class="fas fa-check-circle text-success"></i> Aktiviert</span>
          <span *ngIf="!user.active"><i class="fas fa-lock text-danger"></i> Gesperrt</span>
        </td>
        <td class="align-middle">{{user.name}} {{user.surname}}<br>{{user.email}}<br>{{user.phone}}</td>
        <td class="align-middle">
          <span *ngIf="user.admin"><i class="fas fa-user-shield text-success"></i> Administrator</span>
          <span *ngIf="!user.admin"><i class="fas fa-user"></i> Kundenkonto</span>
        </td>
        <td class="align-middle">
          <button (click)="selectedUser = user" class="btn btn-small btn-success float-end" data-bs-target="#shopModal"
                  data-bs-toggle="modal">Übersicht <span
            class="badge bg-light text-dark">{{user.shops.length}}</span></button>
        </td>
        <td class="align-middle">
          <button (click)="selectedUser = user" class="btn btn-small btn-dark float-end" data-bs-target="#userModal"
                  data-bs-toggle="modal"><i class="fas fa-sliders-h"></i></button>
        </td>
      </tr>
      </tbody>
    </table>
    <jw-pagination (changePage)="onChangePage($event)" [items]="auth.users"></jw-pagination>
  </ng-template>
</div>

<!-- Shop Modal -->
<div aria-hidden="true" class="modal fade" id="shopModal" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="container p-5 pt-0">
        <app-user-shop-overview [selectedUser]="selectedUser"></app-user-shop-overview>
      </div>
    </div>
  </div>
</div>

<!-- User Modal -->
<div aria-hidden="true" class="modal fade" id="userModal" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="container p-5 pt-0">
        <app-user-details [selectedUser]="selectedUser"></app-user-details>
      </div>
    </div>
  </div>
</div>





