<ng-container *ngIf="registrationSuccess; else registrationTemplate">
  <h3 class="display-4">Willkommen</h3>
  <p>Ihr Benutzerkonto wurde erfolgreich erstellt. In den nächsten Minuten erhalten Sie eine E-Mail mit den nun
    folgenden Schritten.<br><br>Vielen Dank für Ihr Vertrauen.</p>
  <p>Willkommen bei <b>Motion Circle.</b></p>
</ng-container>

<ng-template #registrationTemplate>
  <app-loading *ngIf="loading; else registerTemplate"></app-loading>
  <ng-template #registerTemplate>
    <h3 class="display-4">Registrierung</h3>
    <p class="text-muted mb-4">Registrieren Sie sich für Ihr digitales Restaurant.</p>
    <form (ngSubmit)="registerUser()" [formGroup]="registerForm">
      <div class="form-group mb-3">
        <input class="form-control border-0 shadow-sm px-4 py-2" formControlName="name" placeholder="Name" type="text">
      </div>
      <div class="form-group mb-3">
        <input class="form-control border-0 shadow-sm px-4 py-2" formControlName="surname" placeholder="Nachname"
               type="text">
      </div>
      <div class="form-group mb-3">
        <input autofocus class="form-control border-0 shadow-sm px-4 py-2" formControlName="email" placeholder="E-Mail"
               type="email">
      </div>
      <div class="form-group mb-3">
        <input autofocus class="form-control border-0 shadow-sm px-4 py-2" formControlName="phone"
               placeholder="Telefonnummer">
      </div>
      <div class="form-group mb-3">
        <input class="form-control border-0 shadow-sm px-4 text-success py-2" formControlName="password"
               placeholder="Passwort (min. 6 Zeichen)" type="password">
      </div>
      <div class="form-group mb-3">
        <span class="text-secondary">Diese Registrierung ist <b>ausschließlich</b> für Restaurantbesitzer.</span>
      </div>
      <button [ngClass]="{'disabled': registerForm.invalid }"
              class="btn btn-success btn-block text-uppercase mb-2 py-2 shadow-sm" type="submit">Registrieren
      </button>
    </form>
  </ng-template>
</ng-template>

<div class="mt-5 border-top py-4">
  <p><a [routerLink]="['/impressum']" class="link text-secondary">Impressum</a> | <a [routerLink]="['/datenschutz']"
                                                                                     class="link text-secondary">Datenschutz</a>
  </p>
</div>
