<app-loading *ngIf="loading; else createForm"></app-loading>
<ng-template #createForm>
  <h3 class="fw-light">{{selectedMethod ? 'Liefermethode Bearbeiten' : 'Neue Liefermethode'}}</h3>
  <p
    class="text-muted mb-4">{{selectedMethod ? ('Bearbeiten Sie die Liefermethode ' + selectedMethod?.name + '.') : 'Erstellen Sie eine neue Liefermethode.'}}</p>
  <form (ngSubmit)="createShippingMethod()" [formGroup]="methodForm" class="row g-3">
    <div class="col-md-6">
      <div class="form-floating">
        <input class="form-control" formControlName="name" type="text">
        <label>Name</label>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-floating">
        <select class="form-select" formControlName="codelist">
          <option [ngValue]="''" [selected]="true">Auswählen...</option>
          <option *ngFor="let list of dashboard.shippingAreas;" [ngValue]="list.uuid"
                  [selected]="selectedMethod?.codelist && selectedMethod?.codelist?.uuid === list.uuid">{{list.name}}</option>
        </select>
        <label>Liefergebiet</label>
      </div>
    </div>
    <div class="col-12">
      <div class="form-floating">
        <textarea class="form-control" formControlName="description" style="height: 75px;"></textarea>
        <label>Beschreibung</label>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-floating">
        <input class="form-control" formControlName="price" type="number">
        <label>Preis €</label>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-floating">
        <input class="form-control" formControlName="minordervalue" type="number">
        <label>Mindestbestellwert €</label>
      </div>
    </div>
    <div class="col-md-12">
      <input autocomplete="off" class="btn-check" formControlName="pickup" id="pickup-create" type="checkbox">
      <label class="btn btn-outline-success w-100" for="pickup-create"><i class="fas fa-store"></i> Abholung</label>
    </div>
    <div class="col-12">
      <button class="btn btn-danger btn-block text-uppercase mb-2 py-2 shadow-sm float-end" data-bs-dismiss="modal"
              type="button">Abbrechen
      </button>
      <button [ngClass]="{'disabled' : methodForm.invalid}"
              class="btn btn-success btn-block text-uppercase mb-2 py-2 shadow-sm float-end me-2"
              type="submit">Speichern
      </button>
    </div>
  </form>
</ng-template>
