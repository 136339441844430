import {Component} from '@angular/core';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {ApiConfig} from '../../../../services/api/api.config';
import {Product} from '../../../../models/product.model';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-list-products',
  templateUrl: './list-products.component.html',
  styleUrls: ['./list-products.component.css']
})
export class ListProductsComponent {

  selectedProduct: Product;
  searchString: string = "";

  constructor(public dashboard: DashboardService, public config: ApiConfig, private alert: ToastrService) {
  }

  updateSearchString(value) {
    this.searchString = value.target.value;
  }

  deleteProduct(product) {
    this.dashboard.deleteProduct(product)
      .pipe(first())
      .subscribe(
        result => {
          this.dashboard.products.splice(this.dashboard.products.indexOf(product), 1);
        },
        error => {
          this.alert.warning(error);
        }
      );
  }
}
