export class AddonList {
  shop: string;
  uuid: string;
  name: string;
  multiselect: boolean;
  addons: Addon[];

  constructor(shop: string, uuid: string, name: string, addons: Addon[], multiselect: boolean) {
    this.shop = shop;
    this.uuid = uuid;
    this.name = name;
    this.addons = addons;
    this.multiselect = multiselect;
  }
}

export class Addon {
  uuid: string;
  name: string;
  price: number;
  addonlist: string;
  addonlistName: string;

  constructor(uuid: string, addonlist: string, name: string, price: number) {
    this.uuid = uuid;
    this.addonlist = addonlist;
    this.name = name;
    this.price = price;
  }
}

