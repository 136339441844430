import {Component, Input, OnChanges} from '@angular/core';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {CodeList} from '../../../../models/codelist.model';
import {first} from 'rxjs/operators';
import {ShippingMethod} from '../../../../models/shippingmethod.model';
import {ToastrService} from 'ngx-toastr';
import {updateObject} from '../../../../helper/object.helper';

@Component({
  selector: 'app-create-shipping-method',
  templateUrl: './create-shipping-method.component.html',
  styleUrls: ['./create-shipping-method.component.css']
})
export class CreateShippingMethodComponent implements OnChanges {
  @Input() selectedMethod: ShippingMethod;

  methodForm: UntypedFormGroup;
  loading = false;

  constructor(private router: Router, private formBuilder: UntypedFormBuilder, public dashboard: DashboardService, private alert: ToastrService) {
  }

  get form(): any {
    return this.methodForm.controls;
  }

  ngOnChanges(): void {
    this.methodForm = this.formBuilder.group({
      name: [this.selectedMethod?.name ?? '', Validators.required],
      description: [this.selectedMethod?.description ?? '', Validators.required],
      price: [this.selectedMethod?.price ?? '', Validators.required],
      minordervalue: [this.selectedMethod?.minordervalue ?? '', Validators.required],
      codelist: [this.selectedMethod?.codelist?.uuid ?? ''],
      pickup: [this.selectedMethod?.pickup ?? '']
    });
  }

  createShippingMethod(): void {
    if (this.methodForm.invalid) return;

    this.loading = true;

    const codeListModel = new CodeList(undefined, this.form.codelist.value, undefined, undefined, undefined);
    const update = new ShippingMethod(this.dashboard.selectedShop.uuid, this.selectedMethod?.uuid ?? undefined, this.form.name.value, this.form.description.value, this.form.price.value, this.form.minordervalue.value, codeListModel, !!this.form.pickup.value);

    if (!this.selectedMethod) {
      this.dashboard.createShippingMethod(update)
        .pipe(first())
        .subscribe(
          result => {
            this.loading = false;
            this.methodForm.reset();
            this.dashboard.shippingMethods.push(result);
            this.alert.success('Die Liefermethode wurde erfolgreich erstellt.');
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    } else {
      this.dashboard.updateShippingMethod(update)
        .pipe(first())
        .subscribe(
          result => {
            this.loading = false;
            updateObject(this.selectedMethod, result);
            this.alert.success('Die Liefermethode wurde erfolgreich aktualisiert.');
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    }
  }
}
