import {Component} from '@angular/core';
import {DashboardService} from '../../../services/api/dashboard.service';

@Component({
  selector: 'app-code-lists',
  templateUrl: './code-lists.component.html',
  styleUrls: ['./code-lists.component.css']
})
export class CodeListsComponent {
  constructor(public dashboard: DashboardService) {
    this.dashboard.loadAllShippingAreas();
  }
}
