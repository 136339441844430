import {Component} from '@angular/core';
import {DashboardService} from '../../../services/api/dashboard.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent {
  constructor(public dashboard: DashboardService) {
    this.dashboard.loadAllCategories();
    this.dashboard.loadAllFiles();
  }
}
