import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {DashboardService} from '../../../../services/api/dashboard.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})

export class UploadComponent {
  uploadForm: UntypedFormGroup;
  loading = false;

  files = [];

  constructor(private formBuilder: UntypedFormBuilder, private alert: ToastrService, private dashboard: DashboardService) {
    this.uploadForm = this.formBuilder.group({});
  }

  get form(): any {
    return this.uploadForm.controls;
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.files = event.target.files;
      this.uploadFiles().then(() => {
      });
    }
  }

  async uploadFiles() {
    if (this.uploadForm.invalid) return;

    this.loading = true;

    Array.from(this.files).forEach(async function (element, index) {
      await this.dashboard.uploadFile(element, this.dashboard.selectedShop.owner)
        .pipe(first())
        .subscribe(
          result => {
            if (index == this.files.length - 1) {
              this.loading = false;
              this.files = [];
            }
            this.dashboard.files.unshift(result);
            this.alert.success('Die Datei ' + element.name + ' wurde erfolgreich gespeichert.');
          },
          error => {
            if (index == this.files.length - 1) {
              this.loading = false;
              this.files = [];
            }
            this.alert.error(element.name + ': ' + error);
          }
        )
    }, this);
  }
}
