import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/api/auth.service';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Meta, Title} from '@angular/platform-browser';
import {LoginData} from "../../../models/login.model";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;
  loading = false;

  constructor(private auth: AuthService, private router: Router, private formBuilder: UntypedFormBuilder, private alert: ToastrService, public titleService: Title, public metaService: Meta) {
  }

  get form(): any {
    return this.loginForm.controls;
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    this.titleService.setTitle('Login');
    this.metaService.updateTag({
      name: 'description',
      content: 'Melden Sie sich bei Ihrem Benutzeraccount an und verwalten Sie Ihre Onlineshops.'
    });
  }

  loginUser(): void {
    if (this.loginForm.invalid) return;

    this.loading = true;

    this.auth.loginUser(new LoginData(this.form.email.value, this.form.password.value))
      .pipe(first())
      .subscribe(
        result => {
          this.auth.setToken(result.token);
          this.auth.redirectToAccount()
            .then(value => {
              this.loginForm.reset();
              this.loading = false;
            })
            .catch(error => {
              this.alert.error(error);
              this.loading = false;
            });
        },
        error => {
          this.alert.error(error);
          this.loading = false;
        }
      );
  }
}
