import {Component} from '@angular/core';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {ApiConfig} from '../../../../services/api/api.config';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {AddonList} from "../../../../models/addon.model";

@Component({
  selector: 'app-list-addon-lists',
  templateUrl: './list-addon-lists.component.html',
  styleUrls: ['./list-addon-lists.component.css']
})
export class ListAddonListsComponent {

  selectedList: AddonList;
  loading = false;

  constructor(public dashboard: DashboardService, public config: ApiConfig, private alert: ToastrService) {
  }

  deleteList(list) {
    this.dashboard.deleteAddonList(list)
      .pipe(first())
      .subscribe(
        result => {
          this.dashboard.addonLists.splice(this.dashboard.addonLists.indexOf(list), 1);
          this.alert.success('Die Addonliste wurde erfolgreich gelöscht.');
        },
        error => {
          this.alert.error(error);
        }
      );
  }
}
