<app-loading *ngIf="loading; else createShopTemplate"></app-loading>
<ng-template #createShopTemplate>
  <div class="row">
    <div class="col-12">
      <h3 class="fw-light">Neuer Shop</h3>
      <p class="text-muted mb-4">Erstellen Sie einen neuen Shop.</p>
    </div>
  </div>
  <form (ngSubmit)="createShop()" [formGroup]="shopForm" class="row g-3">
    <div class="col-md-8">
      <div class="form-floating">
        <input class="form-control" formControlName="name" type="text">
        <label>Shopname</label>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-floating">
        <input class="form-control" formControlName="shortname" type="text">
        <label>Kurzname</label>
      </div>
    </div>
    <div class="col-12 mb-4">
      <div class="form-floating">
        <textarea class="form-control" formControlName="description" style="height: 75px;"></textarea>
        <label>Beschreibung</label>
      </div>
    </div>
    <h5 class="mb-0">Kontaktdaten</h5>
    <p class="text-muted mb-4 mt-0">Adresse und Ansprechpartner Ihres neuen Shops.</p>
    <div class="col-md-4">
      <div class="form-floating">
        <input class="form-control" formControlName="address_name" type="text">
        <label>Vorname</label>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-floating">
        <input class="form-control" formControlName="address_surname" type="text">
        <label>Nachname</label>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-floating">
        <input class="form-control" formControlName="company" type="text">
        <label>Firmenname</label>
      </div>
    </div>
    <div class="col-md-5">
      <div class="form-floating">
        <input class="form-control" formControlName="street" type="text">
        <label>Straße</label>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-floating">
        <input class="form-control" formControlName="number" type="text">
        <label>Nummer</label>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-floating">
        <input class="form-control" formControlName="code" type="text">
        <label>Postleitzahl</label>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-floating">
        <input class="form-control" formControlName="city" type="text">
        <label>Stadt</label>
      </div>
    </div>
    <div class="col-md-8 mb-4">
      <div class="form-floating">
        <select class="form-select" formControlName="country">
          <option value="Deutschland">Deutschland</option>
          <option value="Österreich">Österreich</option>
          <option value="Schweiz">Schweiz</option>
        </select>
        <label>Land</label>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-floating">
        <input class="form-control" formControlName="email" type="text">
        <label>E-Mail</label>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-floating">
        <input class="form-control" formControlName="phone" type="text">
        <label>Telefonnummer</label>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-floating">
        <select class="form-select" formControlName="currency">
          <option value="EUR">EUR (€)</option>
          <option value="CHF">CHF (Fr.)</option>
        </select>
        <label>Währung</label>
      </div>
    </div>
    <div class="col-12">
      <a class="btn btn-danger btn-block text-uppercase mb-2 py-2 shadow-sm float-end"
         routerLink="/account/home">Abbrechen</a>
      <button [ngClass]="{'disabled' : shopForm.invalid}"
              class="btn btn-success btn-block text-uppercase mb-2 py-2 me-2 shadow-sm float-end"
              type="submit">Erstellen
      </button>
    </div>
  </form>
</ng-template>


