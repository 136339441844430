<form (ngSubmit)="addAddonToList()" [formGroup]="addonForm" class="row g-3">
  <div class="col-md-6">
    <div class="form-group">
      <input class="form-control" formControlName="name" placeholder="Extra Knoblauch" type="text">
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <input class="form-control" formControlName="price" placeholder="1.50€" type="number">
    </div>
  </div>
  <div class="col-3">
    <button [ngClass]="{'disabled' : addonForm.invalid}" class="btn btn-success btn-block text-uppercase mb-2 shadow-sm"
            type="submit"><i class="fas fa-plus-circle"></i></button>
  </div>
</form>
<p *ngIf="!selectedList.addons.length" class="text-muted mb-2">Keine Addons zugewiesen.</p>
<p *ngIf="selectedList.addons.length" class="text-muted mb-2">Zugewiesene Addons</p>
<div *ngFor="let addon of selectedList.addons;" class="btn-group me-2 mb-2" role="group">
  <button class="btn btn-sm btn-light" type="button">{{addon.name}}</button>
  <button class="btn btn-sm btn-light"
          type="button">{{addon.price | number:'1.2-2'}}{{dashboard.currencySymbol}}</button>
  <button (click)="removeAddonFromList(addon)" class="btn btn-sm btn-danger" type="button"><i class="fas fa-trash"></i>
  </button>
</div>



