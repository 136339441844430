export class ImageFile {
  shop: string;
  uuid: string;
  name: string;
  url: string;

  constructor(uuid: string, shop: string, name: string, url: string) {
    this.uuid = uuid;
    this.shop = shop;
    this.name = name;
    this.url = url;
  }
}
