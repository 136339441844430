import {Component} from '@angular/core';
import {DashboardService} from '../../services/api/dashboard.service';
import {AuthService} from '../../services/api/auth.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  constructor(public auth: AuthService, private titleService: Title, public dashboard: DashboardService) {
  }

  ngOnInit() {
    this.titleService.setTitle('Ordermotion | Dashboard');
  }
}
