<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <a [routerLink]="['/']" class="navbar-brand"><img
      alt="" class="d-inline-block align-top" height="35" src="/assets/images/motion-circle-logo-dark.png"></a>
    <button aria-controls="navbarContent" aria-expanded="false" class="navbar-toggler" data-bs-target="#navbarContent"
            data-bs-toggle="collapse" type="button">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
      <form class="d-flex">
        <a class="btn btn-success me-2" routerLink="/auth/login"><i class="fas fa-user"></i> Account</a>
      </form>
    </div>
  </div>
</nav>

<div class="jumbotron bg-cover text-white py-5" style="background: url(/assets/images/landing-header.jpg)">
  <div class="container p-5 header">
    <h1 class="fw-normal mb-3">Das Shopsystem für Restaurants und Lieferdienste.</h1>
    <p class="mb-4 fw-light m-auto">Erstellen Sie unkompliziert in wenigen Schritten Ihren eigenen Onlineshop und
      profitieren Sie durch ein modernes Design, zufriedene Kunden und weniger Kosten.</p>
    <a [routerLink]="['/auth/register']" class="btn btn-success px-5 py-2" role="button">Jetzt Anfragen <i
      class="fas fa-check-circle"></i></a>
  </div>
</div>

<div class="container main-section">
  <section class="section-services">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-md-10 col-lg-8">
          <div class="header-section">
            <h2 class="title text-dark">Unsere Features</h2>
            <p class="description">Bei der Entwicklung unserer Plattform konnten wir auf mehrere Jahre Erfahrung in der
              Zusammenarbeit mit Gastronomen zurückgreifen - das merkt man.</p>
            <a [routerLink]="['/shop/demoshop']" class="btn btn-success px-5 py-2 mt-5" role="button"><i
              class="fas fa-eye"></i> Zu unserem Testshop</a>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- Start Single Service -->
        <div class="col-md-6 col-lg-4">
          <div class="single-service">
            <div class="part-1">
              <i class="fas fa-utensils"></i>
              <h3 class="title">Nach Ihren Vorstellungen individuell konfigurierbare Produkte</h3>
            </div>
            <div class="part-2">
              <p class="description">Bieten Sie Ihren Kunden die Möglichkeit, aus zusätzlichen Zutaten, Saucen, Größen
                und anderen Optionen ihres Produktes zu wählen. Der Preis des Produktes wird an die Auswahl des Kunden
                automatisch angepasst.</p>
            </div>
          </div>
        </div>
        <!-- / End Single Service -->
        <!-- Start Single Service -->
        <div class="col-md-6 col-lg-4">
          <div class="single-service">
            <div class="part-1">
              <i class="fas fa-mobile-alt"></i>
              <h3 class="title">Automatisches Drucken Ihrer Bestellungen über ein Empfangsgerät</h3>
            </div>
            <div class="part-2">
              <p class="description">Alle Bestellungen werden automatisch verarbeitet und an das Empfangsgerät in Ihrem
                Restaurant gesendet. Ihre Kunden erhalten gleichzeitig eine Bestätigung per E-Mail inklusive
                Bestellübersicht.</p>
            </div>
          </div>
        </div>
        <!-- / End Single Service -->
        <!-- Start Single Service -->
        <div class="col-md-6 col-lg-4">
          <div class="single-service">
            <div class="part-1">
              <i class="fas fa-wallet"></i>
              <h3 class="title">Individuelle Zahlungsmethoden und Zahlungsgebühren (inkl. PayPal)</h3>
            </div>
            <div class="part-2">
              <p class="description">Sie entscheiden, welche Zahlungsmethoden Sie Ihren Kunden anbieten möchten und
                haben die Möglichkeit, Online-Zahlungen über PayPal direkt auf ihr Geschäftskonto zu erhalten.</p>
            </div>
          </div>
        </div>
        <!-- / End Single Service -->
        <!-- Start Single Service -->
        <div class="col-md-6 col-lg-4">
          <div class="single-service">
            <div class="part-1">
              <i class="fas fa-shipping-fast"></i>
              <h3 class="title">Individuelle Liefermethoden, Liefergebiete und Lieferzeiten</h3>
            </div>
            <div class="part-2">
              <p class="description">Entscheiden Sie selbst, an welche Postleitzahlen sie zu welchen Bedingungen liefern
                und bieten Sie ihren Kunden die Möglichkeit, Speisen abzuholen. Ihre Kunden können einen Zeitpunkt
                auswählen oder vorbestellen.</p>
            </div>
          </div>
        </div>
        <!-- / End Single Service -->
        <!-- Start Single Service -->
        <div class="col-md-6 col-lg-4">
          <div class="single-service">
            <div class="part-1">
              <i class="fas fa-balance-scale-right"></i>
              <h3 class="title">Buchhaltungsübersicht über Ihren Onlineshop</h3>
            </div>
            <div class="part-2">
              <p class="description">Sie können jederzeit für einen gewünschten Zeitraum einen Kassenschnitt erstellen,
                um zu erfahren, wie viel Umsatz über welche Zahlungsmethode abgewickelt wurde und wie viel Umsatzsteuer
                am Ende des Monats zu zahlen ist.</p>
            </div>
          </div>
        </div>
        <!-- / End Single Service -->
        <!-- Start Single Service -->
        <div class="col-md-6 col-lg-4">
          <div class="single-service">
            <div class="part-1">
              <i class="fas fa-user-lock"></i>
              <h3 class="title">Höchster Datenschutz durch modernste Techniken</h3>
            </div>
            <div class="part-2">
              <p class="description">Von Ihnen und Ihren Kunden erheben wir nur zwingend notwendige Daten, setzen auf
                ISO 27001 zertifizierte Rechenzentren, neueste Software und benutzen keine Analyse oder
                Marketingcookies. Unser Ziel ist ein perfektes Produkt für unsere Kunden.</p>
            </div>
          </div>
        </div>
        <!-- / End Single Service -->
      </div>
    </div>
  </section>
  <section class="section-services mt-5">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-md-10 col-lg-8">
          <div class="header-section">
            <h2 class="title text-dark">Überzeugen Sie sich selbst.</h2>
            <p class="description">Statt undurchsichtigen Preismodellen bieten wir all unseren Kunden den gesamten
              Funktionsumfang zum gleichen Preis an.</p>
            <div class="mt-5 text-dark">
              <h1 class="h6 text-uppercase font-weight-bold mb-4">Ordermotion</h1>
              <h2 class="h1 font-weight-bold">100€<span class="text-small font-weight-normal ml-2">/ Monat</span></h2>

              <div class="custom-separator my-4 mx-auto bg-success"></div>

              <ul class="list-unstyled my-5 text-small text-left font-weight-normal">
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-success"></i> Moderner und zukunftssicherer Onlineshop
                </li>
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-success"></i> Unbegrenzte Anzahl an Bestellungen
                </li>
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-success"></i> Online Bezahlung von Kunden über PayPal
                </li>
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-success"></i> Bestellung ohne Neuladen von Seiten
                </li>
                <li class="mb-5">
                  <i class="fa fa-check mr-2 text-success"></i> Persönlicher und fachlicher Ansprechpartner
                </li>
                <li class="mb-3">
                  <i class="fas fa-heart text-danger"></i> 100% Made in Germany
                </li>
                <li class="mb-3">
                  <i class="fas fa-leaf text-success"></i> Klimaneutrale Server
                </li>
              </ul>
              <a [routerLink]="['/auth/register']" class="btn btn-success px-5 py-2" role="button">Jetzt Anfragen <i
                class="fas fa-check-circle"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!--
<div class="jumbotron bg-cover team-image position-relative"
     style="background: url(/assets/images/motion-circle-team.jpeg)">
  <div class="container position-absolute bottom-0 pb-5 w-100 text-center text-white">
    <h2 class="fw-normal mb-3">Leitung der Softwareentwicklung.</h2>
  </div>
</div>
-->

<div class="mt-5 py-4 text-center">
  <small><a [routerLink]="['/impressum']" class="link text-secondary">Impressum</a> | <a [routerLink]="['/datenschutz']"
                                                                                         class="link text-secondary">Datenschutz</a></small>
  <img class="mx-auto d-block mt-3" height="30" src="/assets/images/motion-circle-logo-dark.png">
</div>
