import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {LoginComponent} from './components/auth/login/login.component';
import {ShopComponent} from './components/shop/shop.component';
import {RegisterComponent} from './components/auth/register/register.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthService} from './services/api/auth.service';
import {AccountGuard} from './guards/account.guard';
import {SettingsComponent} from './components/dashboard/settings/settings.component';
import {AuthComponent} from './components/auth/auth.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CreateRecoveryComponent} from './components/auth/create-recovery/create-recovery.component';
import {CompleteRecoveryComponent} from './components/auth/complete-recovery/complete-recovery.component';
import {InterceptorService} from './services/api/interceptor.service';
import {ProductGridComponent} from './components/shop/product-grid/product-grid.component';
import {FooterComponent} from './components/shop/footer/footer.component';
import {CreateShopComponent} from './components/account/create-shop/create-shop.component';
import {MediaComponent} from './components/dashboard/media/media.component';
import {UploadComponent} from './components/dashboard/media/upload/upload.component';
import {FileGridComponent} from './components/dashboard/media/file-grid/file-grid.component';
import {CategoriesComponent} from './components/dashboard/categories/categories.component';
import {CreateCategoryComponent} from './components/dashboard/categories/create-category/create-category.component';
import {ShopSelectorComponent} from './components/account/shop-selector/shop-selector.component';
import {LoadingComponent} from './components/loading/loading.component';
import {FileOptionsComponent} from './components/dashboard/media/file-options/file-options.component';
import {ListCategoriesComponent} from './components/dashboard/categories/list-categories/list-categories.component';
import {AccountSettingsComponent} from './components/account/settings/account-settings.component';
import {TaxClassesComponent} from './components/dashboard/tax-classes/tax-classes.component';
import {ListTaxClassesComponent} from './components/dashboard/tax-classes/list-tax-classes/list-tax-classes.component';
import {CreateTaxClassComponent} from './components/dashboard/tax-classes/create-tax-class/create-tax-class.component';
import {AddonsComponent} from './components/dashboard/addons/addons.component';
import {CodeListsComponent} from './components/dashboard/code-lists/code-lists.component';
import {ShippingMethodsComponent} from './components/dashboard/shipping-methods/shipping-methods.component';
import {ListCodeListsComponent} from './components/dashboard/code-lists/list-code-lists/list-code-lists.component';
import {CreateCodeListComponent} from './components/dashboard/code-lists/create-code-list/create-code-list.component';
import {CodeManagerComponent} from './components/dashboard/code-lists/code-manager/code-manager.component';
import {ListShippingMethodsComponent} from './components/dashboard/shipping-methods/list-shipping-methods/list-shipping-methods.component';
import {CreateShippingMethodComponent} from './components/dashboard/shipping-methods/create-shipping-method/create-shipping-method.component';
import {ListAddonListsComponent} from './components/dashboard/addons/list-addon-lists/list-addon-lists.component';
import {CreateAddonListComponent} from './components/dashboard/addons/create-addon-list/create-addon-list.component';
import {AddonManagerComponent} from './components/dashboard/addons/addon-manager/addon-manager.component';
import {ProductsComponent} from './components/dashboard/products/products.component';
import {ListProductsComponent} from './components/dashboard/products/list-products/list-products.component';
import {CreateProductComponent} from './components/dashboard/products/create-product/create-product.component';
import {CartComponent} from './components/shop/cart/cart.component';
import {ProductDetailComponent} from './components/shop/product-detail/product-detail.component';
import {PaymentMethodsComponent} from './components/dashboard/payment-methods/payment-methods.component';
import {ListPaymentMethodsComponent} from './components/dashboard/payment-methods/list-payment-methods/list-payment-methods.component';
import {CreatePaymentMethodComponent} from './components/dashboard/payment-methods/create-payment-method/create-payment-method.component';
import {FeesComponent} from './components/dashboard/fees/fees.component';
import {ListFeesComponent} from './components/dashboard/fees/list-fees/list-fees.component';
import {CreateFeeComponent} from './components/dashboard/fees/create-fee/create-fee.component';
import {CapturePaymentComponent} from './components/shop/capture-payment/capture-payment.component';
import {OrdersComponent} from './components/dashboard/orders/orders.component';
import {ListOrdersComponent} from './components/dashboard/orders/list-orders/list-orders.component';
import {EditOrderComponent} from './components/dashboard/orders/edit-order/edit-order.component';
import {LegalImprintComponent} from './components/landing-page/legal/legal-imprint/legal-imprint.component';
import {LegalPolicyComponent} from './components/landing-page/legal/legal-policy/legal-policy.component';
import {LegalRevocationComponent} from './components/shop/legal/legal-revocation/legal-revocation.component';
import {LegalAgbComponent} from './components/shop/legal/legal-agb/legal-agb.component';
import {EditOpeningHourComponent} from './components/dashboard/settings/edit-opening-hour/edit-opening-hour.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {LandingPageComponent} from './components/landing-page/landing-page.component';
import {JwPaginationComponent} from './components/dashboard/pagination/pagination.component';
import {AccountComponent} from './components/account/account.component';
import {AdminDashboardComponent} from './components/admin-dashboard/admin-dashboard.component';
import {EditShopComponent} from './components/dashboard/settings/edit-shop/edit-shop.component';
import {UserShopOverview} from './components/admin-dashboard/user-shop-overview/user-shop-overview.component';
import {UserDetailsComponent} from './components/admin-dashboard/user-details/user-details.component';
import {ToastrModule} from 'ngx-toastr';
import {NothingFoundComponent} from './components/dashboard/nothing-found/nothing-found.component';
import {ConfigurationErrorComponent} from './components/shop/configuration-error/configuration-error.component';
import { AdditivesComponent } from './components/dashboard/additives/additives.component';
import { ListAdditivesComponent } from './components/dashboard/additives/list-additives/list-additives.component';
import { CreateAdditiveComponent } from './components/dashboard/additives/create-additive/create-additive.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    ShopComponent,
    RegisterComponent,
    SettingsComponent,
    AuthComponent,
    CreateRecoveryComponent,
    CompleteRecoveryComponent,
    ProductGridComponent,
    FooterComponent,
    CreateShopComponent,
    MediaComponent,
    UploadComponent,
    FileGridComponent,
    CategoriesComponent,
    CreateCategoryComponent,
    ShopSelectorComponent,
    LoadingComponent,
    FileOptionsComponent,
    ListCategoriesComponent,
    AccountSettingsComponent,
    TaxClassesComponent,
    ListTaxClassesComponent,
    CreateTaxClassComponent,
    AddonsComponent,
    CodeListsComponent,
    ShippingMethodsComponent,
    ListCodeListsComponent,
    CreateCodeListComponent,
    CodeManagerComponent,
    ListShippingMethodsComponent,
    CreateShippingMethodComponent,
    ListAddonListsComponent,
    CreateAddonListComponent,
    AddonManagerComponent,
    ProductsComponent,
    ListProductsComponent,
    CreateProductComponent,
    CartComponent,
    ProductDetailComponent,
    PaymentMethodsComponent,
    ListPaymentMethodsComponent,
    CreatePaymentMethodComponent,
    FeesComponent,
    ListFeesComponent,
    CreateFeeComponent,
    CapturePaymentComponent,
    OrdersComponent,
    ListOrdersComponent,
    EditOrderComponent,
    LegalImprintComponent,
    LegalPolicyComponent,
    LegalRevocationComponent,
    LegalAgbComponent,
    EditOpeningHourComponent,
    LandingPageComponent,
    JwPaginationComponent,
    AccountComponent,
    AdminDashboardComponent,
    EditShopComponent,
    UserShopOverview,
    UserDetailsComponent,
    NothingFoundComponent,
    ConfigurationErrorComponent,
    AdditivesComponent,
    ListAdditivesComponent,
    CreateAdditiveComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true
    })
  ],
  providers: [AuthService, AccountGuard, {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
