<app-loading *ngIf="loading; else createForm"></app-loading>
<ng-template #createForm>
  <h3 class="fw-light">{{selectedList ? 'Liefergebiet Bearbeiten' : 'Neues Liefergebiet'}}</h3>
  <p
    class="text-muted mb-4">{{selectedList ? ('Bearbeiten Sie das Liefergebiet ' + selectedList?.name + '.') : 'Erstellen Sie ein neues Liefergebiet.'}}</p>
  <form (ngSubmit)="createCodeList()" [formGroup]="listForm" class="row g-3">
    <div class="col-md-12">
      <div class="form-floating">
        <input class="form-control" formControlName="name" type="text">
        <label>Name</label>
      </div>
    </div>
    <div class="col-12">
      <div class="form-floating">
        <textarea class="form-control" formControlName="description" style="height: 75px;"></textarea>
        <label>Beschreibung</label>
      </div>
    </div>
    <div class="col-12">
      <button class="btn btn-danger btn-block text-uppercase mb-2 py-2 shadow-sm float-end" data-bs-dismiss="modal"
              type="button">Abbrechen
      </button>
      <button [ngClass]="{'disabled' : listForm.invalid}"
              class="btn btn-success btn-block text-uppercase mb-2 py-2 shadow-sm float-end me-2"
              type="submit">Speichern
      </button>
    </div>
  </form>
</ng-template>

