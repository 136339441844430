import {Component, Input, OnChanges} from '@angular/core';
import {ShopHour} from '../../../../models/shophour.model';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {updateObject} from '../../../../helper/object.helper';

@Component({
  selector: 'app-edit-opening-hour',
  templateUrl: './edit-opening-hour.component.html',
  styleUrls: ['./edit-opening-hour.component.css']
})
export class EditOpeningHourComponent implements OnChanges {
  @Input() selectedShopHour: ShopHour;

  hourForm: UntypedFormGroup;
  loading = false;

  constructor(public dashboard: DashboardService, public formBuilder: UntypedFormBuilder, private alert: ToastrService) {
  }

  get form(): any {
    return this.hourForm.controls;
  }

  ngOnChanges(): void {
    this.hourForm = this.formBuilder.group({
      open_first: [this.selectedShopHour.slots[0].open, Validators.required],
      close_first: [this.selectedShopHour.slots[0].close, Validators.required],
      open_second: [this.selectedShopHour.slots[1].open, Validators.required],
      close_second: [this.selectedShopHour.slots[1].close, Validators.required],
      closed: [this.selectedShopHour.closed],
      continuous: [this.selectedShopHour.continuous]
    });
  }

  updateHour() {
    this.loading = true;

    const slots = [
      {
        open: this.form.open_first.value,
        close: this.form.close_first.value
      },
      {
        open: this.form.open_second.value,
        close: this.form.close_second.value
      }
    ]

    const updateModel = new ShopHour(this.dashboard.selectedShop.uuid, this.selectedShopHour.uuid, this.selectedShopHour.day, this.form.continuous.value, this.form.closed.value, slots);

    this.dashboard.updateOpeningHour(updateModel)
      .pipe(first())
      .subscribe(
        result => {
          this.loading = false;
          updateObject(this.selectedShopHour, result);
          this.alert.success('Die Öffnungszeiten wurden erfolgreich aktualisiert.');
        },
        error => {
          this.loading = false;
          this.alert.error(error)
        }
      );
  }
}
