import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/auth/login/login.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {RegisterComponent} from './components/auth/register/register.component';
import {AccountGuard} from './guards/account.guard';
import {ShopComponent} from './components/shop/shop.component';
import {SettingsComponent} from './components/dashboard/settings/settings.component';
import {AuthComponent} from './components/auth/auth.component';
import {CreateRecoveryComponent} from './components/auth/create-recovery/create-recovery.component';
import {CompleteRecoveryComponent} from './components/auth/complete-recovery/complete-recovery.component';
import {MediaComponent} from './components/dashboard/media/media.component';
import {CategoriesComponent} from './components/dashboard/categories/categories.component';
import {ShopSelectorComponent} from './components/account/shop-selector/shop-selector.component';
import {CreateShopComponent} from './components/account/create-shop/create-shop.component';
import {AccountSettingsComponent} from './components/account/settings/account-settings.component';
import {DashboardGuard} from './guards/dashboard.guard';
import {ShopGuard} from './guards/shop.guard';
import {TaxClassesComponent} from './components/dashboard/tax-classes/tax-classes.component';
import {AddonsComponent} from './components/dashboard/addons/addons.component';
import {CodeListsComponent} from './components/dashboard/code-lists/code-lists.component';
import {ShippingMethodsComponent} from './components/dashboard/shipping-methods/shipping-methods.component';
import {ProductsComponent} from './components/dashboard/products/products.component';
import {PaymentMethodsComponent} from './components/dashboard/payment-methods/payment-methods.component';
import {FeesComponent} from './components/dashboard/fees/fees.component';
import {CapturePaymentComponent} from './components/shop/capture-payment/capture-payment.component';
import {OrdersComponent} from './components/dashboard/orders/orders.component';
import {LegalImprintComponent} from './components/landing-page/legal/legal-imprint/legal-imprint.component';
import {LegalPolicyComponent} from './components/landing-page/legal/legal-policy/legal-policy.component';
import {LandingPageComponent} from './components/landing-page/landing-page.component';
import {AccountComponent} from './components/account/account.component';
import {AdminDashboardComponent} from './components/admin-dashboard/admin-dashboard.component';
import {AdditivesComponent} from "./components/dashboard/additives/additives.component";

const routes: Routes = [
  {
    /* Default Path */
    path: '',
    /* component: LandingPageComponent, */
    pathMatch: "full",
    redirectTo: "auth/"
  },
  {
    path: "auth",
    redirectTo: "auth/login"
  },
  {
    /* Authentication Path */
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'register',
        component: RegisterComponent
      },
      {
        path: 'recovery',
        component: CreateRecoveryComponent
      },
      {
        path: 'recovery/:token',
        component: CompleteRecoveryComponent
      }
    ]
  },
  {
    /* Login Path -> Redirection */
    path: 'login',
    redirectTo: 'auth/login'
  },
  {
    /* Register Path -> Redirection */
    path: 'register',
    redirectTo: 'auth/register'
  },
  {
    /* Recovery Path -> Redirection */
    path: 'recovery',
    redirectTo: 'auth/recovery'
  },
  {
    path: "account",
    redirectTo: "account/home"
  },
  {
    /* Account Path */
    path: 'account',
    component: AccountComponent,
    canActivate: [AccountGuard],
    children: [
      {
        /* Account Settings Path */
        path: 'home',
        component: ShopSelectorComponent,
      },
      {
        /* Account Create Shop Path */
        path: 'new-shop',
        component: CreateShopComponent,
      },
      {
        /* Account Settings Path */
        path: 'settings',
        component: AccountSettingsComponent,
      },
    ]
  },
  {
    /* Legal Data-Protection Path */
    path: 'datenschutz',
    component: LegalPolicyComponent
  },
  {
    /* Legal Imprint Path */
    path: 'impressum',
    component: LegalImprintComponent
  },
  {
    /* Admin Path */
    path: 'admin',
    component: AdminDashboardComponent,
    canActivate: [AccountGuard],
    data: {admin: true},
  },
  {
    /* Dashboard Path */
    path: 'dashboard/:shopname',
    component: DashboardComponent,
    canActivate: [AccountGuard, DashboardGuard],
    data: {admin: false},
    children: [
      {
        path: 'settings',
        component: SettingsComponent
      },
      {
        path: 'media',
        component: MediaComponent
      },
      {
        path: 'categories',
        component: CategoriesComponent
      },
      {
        path: 'addons',
        component: AddonsComponent
      },
      {
        path: 'additives',
        component: AdditivesComponent
      },
      {
        path: 'tax-classes',
        component: TaxClassesComponent
      },
      {
        path: 'code-lists',
        component: CodeListsComponent
      },
      {
        path: 'shipping-methods',
        component: ShippingMethodsComponent
      },
      {
        path: 'products',
        component: ProductsComponent
      },
      {
        path: 'payment-methods',
        component: PaymentMethodsComponent
      },
      {
        path: 'fees',
        component: FeesComponent
      },
      {
        path: 'orders',
        component: OrdersComponent
      }
    ]
  },
  {
    /* Shop Path */
    path: 'shop/:shopname', component: ShopComponent, canActivate: [ShopGuard]
  },
  {
    /* PayPal Capture Path */
    path: 'capture-payment', component: CapturePaymentComponent
  },
  {
    /* Default Path -> Redirection */
    path: '**',
    redirectTo: 'auth/login'
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
