<app-loading *ngIf="!dashboard.responses.orders; else orderTable"></app-loading>
<ng-template #orderTable>
  <ng-container *ngIf="dashboard.orders.length; else nothingFound">
    <table class="table mt-5">
      <thead>
      <tr>
        <th scope="col"><i class="fas fa-dolly"></i> Bestellung</th>
        <th scope="col"><i class="fas fa-user-tag"></i> Kunde</th>
        <th scope="col"><i class="fas fa-wallet"></i> Zahlung</th>
        <th scope="col"><i class="fas fa-clock"></i> Liefer-/Abholdatum</th>
        <th scope="col"><i class="fas fa-question-circle"></i> Status</th>
        <th scope="col"><i class="fas fa-calculator"></i> Betrag</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let order of pageOfItems;">
        <td>{{order.sequential}}</td>
        <td>{{order.address.name}}<br>{{order.address.street}} {{order.address.number}}</td>
        <td>{{order.payment.title}}</td>
        <td><b>{{order.fullfillment | date:'dd.MM.yy HH:mm'}}</b><br>{{order.shipping.title}}</td>
        <td *ngIf="order.status === 'canceled'"><span class="badge bg-danger p-2">Storniert</span></td>
        <td *ngIf="order.status === 'waiting-payment'"><span
          class="badge bg-warning text-dark p-2">Zahlung Ausstehend</span></td>
        <td *ngIf="order.status === 'processing'"><span class="badge bg-success p-2">In Bearbeitung</span></td>
        <td *ngIf="order.status === 'completed'"><span class="badge bg-success p-2">Abgeschlossen</span></td>
        <td><b>{{order.total | number:'1.2-2'}} {{dashboard.selectedShop.currencySymbol}}</b></td>
        <td>
          <button (click)="selectedOrder = order" class="btn btn-small btn-dark" data-bs-target="#editModal"
                  data-bs-toggle="modal"><i class="fas fa-sliders-h"></i></button>
        </td>
      </tr>
      </tbody>
    </table>
    <jw-pagination (changePage)="onChangePage($event)" [items]="dashboard.orders"></jw-pagination>
  </ng-container>
  <ng-template #nothingFound>
    <app-nothing-found></app-nothing-found>
  </ng-template>
</ng-template>

<div aria-hidden="true" class="modal fade" id="editModal" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="container p-5 pt-0">
        <app-edit-order [selectedOrder]="selectedOrder"></app-edit-order>
      </div>
    </div>
  </div>
</div>
