import {Component, OnInit} from '@angular/core';
import {DashboardService} from '../../../services/api/dashboard.service';
import {ApiConfig} from '../../../services/api/api.config';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {ShopHour} from '../../../models/shophour.model';
import {ShopUpdate} from '../../../models/shop.model';
import {AuthService} from '../../../services/api/auth.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  selectedShopHour: ShopHour;

  fullfillmentForm: UntypedFormGroup;
  cutForm: UntypedFormGroup;

  loading = {
    fullfillment: false,
    status: false,
    cut: false
  }

  constructor(private router: Router, private formBuilder: UntypedFormBuilder, public dashboard: DashboardService, private alert: ToastrService, public config: ApiConfig, public auth: AuthService) {
    this.dashboard.loadAllFiles();
  }

  get fullfillmentFormData(): any {
    return this.fullfillmentForm.controls;
  }

  get cutFormData(): any {
    return this.cutForm.controls;
  }

  ngOnInit(): void {
    this.fullfillmentForm = this.formBuilder.group({
      fullfillment: [this.dashboard.selectedShop.fullfillment, Validators.required],
    });

    this.cutForm = this.formBuilder.group({
      start: ['', Validators.required],
      end: ['', Validators.required]
    });
  }

  createCut() {
    this.loading.cut = true;

    this.dashboard.createCut({
      shop: this.dashboard.selectedShop.uuid,
      start: this.cutFormData.start.value,
      end: this.cutFormData.end.value
    })
      .pipe(first())
      .subscribe(
        result => {
          this.loading.cut = false;
          this.alert.success(result.message);
        },
        error => {
          this.loading.cut = false;
          this.alert.warning(error);
        }
      );
  }

  updateFullfillmentTime() {
    this.loading.fullfillment = true;

    const shopModel = new ShopUpdate(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      this.fullfillmentFormData.fullfillment.value
    );

    this.dashboard.updateShop(shopModel)
      .pipe(first())
      .subscribe(
        result => {
          this.loading.fullfillment = false;
          this.dashboard.selectedShop = result;
          this.alert.success('Die Lieferzeit des Shops wurde erfolgreich aktualisiert.')
        },
        error => {
          this.loading.fullfillment = false;
          this.alert.warning(error);
        }
      );
  }

  updateShopStatus(update) {
    this.loading.status = true;

    const shopModel = new ShopUpdate(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      update.active ?? undefined,
      update.open ?? undefined,
      undefined
    );

    this.dashboard.updateShop(shopModel)
      .pipe(first())
      .subscribe(
        result => {
          this.loading.status = false;
          this.dashboard.selectedShop = result;
          this.alert.success('Der Status des Shops wurde erfolgreich aktualisiert.');
        },
        error => {
          this.loading.status = false;
          this.alert.warning(error);
        }
      );
  }
}
