import {Component, OnInit} from '@angular/core';
import {ShopService} from '../../services/api/shop.service';
import {ApiConfig} from '../../services/api/api.config';
import {Meta, Title} from '@angular/platform-browser';


@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css'],
})

export class ShopComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta, public shop: ShopService, public config: ApiConfig) {
  }

  ngOnInit() {
    this.titleService.setTitle('Jetzt bestellen bei ' + this.shop.current.name);
    this.metaService.updateTag({name: 'description', content: this.shop.current.description.substring(0, 50)});
  }
}
