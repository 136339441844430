import {Component, Input, OnChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {Router} from '@angular/router';
import {Category, CategoryCreate} from '../../../../models/category.model';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {updateObject} from '../../../../helper/object.helper';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.css']
})
export class CreateCategoryComponent implements OnChanges {
  @Input() selectedCategory: Category;

  categoryForm: UntypedFormGroup;
  loading = false;

  constructor(private router: Router, private formBuilder: UntypedFormBuilder, public dashboard: DashboardService, private alert: ToastrService) {
  }

  get form(): any {
    return this.categoryForm.controls;
  }

  ngOnChanges(): void {
    this.categoryForm = this.formBuilder.group({
      name: [this.selectedCategory?.name ?? '', Validators.required],
      description: [this.selectedCategory?.description ?? '', Validators.required],
      image: [this.selectedCategory?.image?.uuid ?? '', Validators.required]
    });
  }

  createCategory(): void {
    if (this.categoryForm.invalid) return;

    this.loading = true;
    const update = new CategoryCreate(this.dashboard.selectedShop.uuid, this.form.name.value, this.form.description.value, this.form.image.value);

    if (!this.selectedCategory) {
      this.dashboard.createCategory(update)
        .pipe(first())
        .subscribe(
          result => {
            this.loading = false;
            this.categoryForm.reset();
            this.dashboard.categories.push(result);
            this.alert.success('Die Kategorie wurde erfolgreich erstellt.');
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    } else {
      this.dashboard.updateCategory(this.selectedCategory.uuid, update)
        .pipe(first())
        .subscribe(
          result => {
            this.loading = false;
            updateObject(this.selectedCategory, result);
            this.alert.success('Die Kategorie wurde erfolgreich aktualisiert.');
          },
          error => {
            this.loading = false;
            this.alert.error(error);
          }
        );
    }
  }
}
