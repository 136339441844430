<app-loading *ngIf="loading; else createForm"></app-loading>
<ng-template #createForm>
  <h3 class="fw-light">{{this.selectedAdditive ? 'Zusatzstoff Bearbeiten' : 'Neuer Zusatzstoff'}}</h3>
  <p
    class="text-muted mb-4">{{this.selectedAdditive ? ('Bearbeiten Sie den Zusatzstoff ' + this.selectedAdditive.name + '.') : 'Erstellen Sie einen neuen Zusatzstoff.'}}
    .</p>
  <form (ngSubmit)="createAdditive()" [formGroup]="additiveForm" class="row g-3">
    <div class="col-md-12">
      <div class="form-floating">
        <input class="form-control" formControlName="name" type="text">
        <label>Name</label>
      </div>
    </div>
    <div class="col-md-12">
      <input autocomplete="off" class="btn-check" formControlName="allergen" id="allergen-create" type="checkbox">
      <label class="btn btn-outline-warning w-100" for="allergen-create"><i class="fas fa-exclamation-triangle"></i>
        Allergen</label>
    </div>
    <div class="col-12">
      <button class="btn btn-danger btn-block text-uppercase mb-2 py-2 shadow-sm float-end" data-bs-dismiss="modal"
              type="button">Abbrechen
      </button>
      <button [ngClass]="{'disabled' : additiveForm.invalid}"
              class="btn btn-success btn-block text-uppercase mb-2 py-2 shadow-sm float-end me-2"
              type="submit">Speichern
      </button>
    </div>
  </form>
</ng-template>

