<app-loading *ngIf="loading; else createForm"></app-loading>
<ng-template #createForm>
  <h3 class="fw-light">{{this.selectedList ? 'Addonliste Bearbeiten' : 'Neue Addonliste'}}</h3>
  <p
    class="text-muted mb-4">{{this.selectedList ? ('Bearbeiten Sie die Addonliste ' + this.selectedList.name + '.') : 'Erstellen Sie eine neue Addonliste.'}}
    .</p>
  <form (ngSubmit)="createAddonList()" [formGroup]="listForm" class="row g-3">
    <div class="col-md-12">
      <div class="form-floating">
        <input class="form-control" formControlName="name" type="text">
        <label>Name</label>
      </div>
    </div>
    <div class="col-md-12">
      <input autocomplete="off" class="btn-check" formControlName="multiselect" id="multiselect-create" type="checkbox">
      <label class="btn btn-outline-success w-100" for="multiselect-create"><i class="fas fa-box"></i>
        Multiselect</label>
    </div>
    <div class="col-12">
      <button class="btn btn-danger btn-block text-uppercase mb-2 py-2 shadow-sm float-end" data-bs-dismiss="modal"
              type="button">Abbrechen
      </button>
      <button [ngClass]="{'disabled' : listForm.invalid}"
              class="btn btn-success btn-block text-uppercase mb-2 py-2 shadow-sm float-end me-2"
              type="submit">Speichern
      </button>
    </div>
  </form>
</ng-template>

