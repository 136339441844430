import {Component} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent {
  constructor(private titleService: Title, private metaService: Meta) {
  }

  ngOnInit() {
    this.titleService.setTitle('Ordermotion - Ihr eigener Onlineshop');
    this.metaService.updateTag({
      name: 'description',
      content: 'Erstellen Sie in wenigen Schritten Ihren eigenen Onlineshop und profitieren Sie durch zufriedene Kunden.'
    });
  }
}
