import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/api/auth.service';
import {Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-create-recovery',
  templateUrl: './create-recovery.component.html',
  styleUrls: ['./create-recovery.component.css']
})
export class CreateRecoveryComponent implements OnInit {

  recoveryForm: UntypedFormGroup;
  loading = false;

  constructor(private auth: AuthService, private router: Router, private formBuilder: UntypedFormBuilder, private alert: ToastrService, public titleService: Title, public metaService: Meta) {
  }

  get form(): any {
    return this.recoveryForm.controls;
  }

  ngOnInit(): void {
    this.recoveryForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });


    this.titleService.setTitle('Passwort Vergessen?');
    this.metaService.updateTag({
      name: 'description',
      content: 'Sie haben Ihr Passwort vergessen? Setzen Sie es einfach per E-Mail zurück.'
    });
  }

  createRecovery(): void {
    if (this.recoveryForm.invalid) return;

    this.loading = true;

    this.auth.createRecovery(this.form.email.value)
      .pipe(first())
      .subscribe(
        result => {
          this.alert.success(result.message);
          this.loading = false;
        },
        error => {
          this.alert.error(error);
          this.loading = false;
        }
      );
  }
}
