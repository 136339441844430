<div class="container mt-5 mb-5 p-5">
  <h1>Impressum</h1>
  <h4 id="m46">Diensteanbieter</h4>
  <p>
    Reichsoellner GmbH<br>
    Gaußstrasse 123<br>
    70193 Stuttgart<br>
    Deutschland
  </p>
  <h4 id="m56">Kontaktmöglichkeiten</h4>
  <p>
    E-Mail-Adresse: hello&#64;msx.motion-circle.com<br>
    Telefon: +49 (0)176 21 51 922-4
  </p>
  <h4 id="m153">Vertretungsberechtigte</h4>
  <p>Geschäftsführer: Remo Romero Reichsoellner</p>
  <h4 id="m58">Angaben zum Unternehmen</h4>
  <p>Umsatzsteuer Identifikationsnummer (USt-ID): DE334756387</p>
  <h4 id="m151">Register und Registernummer</h4>
  <p>
    Handelsregister<br>
    Geführt bei: Amtsgericht Stuttgart<br>
    Nummer: HRB 775 144<br>
  </p>
  <h4 id="m162">Kammer</h4>
  <p>Kammer (oder vergleichbare berufsrechtlicher Organisation):</p>
  <p>IHK Region Stuttgart<br>Jägerstraße 30<br>70174 Stuttgart</p>
  <p>Website und Kontakt der Kammer: <a href="https://www.stuttgart.ihk24.de/" target="_blank">https://www.stuttgart.ihk24.de/</a>
  </p>
  <h4 id="m65">Haftungs- und Schutzrechtshinweise</h4>
  <p>
    Die Reichsoellner GmbH, Gaußstrasse 123, 70193, Stuttgart, ist Anbieter der Onlineplattform „Ordermotion“ über die externe Verkäufer ihre
    Waren anbieten und verkaufen können.
    Dieses Impressum und die dazugehörige Datenschutzerklärung gilt nur für die Onlineplattform und den Anbieter selbst,
    der sämtliche Bestellungen für Verkäufer und deren Onlineshops, bspw. erreichbar unter
    order.motion-circle.com/shop/BeispielShop, verarbeitet. Der Anbieter ist also für die rechtmäßige Speicherung und
    Verarbeitung sowie Weiterleitung von Kundendaten an den jeweiligen Verkäufer zuständig - Kaufverträge und rechtliche
    Widerrufsansprüche sind jedoch an den jeweiligen Verkäufer zu wenden und kommen auch nur mit dem jeweiligen
    Verkäufer zustande, bei dem der Kunde Waren bestellt.
    Die Reichsoellner GmbH übernimmt lediglich eine Vermittlerrolle zwischen Käufer und
    Verkäufer und hat keinen Einfluss auf etwaige Online-Zahlungen und die Erfüllung rechtskräftiger Kaufverträge.
  </p>
  <p><strong>Haftungsausschluss</strong>: Die Inhalte dieses Onlineangebotes wurden sorgfältig und nach unserem
    aktuellen Kenntnisstand erstellt, dienen jedoch nur der Information und entfalten keine rechtlich bindende Wirkung,
    sofern es sich nicht um gesetzlich verpflichtende Informationen (z.B. das Impressum, die Datenschutzerklärung, AGB
    oder verpflichtende Belehrungen von Verbrauchern) handelt. Wir behalten uns vor, die Inhalte vollständig oder
    teilweise zu ändern oder zu löschen, soweit vertragliche Verpflichtungen unberührt bleiben. Alle Angebote sind
    freibleibend und unverbindlich. </p>
  <p><strong>Links auf fremde Webseiten</strong>: Inhalte fremder Webseiten, auf die wir direkt oder indirekt verweisen,
    liegen außerhalb unseres Verantwortungsbereiches und machen wir uns nicht zu Eigen. Für alle Inhalte und
    insbesondere für Schäden, die aus der Nutzung der in den verlinkten Webseiten aufrufbaren Informationen entstehen,
    haftet allein der Anbieter der verlinkten Webseiten.</p>
  <p><strong>Urheberrechte und Markenrechte</strong>: Alle auf dieser Website dargestellten Inhalte, wie Texte,
    Fotografien, Grafiken, Marken und Warenzeichen sind durch die jeweiligen Schutzrechte (Urheberrechte, Markenrechte)
    geschützt. Die Verwendung, Vervielfältigung usw. unterliegen unseren Rechten oder den Rechten der jeweiligen Urheber
    bzw. Rechteverwalter.</p>
  <p><strong>Hinweise auf Rechtsverstöße</strong>: Sollten Sie innerhalb unseres Internetauftritts Rechtsverstöße
    bemerken, bitten wir Sie uns auf diese hinzuweisen. Wir werden rechtswidrige Inhalte und Links nach Kenntnisnahme
    unverzüglich entfernen.</p>
</div>
