export class Address {
  name: string;
  surname: string;
  company: string;
  street: string;
  number: string;
  code: string;
  city: string;
  country: string;
  email: string;
  phone: string;
  createdAt: string;
  updatedAt: string;

  constructor(name: string, surname: string, company: string, street: string, number: string, code: string, city: string, country: string, email: string, phone: string) {
    this.name = name;
    this.surname = surname;
    this.company = company;
    this.street = street;
    this.number = number;
    this.code = code;
    this.city = city;
    this.country = country;
    this.email = email;
    this.phone = phone;
  }
}

