import {CodeList} from './codelist.model';

export class ShippingMethod {
  shop: string;
  uuid: string;
  name: string;
  description: string;
  price: number;
  codelist: CodeList;
  minordervalue: number;
  pickup: boolean;

  constructor(shop: string, uuid: string, name: string, description: string, price: number, minordervalue: number, codelist: CodeList, pickup: boolean) {
    this.shop = shop;
    this.uuid = uuid;
    this.name = name;
    this.description = description;
    this.price = price;
    this.minordervalue = minordervalue;
    this.codelist = codelist;
    this.pickup = pickup;
  }
}
