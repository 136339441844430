<ng-container *ngIf="shop.canPerformOrder; else shopConfigurationError">

  <div class="scrollmenu mt-4 mb-4 bg-light">
    <a (click)="selectedCategory = undefined"><i class="fas fa-list"></i></a>
    <ng-container *ngFor="let category of shop.categories">
      <a (click)="selectedCategory = category" [ngClass]="{'selected': selectedCategory?.uuid === category.uuid}">{{category.name}}</a>
    </ng-container>
  </div>

  <div class="cart-button py-3 text-light fw-light d-lg-none" data-bs-target="#cartModal" data-bs-toggle="modal">
    <i class="fas fa-shopping-cart"></i> {{shop.getCartLength()}}
    | {{shop.getCartSum() | number:'1.2-2'}} {{shop.currencySymbol}}
  </div>

  <ng-container *ngFor="let category of filteredCategories;">
    <div class="container">
      <div class="row mt-5 mb-5">
        <div class="col border-0 category-container shadow py-3"
             style="background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url({{config.fileUrl + category.image?.url }}); background-size: cover; background-position: center;">
          <h1 class="category-name text-light text-center fw-light">{{category.name}}</h1>
          <p class="text-light text-center fw-light m-auto category-description">{{category.description}}</p>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <ng-container *ngFor="let product of shop.products; let i = index">
        <ng-container *ngIf="product.available">
          <div *ngIf="category.uuid === product.category.uuid" class="col-lg-4 mb-4 mb-lg-5">
            <div class="card border-0 bg-dark text-light shadow product-card">
              <div class="card-body">
                <h5 class="card-title fw-light">{{product.name}}</h5>
                <p class="text-secondary small mb-0">{{product.description}}</p>
              </div>
              <span class="badge bg-success price-badge py-2 w-25 rounded-0">{{product.price | number:'1.2-2'}} {{shop.currencySymbol}}</span>
              <a (click)="selectedProduct = product" class="btn btn-light add-to-cart border-0" data-bs-target="#detailsModal"
                 data-bs-toggle="modal"><i class="fas fa-cart-plus"></i></a>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<!-- Product Details Modal -->
<div aria-hidden="true" class="modal fade" id="detailsModal" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="container p-5 pt-0">
        <app-product-detail [selectedProduct]="selectedProduct"></app-product-detail>
      </div>
    </div>
  </div>
</div>

<ng-template #shopConfigurationError>
  <app-configuration-error></app-configuration-error>
</ng-template>



