<app-loading *ngIf="loading; else orderTemplate"></app-loading>
<ng-template #orderTemplate>
  <div class="row order-item">
    <div class="col-12">
      <h3 class="fw-light">Bestellung {{selectedOrder?.sequential}}</h3>
      <p class="text-muted mb-4">{{selectedOrder?.createdAt | date:'dd.MM.yyyy HH:mm'}}</p>
    </div>
    <div class="col-12">
      <div class="btn-group mb-4 w-100" role="group">
        <button *ngIf="selectedOrder?.status === 'waiting-payment'" class="btn btn-warning" type="button"><i
          class="fas fa-clock"></i> Zahlung Ausstehend
        </button>
        <button *ngIf="selectedOrder?.status === 'processing'" class="btn btn-dark" type="button"><i
          class="fas fa-cogs"></i> In Bearbeitung
        </button>
        <button *ngIf="selectedOrder?.status === 'completed'" class="btn btn-success" type="button"><i
          class="fas fa-check"></i> Abgeschlossen
        </button>
        <button *ngIf="selectedOrder?.status === 'canceled'" class="btn btn-danger" type="button"><i
          class="fas fa-times"></i> Storniert
        </button>

        <ng-container *ngIf="selectedOrder?.status !== 'completed' && selectedOrder?.status !== 'canceled'">
          <button (click)="updateOrderStatus('canceled')" class="btn btn-danger" type="button"><i
            class="fas fa-times"></i>
            Stornieren {{selectedOrder?.payment.paypal.paypal && selectedOrder?.status !== 'waiting-payment' ? '(+ PayPal Rückerstattung)' : ''}}
          </button>
        </ng-container>

        <ng-container *ngIf="selectedOrder?.status !== 'completed' && selectedOrder?.status !== 'canceled'">
          <button (click)="updateOrderStatus('completed')" class="btn btn-success" type="button"><i
            class="fas fa-check"></i> Abschliessen
          </button>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="row order-item mt-4">
    <h4 class="fw-light">{{selectedOrder?.shipping.pickup ? 'Abholung' : 'Lieferung'}}</h4>
    <p class="text-success fs-5 fw-light"><b>{{selectedOrder?.fullfillment | date:'dd.MM.yy HH:mm'}}</b></p>
    <p *ngIf="selectedOrder?.note"><b>Nachricht:</b> {{selectedOrder?.note}}</p>
    <div class="col-lg-6 mt-2">
      <h5>Rechnungsadresse</h5>
      <p>
        {{selectedOrder?.address?.name}} {{selectedOrder?.address?.company}}<br>
        {{selectedOrder?.address?.street}} {{selectedOrder?.address?.number}}<br>
        {{selectedOrder?.address?.code}} {{selectedOrder?.address?.city}}<br>
        {{selectedOrder?.address?.country}}<br><br>
        {{selectedOrder?.address?.email}}<br>
        {{selectedOrder?.address?.phone}}
      </p>
    </div>
    <div class="col-lg-6 mt-2">
      <h5>Zahlungsmethode</h5>
      <p>
        {{selectedOrder?.payment.title}}<br><br>
        <ng-container *ngIf="selectedOrder?.payment.paypal.paypal">
          <span class="text-success"><i class="fab fa-cc-paypal"></i> PayPal-Zahlung</span><br>
          Transaktion: {{selectedOrder?.payment.paypal.transaction}}
        </ng-container>
      </p>
    </div>
  </div>

  <div class="row mt-4 order-item bg-dark text-light">
    <ng-container *ngFor="let product of selectedOrder?.products; let i = index">
      <div class="col-lg-4 mt-2 mb-2">
        <b>{{product.quantity}}x {{product.name}}</b>
      </div>
      <div class="col-lg-4 mt-2 mb-2">
        <ng-container *ngIf="product.addons?.length; else noAddons">
          <ng-container *ngFor="let addonlist of product.addons let i = index">
            <span class="text-warning">{{addonlist.title}}</span><br>
            <ng-container *ngFor="let addon of addonlist.addons">
              <span>{{addon.name}} | {{addon.price | number:'1.2-2'}}{{dashboard.currencySymbol}}</span><br>
            </ng-container>
            <hr *ngIf="i !== product.addons.length - 1">
          </ng-container>
        </ng-container>
        <ng-template #noAddons>-</ng-template>
      </div>
      <div class="col-lg-4 mt-2 mb-2 text-end">
            <span class="fs-6 fst-italic">Gesamt {{product.total | number:'1.2-2'}}{{dashboard.currencySymbol}}<br>
              {{product.taxclass}} ({{product.tax | number:'1.2-2'}}{{dashboard.currencySymbol}})</span>
      </div>
      <hr *ngIf="i !== (selectedOrder?.products?.length - 1)" class="bg-light">
    </ng-container>
  </div>

  <div class="row mt-4">
    <div class="col-sm-8"></div>
    <div class="col-lg-4 bg-dark text-light order-sum text-end">
      <ng-container *ngIf="selectedOrder?.payment?.fee?.total != 0">
        {{selectedOrder?.payment?.fee.title}}
        ({{selectedOrder?.payment.fee.total | number:'1.2-2'}}{{dashboard.selectedShop.currencySymbol}})<br>
      </ng-container>
      <ng-container *ngIf="selectedOrder?.shipping.total != 0">
        {{selectedOrder?.shipping.title}}
        ({{selectedOrder?.shipping.total | number:'1.2-2'}}{{dashboard.selectedShop.currencySymbol}})<br>
      </ng-container>
      <br>
      <b>Gesamt {{selectedOrder?.total | number:'1.2-2'}} {{dashboard.selectedShop.currencySymbol}}</b><br>
      <ng-container *ngFor="let taxclass of selectedOrder?.taxclass; let i = index">
        {{taxclass.name}} {{taxclass.tax | number:'1.2-2'}}{{dashboard.selectedShop.currencySymbol}}{{i !== selectedOrder.taxclass.length - 1 ? ', ' : ''}}
        <br>
      </ng-container>
    </div>
  </div>
</ng-template>


