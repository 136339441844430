import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Product} from '../../../models/product.model';
import {Addon} from '../../../models/addon.model';
import {ShopService} from '../../../services/api/shop.service';
import {ApiConfig} from '../../../services/api/api.config';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnChanges {
  @Input() selectedProduct: Product;

  selectedAddons: Addon[] = [];
  sortedAdditiveStrings: { allergens: string, additives: string } = { allergens: "", additives: "" };

  constructor(public shop: ShopService, public config: ApiConfig) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.selectedProduct) return;

    this.selectedAddons = [];
    this.selectedProduct.selectedAddons = [];

    this.selectedProduct.quantity = 1;
    this.sortedAdditiveStrings = this.shop.sortedProductAdditiveStrings(this.selectedProduct);
  }

  setProductQuantity(number) {
    this.selectedProduct.quantity += number;
    if (this.selectedProduct.quantity < 1) this.selectedProduct.quantity = 1;
  }

  addonSelectChange(addon, single) {

    let contains = false;
    this.selectedAddons.forEach((element, index) => {
      if (!single) {
        if (element.uuid === addon.uuid) {
          this.selectedAddons.splice(index, 1);
          contains = true;
        }
      } else {
        if (element.addonlist === addon.addonlist) {
          this.selectedAddons.splice(index, 1);
        }
      }
    });

    if (!contains) this.selectedAddons.push(addon);

    this.selectedProduct.selectedAddons = this.selectedAddons;
  }
}
