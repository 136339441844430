import {Shop} from './shop.model';

export class User {
  uuid: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  active: boolean;
  type: string;
  admin: boolean;
  shops: Shop[];
}

export class UserUpdate {
  constructor(
    public uuid: string,
    public name: string,
    public surname: string,
    public email: string,
    public phone: string,
    public active: boolean,
    public type: string
  ) {
  }
}

