import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LoginData, LoginResponse} from '../../models/login.model';
import {Router} from '@angular/router';
import {RegisterData} from '../../models/register.data.model';
import {RecoveryComplete} from '../../models/recovery.complete.model';
import {User, UserUpdate} from '../../models/user.model';
import {catchError, first} from 'rxjs/operators';
import {Response} from '../../models/response.model';
import {ApiConfig} from './api.config';
import {Observable} from 'rxjs';
import {Shop, ShopCreate} from '../../models/shop.model';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  users: User[] = [];
  user: User;

  responses = {
    user: false,
    users: false
  }

  constructor(private http: HttpClient, private router: Router, private config: ApiConfig, private alert: ToastrService) {
  }

  loadAllUsers() {
    if (this.responses.users && !!this.users.length) return;

    this.getUserList()
      .pipe(first())
      .subscribe(
        result => {
          this.responses.users = true;
          this.users = result;
        },
        error => {
          this.alert.error(error);
          this.responses.users = true;
          this.users = [];
        }
      );
  }

  getAuthenticatedUser(strict: boolean): Observable<User> {
    return this.http.get<User>(this.config.apiUrl + 'user' + (strict ? '' : '/check')).pipe(catchError(this.config.handleError));
  }

  updateUser(update: UserUpdate) {
    return this.http.post<User>(this.config.apiUrl + 'user/update', update).pipe(catchError(this.config.handleError));
  }

  updatePassword(password: string) {
    return this.http.post<Response>(this.config.apiUrl + 'user/update/password', {password: password}).pipe(catchError(this.config.handleError));
  }

  createRecovery(email: string) {
    return this.http.post<Response>(this.config.apiUrl + 'user/recovery/', {email: email}).pipe(catchError(this.config.handleError));
  }

  completeRecovery(user: RecoveryComplete) {
    return this.http.post<Response>(this.config.apiUrl + 'user/recovery/complete/', user).pipe(catchError(this.config.handleError));
  }

  registerUser(user: RegisterData) {
    return this.http.post<Response>(this.config.apiUrl + 'user/register/', user).pipe(catchError(this.config.handleError));
  }

  loginUser(data: LoginData) {
    return this.http.post<LoginResponse>(this.config.apiUrl + 'user/auth/', data).pipe(catchError(this.config.handleError));
  }

  getUserList() {
    return this.http.get<User[]>(this.config.apiUrl + 'user/list').pipe(catchError(this.config.handleError));
  }

  createShop(shop: ShopCreate) {
    return this.http.post<Shop>(this.config.apiUrl + 'shop/create/', shop).pipe(catchError(this.config.handleError));
  }

  getToken() {
    return localStorage.getItem('login-token');
  }

  setToken(token) {
    localStorage.setItem('login-token', token);
  }

  userHasToken(): boolean {
    return !!this.getToken();
  }

  redirectToAccount(): Promise<boolean> {
    return this.router.navigate(['/account/home']);
  }

  redirectToLogin(): Promise<boolean> {
    return this.router.navigate(['/auth/login']);
  }

  redirectToHome(): Promise<boolean> {
    return this.router.navigate(['/']);
  }

  logout() {
    localStorage.removeItem('login-token');
    this.redirectToLogin().then(() => {
    });
  }
}
