import {Component} from '@angular/core';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {TaxClass} from '../../../../models/taxclass.model';
import {ApiConfig} from '../../../../services/api/api.config';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-list-tax-classes',
  templateUrl: './list-tax-classes.component.html',
  styleUrls: ['./list-tax-classes.component.css']
})
export class ListTaxClassesComponent {

  selectedTaxClass: TaxClass;

  constructor(public dashboard: DashboardService, public config: ApiConfig, private alert: ToastrService) {
  }

  deleteTaxClass(taxclass) {
    this.dashboard.deleteTaxClass(taxclass)
      .pipe(first())
      .subscribe(
        result => {
          this.dashboard.taxClasses.splice(this.dashboard.taxClasses.indexOf(taxclass), 1);
        },
        error => {
          this.alert.warning(error);
        }
      );
  }
}
