<p><strong>Allgemeine Gesch&auml;ftsbedingungen</strong></p> <strong>1.&nbsp;Geltungsbereich</strong> <p>Für alle
  Bestellungen
  über unseren Online-Shop durch Verbraucher und Unternehmer gelten die nachfolgenden AGB.</p><p>Verbraucher ist jede
  natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer
  selbständigen beruflichen Tätigkeit zugerechnet werden können. Unternehmer ist eine natürliche oder juristische Person
  oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen
  oder selbständigen beruflichen Tätigkeit handelt. </p><p>Gegenüber Unternehmern gilt: Verwendet der Unternehmer
  entgegenstehende oder ergänzende Allgemeine Geschäftsbedingungen, wird deren Geltung hiermit widersprochen; sie werden
  nur dann Vertragsbestandteil, wenn wir dem ausdrücklich zugestimmt haben. </p> <strong>2.&nbsp;Vertragspartner,
  Vertragsschluss, Korrekturmöglichkeiten</strong> <p>Der Kaufvertrag kommt zustande mit {{shop.current.name}}
  ({{ shop.current.address.company}}).</p> <p>Mit
  Einstellung der Produkte in den Online-Shop geben wir ein verbindliches Angebot zum Vertragsschluss &uuml;ber diese
  Artikel ab. Sie k&ouml;nnen unsere Produkte zun&auml;chst unverbindlich in den Warenkorb legen und Ihre Eingaben vor
  Absenden Ihrer verbindlichen Bestellung jederzeit korrigieren, indem Sie die hierf&uuml;r im Bestellablauf
  vorgesehenen und erl&auml;uterten Korrekturhilfen nutzen. Der Vertrag kommt zustande, indem Sie durch Anklicken des
  Bestellbuttons das Angebot &uuml;ber die im Warenkorb enthaltenen Waren annehmen. Unmittelbar nach dem Absenden der
  Bestellung erhalten Sie noch einmal eine Best&auml;tigung per E-Mail.</p> <strong>3.&nbsp;Vertragssprache,
  Vertragstextspeicherung</strong> <p>Die f&uuml;r den Vertragsschluss zur Verf&uuml;gung stehende(n) Sprache(n):
  Deutsch</p> <p>Der Vertragstext wird von uns nicht gespeichert.</p> <strong>4.&nbsp;Lieferbedingungen</strong> <p>Zuz&uuml;glich
  zu den angegebenen Produktpreisen k&ouml;nnen noch Versandkosten anfallen. N&auml;here Bestimmungen zu ggf.
  anfallenden Versandkosten erfahren Sie bei den Angeboten.</p> <p>Sie haben grunds&auml;tzlich die M&ouml;glichkeit der
  Abholung bei {{shop.current.name}} ({{ shop.current.address.company}}
  ), {{shop.current.address.street}} {{shop.current.address.number}}, {{shop.current.address.code}}
  und {{shop.current.address.city}}, {{shop.current.address.country}} zu den nachfolgend angegebenen Gesch&auml;ftszeiten:
  Siehe Infodialog auf der Shopseite.</p> <p>Wir liefern nicht an Packstationen.</p> <strong>5.&nbsp;Bezahlung</strong>
<p>In unserem Shop stehen Ihnen
  grunds&auml;tzlich die folgenden Zahlungsarten zur Verf&uuml;gung:</p> <p><strong>PayPal</strong><br/> Im
  Bestellprozess werden Sie auf die Webseite des Online-Anbieters PayPal weitergeleitet. Um den Rechnungsbetrag &uuml;ber
  PayPal bezahlen zu k&ouml;nnen, m&uuml;ssen Sie dort registriert sein bzw. sich erst registrieren, mit Ihren
  Zugangsdaten legitimieren und die Zahlungsanweisung an uns best&auml;tigen. Nach Abgabe der Bestellung im Shop fordern
  wir PayPal zur Einleitung der Zahlungstransaktion auf. Die Zahlungstransaktion wird durch PayPal unmittelbar danach
  automatisch durchgef&uuml;hrt. Weitere Hinweise erhalten Sie beim Bestellvorgang. </p><p>
  <strong>Rechnung</strong><br/> Sie zahlen den Rechnungsbetrag nach Erhalt der Ware und der Rechnung per &Uuml;berweisung
  auf unser Bankkonto. Wir behalten uns vor, den Kauf auf Rechnung nur nach einer erfolgreichen Bonit&auml;tspr&uuml;fung
  anzubieten.</p> <p><strong>Barzahlung bei Abholung </strong><br/> Sie zahlen den Rechnungsbetrag bei der Abholung bar.
</p> <strong>6.&nbsp;Widerrufsrecht</strong> <p>Verbrauchern steht das gesetzliche Widerrufsrecht, wie in der
  Widerrufsbelehrung
  beschrieben, zu. Unternehmern wird kein freiwilliges Widerrufsrecht einger&auml;umt.</p> <strong>7.&nbsp;Eigentumsvorbehalt</strong>
<p>Die Ware bleibt bis zur vollst&auml;ndigen Bezahlung unser Eigentum. <br/> F&uuml;r Unternehmer gilt erg&auml;nzend:
  Wir behalten uns das Eigentum an der Ware bis zur vollst&auml;ndigen Begleichung aller Forderungen aus einer laufenden
  Gesch&auml;ftsbeziehung vor. Sie d&uuml;rfen die Vorbehaltsware im ordentlichen Gesch&auml;ftsbetrieb weiterver&auml;u&szlig;ern;
  s&auml;mtliche aus diesem Weiterverkauf entstehenden Forderungen treten Sie &ndash; unabh&auml;ngig von einer
  Verbindung oder Vermischung der Vorbehaltsware mit einer neuen Sache - in H&ouml;he des Rechnungsbetrages an uns im
  Voraus ab, und wir nehmen diese Abtretung an. Sie bleiben zur Einziehung der Forderungen erm&auml;chtigt, wir d&uuml;rfen
  Forderungen jedoch auch selbst einziehen, soweit Sie Ihren Zahlungsverpflichtungen nicht nachkommen. </p> <strong>8.&nbsp;Gew&auml;hrleistung
  und Garantien</strong> <p>Soweit nicht nachstehend ausdr&uuml;cklich anders vereinbart, gilt das gesetzliche M&auml;ngelhaftungsrecht.
  <br/> Beim Kauf gebrauchter Waren durch Verbraucher gilt: wenn der Mangel nach Ablauf eines Jahres ab Ablieferung der
  Ware auftritt, sind die M&auml;ngelanspr&uuml;che ausgeschlossen. M&auml;ngel, die innerhalb eines Jahres ab
  Ablieferung der Ware auftreten, k&ouml;nnen im Rahmen der gesetzlichen Verj&auml;hrungsfrist von zwei Jahren ab
  Ablieferung der Ware geltend gemacht werden. <br/> F&uuml;r Unternehmer betr&auml;gt die Verj&auml;hrungsfrist f&uuml;r
  M&auml;ngelanspr&uuml;che bei neu hergestellten Sachen ein Jahr ab Gefahr&uuml;bergang. Der Verkauf gebrauchter Waren
  erfolgt unter Ausschluss jeglicher Gew&auml;hrleistung. Die gesetzlichen Verj&auml;hrungsfristen f&uuml;r den R&uuml;ckgriffsanspruch
  nach &sect; 445a BGB bleiben unber&uuml;hrt.<br/> Gegen&uuml;ber Unternehmern gelten als Vereinbarung &uuml;ber die
  Beschaffenheit der Ware nur unsere eigenen Angaben und die Produktbeschreibungen des Herstellers, die in den Vertrag
  einbezogen wurden; f&uuml;r &ouml;ffentliche &Auml;u&szlig;erungen des Herstellers oder sonstige Werbeaussagen &uuml;bernehmen
  wir keine Haftung. <br/> Ist die gelieferte Sache mangelhaft, leisten wir gegen&uuml;ber Unternehmern zun&auml;chst
  nach unserer Wahl Gew&auml;hr durch Beseitigung des Mangels (Nachbesserung) oder durch Lieferung einer mangelfreien
  Sache (Ersatzlieferung). <br/> Die vorstehenden Einschr&auml;nkungen und Fristverk&uuml;rzungen gelten nicht f&uuml;r
  Anspr&uuml;che aufgrund von Sch&auml;den, die durch uns, unsere gesetzlichen Vertreter oder Erf&uuml;llungsgehilfen
  verursacht wurden<br/> &bull; bei Verletzung des Lebens, des K&ouml;rpers oder der Gesundheit<br/> &bull; bei vors&auml;tzlicher
  oder grob fahrl&auml;ssiger Pflichtverletzung sowie Arglist<br/> &bull; bei Verletzung wesentlicher Vertragspflichten,
  deren Erf&uuml;llung die ordnungsgem&auml;&szlig;e Durchf&uuml;hrung des Vertrages &uuml;berhaupt erst erm&ouml;glicht
  und auf deren Einhaltung der Vertragspartner regelm&auml;&szlig;ig vertrauen darf (Kardinalpflichten)<br/> &bull; im
  Rahmen eines Garantieversprechens, soweit vereinbart, oder<br/> &bull; soweit der Anwendungsbereich des
  Produkthaftungsgesetzes er&ouml;ffnet ist.<br/> Informationen zu gegebenenfalls geltenden zus&auml;tzlichen Garantien
  und deren genaue Bedingungen finden Sie jeweils beim Produkt und auf besonderen Informationsseiten im
  Online-Shop.<br/></p> <strong>9.&nbsp;Haftung</strong> <p>F&uuml;r Anspr&uuml;che aufgrund von Sch&auml;den, die durch
  uns,
  unsere gesetzlichen Vertreter oder Erf&uuml;llungsgehilfen verursacht wurden, haften wir stets unbeschr&auml;nkt<br/>
  &bull; bei Verletzung des Lebens, des K&ouml;rpers oder der Gesundheit<br/> &bull; bei vors&auml;tzlicher oder grob
  fahrl&auml;ssiger Pflichtverletzung<br/> &bull; bei Garantieversprechen, soweit vereinbart, oder<br/> &bull; soweit
  der Anwendungsbereich des Produkthaftungsgesetzes er&ouml;ffnet ist.<br/> Bei Verletzung wesentlicher
  Vertragspflichten, deren Erf&uuml;llung die ordnungsgem&auml;&szlig;e Durchf&uuml;hrung des Vertrages &uuml;berhaupt
  erst erm&ouml;glicht und auf deren Einhaltung der Vertragspartner regelm&auml;&szlig;ig vertrauen darf,
  (Kardinalpflichten) durch leichte Fahrl&auml;ssigkeit von uns, unseren gesetzlichen Vertretern oder Erf&uuml;llungsgehilfen
  ist die Haftung der H&ouml;he nach auf den bei Vertragsschluss vorhersehbaren Schaden begrenzt, mit dessen Entstehung
  typischerweise gerechnet werden muss. <br/> Im &Uuml;brigen sind Anspr&uuml;che auf Schadensersatz
  ausgeschlossen.<br/></p> <strong>10.&nbsp;Streitbeilegung</strong> <p>Die Europ&auml;ische Kommission stellt eine
  Plattform
  zur Online-Streitbeilegung (OS) bereit, die Sie <a href="https://ec.europa.eu/consumers/odr/" target="_blank">hier</a>
  finden. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht
  verpflichtet und nicht bereit. </p><strong>11.&nbsp;Jugendschutz</strong> <p>Sofern Ihre Bestellung Waren umfasst,
  deren
  Verkauf Altersbeschr&auml;nkungen unterliegt, stellen wir durch den Einsatz eines zuverl&auml;ssigen Verfahrens unter
  Einbeziehung einer pers&ouml;nlichen Identit&auml;ts- und Alterspr&uuml;fung sicher, dass der Besteller das
  erforderliche Mindestalter erreicht hat. Der Zusteller &uuml;bergibt die Ware erst nach erfolgter Alterspr&uuml;fung
  und nur an den Besteller pers&ouml;nlich.</p> <strong>12.&nbsp;Schlussbestimmungen</strong> <p>Sind Sie Unternehmer,
  dann gilt
  deutsches Recht unter Ausschluss des UN-Kaufrechts.</p> <p>Sind Sie Kaufmann im Sinne des Handelsgesetzbuches,
  juristische Person des &ouml;ffentlichen Rechts oder &ouml;ffentlich-rechtliches Sonderverm&ouml;gen, ist ausschlie&szlig;licher
  Gerichtsstand f&uuml;r alle Streitigkeiten aus Vertragsverh&auml;ltnissen zwischen uns und Ihnen unser Gesch&auml;ftssitz. </p>
<br>
