import {Component} from '@angular/core';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {ApiConfig} from '../../../../services/api/api.config';
import {first} from 'rxjs/operators';
import {Fee} from '../../../../models/fee.model';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-list-fees',
  templateUrl: './list-fees.component.html',
  styleUrls: ['./list-fees.component.css']
})
export class ListFeesComponent {

  selectedFee: Fee;

  constructor(public dashboard: DashboardService, public config: ApiConfig, private alert: ToastrService) {
  }

  deleteFee(fee) {
    this.dashboard.deletePaymentFee(fee)
      .pipe(first())
      .subscribe(
        result => {
          this.dashboard.paymentFees.splice(this.dashboard.paymentFees.indexOf(fee), 1);
          this.alert.success('Die Zahlungsgebühr wurde erfgolreich gelöscht.');
        },
        error => {
          this.alert.warning(error);
        }
      );
  }
}
