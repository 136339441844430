import {Component} from '@angular/core';
import {AuthService} from '../../../services/api/auth.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MustMatch} from '../../../helper/formmatch.helper';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent {

  passwordForm: UntypedFormGroup;
  loading = false

  constructor(public auth: AuthService, private alert: ToastrService, private formBuilder: UntypedFormBuilder) {
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_repeat: ['', [Validators.required, Validators.minLength(6)]]
    }, {validator: MustMatch('password', 'password_repeat')});
  }

  get form(): any {
    return this.passwordForm.controls;
  }

  updatePassword(): void {
    if (this.passwordForm.invalid) return;

    this.loading = true;

    this.auth.updatePassword(this.form.password.value)
      .pipe(first())
      .subscribe(
        result => {
          this.alert.success(result.message);
          this.passwordForm.reset();
          this.loading = false;
        },
        error => {
          this.alert.error(error);
          this.loading = false;
        }
      );
  }
}
