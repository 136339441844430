import {Injectable, OnDestroy} from '@angular/core';
import {Shop} from '../../models/shop.model';
import {Category} from '../../models/category.model';
import {ApiConfig} from './api.config';
import {Product} from '../../models/product.model';
import {catchError, first} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ShippingMethod} from '../../models/shippingmethod.model';
import {PaymentMethod} from '../../models/payment-method.model';
import {OrderCreate, OrderCreationResponse} from '../../models/order.model';
import {Response} from '../../models/response.model';
import {DeliverySlot} from '../../models/deliveryslot.model';
import {ToastrService} from 'ngx-toastr';
import {BehaviorSubject, Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShopService implements OnDestroy {

  current: Shop;
  cart: Product[] = [];
  sub: Subscription;

  products: Product[] = [];
  categories: Category[] = [];
  shippingMethods: ShippingMethod[] = [];
  paymentMethods: PaymentMethod[] = [];
  deliverySlots: DeliverySlot[] = [];

  selectedShippingMethod: ShippingMethod;
  selectedPaymentMethod: PaymentMethod;

  updateShopListener: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  responses = {
    metadata: false,
    categories: false,
    products: false,
    shippingMethods: false,
    paymentMethods: false,
    deliverySlots: false
  };

  constructor(private http: HttpClient, public config: ApiConfig, private alert: ToastrService) {
  }

  get canPerformOrder(): boolean {
    return !!this.products.length && !!this.categories.length && !!this.shippingMethods.length && !!this.paymentMethods.length;
  }

  get availableDeliverySlots(): boolean {
    if (!this.responses.deliverySlots) return true;
    return !!this.deliverySlots.length;
  }

  get currencySymbol() {
    return this.current.currencySymbol;
  }

  sortedProductAdditiveStrings(product: Product): { allergens: string, additives: string } {
    let allergens: string = "";
    let additives: string = "";

    product.additives.forEach((element) => {
      if(element.allergen){
        if(allergens.length) allergens += ', ';
        allergens += element.name;
      }else{
        if(additives.length) additives += ', ';
        additives += element.name;
      }
    });

    return {allergens: allergens, additives: additives};
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  subscribe() {
    this.sub = this.updateShopListener.subscribe(_ => {
      this.cart = this.getCart();
      this.loadAllCategories();
      this.loadAllProducts();
      this.loadAllShippingMethods();
      this.loadAllPaymentMethods();
      this.loadAllDeliverySlots();
    });
  }

  loadAllProducts() {
    if (this.responses.products && !!this.products?.length) return;

    this.getAllProducts()
      .pipe(first())
      .subscribe(
        result => {
          this.products = result;
          this.responses.products = true;
          this.sanitizeCart();
        },
        error => {
          this.alert.error(error);
          this.responses.products = true;
          this.products = [];
        }
      );
  }

  loadAllCategories() {
    if (this.responses.categories && !!this.categories?.length) return;

    this.getAllCategories()
      .pipe(first())
      .subscribe(
        result => {
          this.categories = result;
          this.responses.categories = true;
        },
        error => {
          this.alert.error(error);
          this.responses.categories = true;
          this.categories = [];
        }
      );
  }

  loadAllShippingMethods() {
    if (this.responses.shippingMethods && !!this.shippingMethods?.length) return;

    this.getAllShippingMethods()
      .pipe(first())
      .subscribe(
        result => {
          this.shippingMethods = result;
          this.responses.shippingMethods = true;
        },
        error => {
          this.alert.error(error);
          this.responses.shippingMethods = true;
          this.shippingMethods = [];
        }
      );
  }

  loadAllPaymentMethods() {
    if (this.responses.paymentMethods && !!this.paymentMethods?.length) return;

    this.getAllPaymentMethods()
      .pipe(first())
      .subscribe(
        result => {
          this.paymentMethods = result;
          this.responses.paymentMethods = true;
        },
        error => {
          this.alert.error(error);
          this.responses.paymentMethods = true;
          this.paymentMethods = [];
        }
      );
  }

  loadAllDeliverySlots() {
    if (this.responses.deliverySlots && !!this.deliverySlots?.length) return;

    this.getAllDeliverySlots()
      .pipe(first())
      .subscribe(
        result => {
          this.deliverySlots = result;
          this.responses.deliverySlots = true;
        },
        error => {
          this.alert.error(error);
          this.responses.deliverySlots = true;
          this.deliverySlots = [];
        }
      );
  }

  getDay(input): string {
    switch (input) {
      case '0':
        return 'Sonntag';
      case '1':
        return 'Montag';
      case '2':
        return 'Dienstag';
      case '3':
        return 'Mittwoch';
      case '4':
        return 'Donnerstag';
      case '5':
        return 'Freitag';
      case '6':
        return 'Samstag';
    }
  }

  roundNumber(number) {
    return parseFloat((Math.round((number + Number.EPSILON) * 100) / 100).toFixed(2));
  }

  /* API Functions */

  createOrder(order: OrderCreate) {
    return this.http.post<OrderCreationResponse>(this.config.apiUrl + 'order/create/', order).pipe(catchError(this.config.handleError));
  }

  capturePayment(token: string) {
    return this.http.post<Response>(this.config.apiUrl + 'order/capture-payment/', {token: token}).pipe(catchError(this.config.handleError));
  }

  getAllShippingMethods() {
    return this.http.get<ShippingMethod[]>(this.config.apiUrl + 'shop/' + this.current.uuid + '/shipping-methods/').pipe(catchError(this.config.handleError));
  }

  getAllProducts() {
    return this.http.get<Product[]>(this.config.apiUrl + 'shop/' + this.current.uuid + '/products/').pipe(catchError(this.config.handleError));
  }

  getAllCategories() {
    return this.http.get<Category[]>(this.config.apiUrl + 'shop/' + this.current.uuid + '/categories/').pipe(catchError(this.config.handleError));
  }

  getAllPaymentMethods() {
    return this.http.get<PaymentMethod[]>(this.config.apiUrl + 'shop/' + this.current.uuid + '/payment-methods/').pipe(catchError(this.config.handleError));
  }

  getAllDeliverySlots() {
    return this.http.get<DeliverySlot[]>(this.config.apiUrl + 'shop/' + this.current.uuid + '/delivery-slots/').pipe(catchError(this.config.handleError));
  }

  /* PaymentMethod Functions */

  getFeeSum(cartSum): number {
    if (this.selectedPaymentMethod && this.selectedPaymentMethod.fee)
      if (this.selectedPaymentMethod.fee.fixed) {
        return this.selectedPaymentMethod.fee.value;
      } else {
        return this.roundNumber(this.selectedPaymentMethod.fee.value * Math.pow(10, -2) * cartSum);
      }

    return 0.0;
  }

  selectPayment(method) {
    this.selectedPaymentMethod = method;
  }

  /* Cart Functions */

  addProductToCart(product: Product) {
    const cart = this.getCart();

    let contains = false;
    cart.forEach((element) => {
      if (element.uuid === product.uuid) {
        if (JSON.stringify(element.selectedAddons) === JSON.stringify(product.selectedAddons)) {
          if (element.taxclass.uuid === product.taxclass.uuid) {
            if (element.price === product.price) {

              const elementQuantity = element.quantity;
              const productQuantity = product.quantity;

              if (elementQuantity + productQuantity < 1) {
                product.quantity = 1;
                contains = false;
              } else {
                element.quantity += productQuantity;
                contains = true;
              }
            }
          }
        }
      }
    });

    if (!contains) cart.push(product);

    this.setCart(cart);
    this.alert.success('Das Produkt wurde in den Warenkorb gelegt.', 'Warenkorb', {timeOut: 1500});
    this.updateShopListener.next();
  }

  updateProductCartQuantity(index, number) {
    const cart = this.getCart();

    cart[index].quantity += number;
    if (cart[index].quantity < 1) cart.splice(index, 1);

    this.setCart(cart);
    this.updateShopListener.next();
  }


  sanitizeCart() {
    let cartLength = this.getCart().length;
    this.getCart().forEach((product, index) => {
      if (!this.productHasValidMeta(product) || !this.productHasValidAddons(product) || product.quantity < 1) this.updateProductCartQuantity(index, -(product.quantity));
    });

    let cart = this.getCart();
    cart.forEach((product, index) => {
      product.quantity = Math.floor(product.quantity);
    });

    this.setCart(cart);

    if (this.getCart().length !== cartLength) this.alert.warning('Es wurden automatisch Produkte aus Ihrem Warenkorb entfernt, die nicht mehr verfügbar sind.');
  }

  productHasValidMeta(product) {
    let valid = false;
    this.products.forEach((loadedProduct) => {
      if (loadedProduct.uuid === product.uuid) {
        if (loadedProduct.price === product.price) {
          if (loadedProduct.name === product.name) {
            valid = true;
          }
        }
      }
    });
    return valid;
  }

  productHasValidAddons(product) {

    let expectedAddonMinimum = 0;
    let availableAddonLists = [];
    this.products.forEach((availableProduct) => {
      availableProduct.addonlists.forEach((availableAddonList) => {
        if (availableProduct.uuid === product.uuid && !availableAddonList.multiselect) expectedAddonMinimum += 1;
        if (availableProduct.uuid === product.uuid) availableAddonLists.push(availableAddonList);
      });
    });

    if (!expectedAddonMinimum && !product.selectedAddons?.length) return true;

    let addonsValid = true;
    product.selectedAddons.forEach((selectedAddon) => {
      let addonValid = false;
      availableAddonLists.forEach((availableAddonList) => {
        if (availableAddonList.name === selectedAddon.addonlistName) {
          availableAddonList.addons.forEach((availableAddon) => {
            if (selectedAddon.uuid === availableAddon.uuid) {
              if (selectedAddon.price === availableAddon.price) {
                if (selectedAddon.name === availableAddon.name) {
                  addonValid = true;
                }
              }
            }
          });
        }
      });
      if (!addonValid) addonsValid = false;
    });

    if (!addonsValid) return false;
    if (!expectedAddonMinimum) return true;

    let addonSelectValid = true;
    availableAddonLists.forEach((availableAddonList) => {
      let selectedAddons = [];
      product.selectedAddons.forEach((selectedAddon) => {
        if (selectedAddon.addonlist === availableAddonList.uuid) selectedAddons.push(selectedAddon);
      });

      if (!availableAddonList.multiselect && (selectedAddons.length > 1 || selectedAddons.length === 0)) addonSelectValid = false;
    });

    return addonSelectValid;
  }

  getSortedProductAddons(product) {
    if (!product.selectedAddons?.length) return [];

    const sorted = [];

    product.selectedAddons?.forEach((selectedAddon) => {
      let contains = false;
      let addon = {
        title: selectedAddon.addonlistName,
        addons: [{
          name: selectedAddon.name,
          price: selectedAddon.price
        }]
      }

      sorted.forEach((sortedElement) => {
        if (sortedElement.title === addon.title) {
          sortedElement.addons.push(addon.addons[0]);
          contains = true;
        }
      });

      if (!contains) sorted.push(addon);
    });

    return sorted;
  }

  getCartSum(raw = false): number {
    let sum = 0.0;

    this.cart.forEach((element) => {
      sum += this.getProductTotal(element);
    });

    if (!raw) {
      sum += this.getShippingSum();
      sum += this.getFeeSum(sum);
    }

    return sum;
  }

  getProductTotal(product: Product): number {
    let price = product.price;

    if (product.selectedAddons) {
      product.selectedAddons.forEach((element) => {
        price += element.price;
      });
    }

    return price * product.quantity;
  }

  getCartLength(): number {
    let length = 0;
    this.getCart().forEach((element) => {
      length += element.quantity;
    });

    return length;
  }

  clearCart() {
    localStorage.removeItem(this.current.shortname + '-cart');
    this.updateShopListener.next();
  }

  setCart(cart) {
    localStorage.setItem(this.current.shortname + '-cart', JSON.stringify(cart));
  }

  getCart(): Product[] {
    const cart = JSON.parse(localStorage.getItem(this.current.shortname + '-cart'));
    return cart ? cart : [];
  }

  /* ShippingMethod Functions */

  canActivateAnyShippingMethod(postal: string): boolean {
    let activate = false;
    this.shippingMethods.forEach((element) => {
      if (element.pickup) return activate = true;

      element.codelist.codes.forEach((code) => {
        if (code === postal) activate = true;
      })
    });

    return activate;
  }

  getDeliveryMethodHints(postal: string): ShippingMethod[] {
    let methods: ShippingMethod[] = []

    this.shippingMethods.forEach((element) => {
      if(this.canActivateShippingMethod(postal,element)) return;

      const code = element.codelist?.codes?.find(x => x === postal);
      const cheaper = this.shippingMethods.find(x => (x.price < element.price) && (x.uuid !== element.uuid));

      if(code && !cheaper) methods.push(element);
    });

    return methods;
  }

  canActivateCheaperShippingMethod(postal: string, method: ShippingMethod): boolean {
    let activate = false;
    this.shippingMethods.forEach((element) => {
      if (element.pickup) return;

      if (element.uuid !== method.uuid && element.price < method.price && this.getCartSum(true) >= element.minordervalue) {
        element.codelist.codes.forEach((code) => {
          if (code === postal) activate = true;
        })
      }
    });

    return activate;
  }

  canActivateShippingMethod(postal: string, method: ShippingMethod): boolean {
    if (method.pickup) return true;

    let activate = false;
    method.codelist.codes.forEach((element) => {
      if (element === postal && this.getCartSum(true) >= method.minordervalue && !this.canActivateCheaperShippingMethod(postal, method)) activate = true;
    });

    return activate;
  }

  selectShipping(shipping) {
    this.selectedShippingMethod = shipping;
  }

  getShippingSum(): number {
    return this.selectedShippingMethod?.price ?? 0.0;
  }
}
