import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {CodeList} from '../../../../models/codelist.model';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-add-code',
  templateUrl: './code-manager.component.html',
  styleUrls: ['./code-manager.component.css']
})
export class CodeManagerComponent implements OnInit {
  @Input() selectedList: CodeList;

  codeForm: UntypedFormGroup;

  constructor(private router: Router, private formBuilder: UntypedFormBuilder, public dashboard: DashboardService, private alert: ToastrService) {
  }

  get form(): any {
    return this.codeForm.controls;
  }

  ngOnInit(): void {
    this.codeForm = this.formBuilder.group({
      code: ['', Validators.required]
    });
  }

  addCodeToList(): void {
    if (this.codeForm.invalid) return;

    this.dashboard.addCodeToCodeList(this.form.code.value, this.selectedList)
      .pipe(first())
      .subscribe(
        result => {
          this.selectedList.codes.push(this.form.code.value);
          this.codeForm.reset();
        },
        error => {
          this.alert.error(error);
        }
      );
  }

  removeCodeFromList(code): void {
    this.dashboard.removeCodeFromCodeList(code, this.selectedList)
      .pipe(first())
      .subscribe(
        result => {
          this.selectedList.codes.splice(this.selectedList.codes.indexOf(code), 1);
        },
        error => {
          this.alert.error(error);
        }
      );
  }
}
