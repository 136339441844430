<div class="row">
  <div class="col-lg-6 mt-2">
    <input (input)="updateSearchString($event)" class="form-control" placeholder="Suche..." type="text">
  </div>
  <div class="col-lg-6 mt-2">
    <button (click)="selectedProduct = undefined"
            class="btn btn-success btn-block text-uppercase shadow-sm float-end text-center"
            data-bs-target="#createModal" data-bs-toggle="modal"><i class="fas fa-plus"></i> Neues
      Produkt
    </button>
  </div>
</div>

<app-loading *ngIf="!dashboard.responses.products; else productsTemplate"></app-loading>

<ng-template #productsTemplate>
  <ng-container *ngIf="dashboard.products.length; else nothingFound">
    <ul class="list-group mt-5 mb-5">
      <li *ngFor="let product of dashboard.filterArrayObjects(dashboard.products,searchString);"
          class="list-group-item">
        <div class="row">
          <div class="col-lg-4 p-0 py-2">
            <h4 class="fw-light">{{product.name}}</h4>
            <p *ngIf="product.available" class="text-success"><i class="fas fa-check-circle"></i> Verfügbar</p>
            <p *ngIf="!product.available" class="text-danger"><i class="fas fa-times-circle"></i> Nicht Verfügbar</p>
            <p class="text-muted mb-2">{{product.description}}</p>
            <div class="btn-group" role="group">
              <button class="btn btn-light" type="button"><i class="fas fa-list"></i> <b> {{product.category.name}}</b>
              </button>
              <button class="btn btn-light" type="button"><i
                class="fas fa-tags"></i><b> {{product.price | number:'1.2-2'}}{{dashboard.currencySymbol}}</b>
                ({{product.taxclass.name}})
              </button>
            </div>
          </div>
          <div class="col-lg-4">
            <img *ngIf="product.image?.url" class="img-fluid mx-auto d-block"
                 src="{{config.fileUrl + product.image?.url}}">
          </div>
          <div class="col-lg-4">
            <button (click)="selectedProduct = product"
                    class="btn btn-dark btn-block text-uppercase shadow-sm float-end text-center"
                    data-bs-target="#createModal" data-bs-toggle="modal"><i
              class="fas fa-sliders-h"></i></button>
            <button (click)="deleteProduct(product)"
                    class="btn btn-danger btn-block text-uppercase shadow-sm float-end text-center me-2"><i
              class="fas fa-trash"></i></button>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>
  <ng-template #nothingFound>
    <app-nothing-found></app-nothing-found>
  </ng-template>
</ng-template>

<div aria-hidden="true" class="modal fade" id="createModal" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="container p-5 pt-0">
        <app-create-product [selectedProduct]="selectedProduct"></app-create-product>
      </div>
    </div>
  </div>
</div>

