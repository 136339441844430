import {Component} from '@angular/core';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {ApiConfig} from '../../../../services/api/api.config';
import {first} from 'rxjs/operators';
import {ShippingMethod} from '../../../../models/shippingmethod.model';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-list-shipping-methods',
  templateUrl: './list-shipping-methods.component.html',
  styleUrls: ['./list-shipping-methods.component.css']
})
export class ListShippingMethodsComponent {

  selectedMethod: ShippingMethod;

  constructor(public dashboard: DashboardService, public config: ApiConfig, private alert: ToastrService) {
  }

  deleteMethod(method) {
    this.dashboard.deleteShippingMethod(method)
      .pipe(first())
      .subscribe(
        result => {
          this.dashboard.shippingMethods.splice(this.dashboard.shippingMethods.indexOf(method), 1);
        },
        error => {
          this.alert.warning(error);
        }
      );
  }
}
