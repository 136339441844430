<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <a [routerLink]="['/dashboard', dashboard.selectedShop.shortname, 'settings']" class="navbar-brand"><img
      alt="" class="d-inline-block align-top" height="35" src="/assets/images/motion-circle-logo-dark.png"></a>
    <button aria-controls="navbarContent" aria-expanded="false" class="navbar-toggler" data-bs-target="#navbarContent"
            data-bs-toggle="collapse" type="button">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a [routerLink]="['/dashboard', dashboard.selectedShop.shortname, 'orders']" class="nav-link"
             routerLinkActive="active">Bestellungen</a>
        </li>
        <li class="nav-item dropdown">
          <a aria-expanded="false" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button">
            Produkte
          </a>
          <ul aria-labelledby="navbarDropdown" class="dropdown-menu">
            <li><a [routerLink]="['/dashboard', dashboard.selectedShop.shortname, 'products']" class="dropdown-item"
                   routerLinkActive="active">Produkte</a></li>
            <li><a [routerLink]="['/dashboard', dashboard.selectedShop.shortname, 'categories']" class="dropdown-item"
                   routerLinkActive="active">Kategorien</a></li>
            <li><a [routerLink]="['/dashboard', dashboard.selectedShop.shortname, 'addons']" class="dropdown-item"
                   routerLinkActive="active">Addonlisten</a></li>
            <li><a [routerLink]="['/dashboard', dashboard.selectedShop.shortname, 'additives']" class="dropdown-item"
                   routerLinkActive="active">Zusatzstoffe</a></li>
            <li><a [routerLink]="['/dashboard', dashboard.selectedShop.shortname, 'tax-classes']"
                   class="dropdown-item"
                   routerLinkActive="active">Steuerklassen</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a aria-expanded="false" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button">
            Lieferung
          </a>
          <ul aria-labelledby="navbarDropdown" class="dropdown-menu">
            <li><a [routerLink]="['/dashboard', dashboard.selectedShop.shortname, 'shipping-methods']"
                   class="dropdown-item"
                   routerLinkActive="active">Liefermethoden</a></li>
            <li><a [routerLink]="['/dashboard', dashboard.selectedShop.shortname, 'code-lists']" class="dropdown-item"
                   routerLinkActive="active">Liefergebiete</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a aria-expanded="false" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" role="button">
            Bezahlung
          </a>
          <ul aria-labelledby="navbarDropdown" class="dropdown-menu">
            <li><a [routerLink]="['/dashboard', dashboard.selectedShop.shortname, 'payment-methods']"
                   class="dropdown-item"
                   routerLinkActive="active">Zahlungsmethoden</a></li>
            <li><a [routerLink]="['/dashboard', dashboard.selectedShop.shortname, 'fees']" class="dropdown-item"
                   routerLinkActive="active">Gebühren</a></li>
          </ul>
        </li>

        <li class="nav-item">
          <a [routerLink]="['/dashboard', dashboard.selectedShop.shortname, 'media']" class="nav-link"
             routerLinkActive="active">Bilder</a>
        </li>

        <li class="nav-item">
          <a [routerLink]="['/dashboard', dashboard.selectedShop.shortname, 'settings']" class="nav-link"
             routerLinkActive="active">Einstellungen</a>
        </li>

      </ul>
      <form class="d-flex">
        <a class="btn btn-dark me-2" routerLink="/account"><i class="fas fa-user"></i> Account</a>
      </form>
    </div>
  </div>
</nav>
<div class="container mt-4 mt-lg-5 p-4 p-lg-3">
  <router-outlet></router-outlet>
</div>

