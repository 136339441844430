import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/api/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  loading = false;

  constructor(public auth: AuthService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.auth.redirectToAccount()
      .then(
        result => {
          this.loading = result;
        }
      );
  }
}
