<div class="row">
  <div class="col-6 mb-4">
    <img class="img-fluid" src="/assets/images/motion-circle-logo-dark.png" style="max-width: 200px;">
  </div>
  <div class="col-6 align-middle">
    <a (click)="auth.logout()" class="btn btn-danger btn-block float-end"><i class="fas fa-sign-out-alt"></i></a>
    <a class="btn btn-dark btn-block float-end me-2" routerLink="/account/settings"><i class="fas fa-cogs"></i></a>
  </div>
</div>
<ng-container *ngIf="!auth.user.admin; else adminView">
  <div class="row">
    <div class="col-lg-6 mb-4 align-items-stretch">
      <a class="text-secondary" routerLink="/account/new-shop">
        <div class="card border-0 shadow selection-card">
          <div class="card-body">
            <i class="fas fa-plus shop-icon mb-2"></i>
            <h5 class="card-title">Neuen Shop Erstellen</h5>
          </div>
        </div>
      </a>
    </div>
    <div *ngFor="let shop of auth.user.shops;" class="col-lg-6 mb-4 align-items-stretch">
      <a class="text-dark" routerLink="/dashboard/{{shop.shortname}}/settings">
        <div class="card bg-dark text-light border-0 shadow selection-card">
          <div class="card-body">
            <i class="fas fa-store-alt shop-icon mb-2"></i>
            <h5 class="card-title">{{shop.name}}</h5>
          </div>
        </div>
      </a>
    </div>
  </div>
</ng-container>

<ng-template #adminView>
  <div class="row">
    <div class="col-12">
      <a class="text-dark" routerLink="/admin">
        <div class="card bg-success text-light border-0 shadow selection-card">
          <div class="card-body">
            <i class="fas fa-user-shield shop-icon mb-2"></i>
            <h5 class="card-title">Admin-Dashboard</h5>
          </div>
        </div>
      </a>
    </div>
  </div>
</ng-template>
