export class Fee {
  shop: string;
  uuid: string;
  name: string;
  description: string;
  fixed: boolean;
  value: number;

  constructor(shop: string, uuid: string, name: string, description: string, fixed: boolean, value: number) {
    this.shop = shop;
    this.uuid = uuid;
    this.name = name;
    this.description = description;
    this.fixed = fixed;
    this.value = value;
  }
}
