import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/api/auth.service';
import {RecoveryComplete} from '../../../models/recovery.complete.model';
import {MustMatch} from '../../../helper/formmatch.helper';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-complete-recovery',
  templateUrl: './complete-recovery.component.html',
  styleUrls: ['./complete-recovery.component.css']
})
export class CompleteRecoveryComponent implements OnInit, OnDestroy {

  recoveryForm: UntypedFormGroup;
  loading = false;

  recoveryToken: string;
  private sub: any;

  constructor(private auth: AuthService, private route: ActivatedRoute, private router: Router, private formBuilder: UntypedFormBuilder, private alert: ToastrService) {
  }

  get form(): any {
    return this.recoveryForm.controls;
  }

  ngOnInit() {
    this.recoveryForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_repeat: ['', [Validators.required, Validators.minLength(6)]]
    }, {validator: MustMatch('password', 'password_repeat')});

    this.sub = this.route.params.subscribe(params => {
      this.recoveryToken = params.token;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  createRecovery(): void {
    if (this.recoveryForm.invalid) return;

    this.loading = true;

    this.auth.completeRecovery(new RecoveryComplete(this.form.password.value, this.recoveryToken))
      .pipe(first())
      .subscribe(
        result => {
          this.alert.success(result.message);
          this.loading = false;
        },
        error => {
          this.alert.error(error);
          this.loading = false;
        }
      );
  }
}
