import {Component} from '@angular/core';
import {ShopService} from '../../../services/api/shop.service';
import {ApiConfig} from '../../../services/api/api.config';
import {Product} from '../../../models/product.model';
import {Category} from '../../../models/category.model';

@Component({
  selector: 'app-product-grid',
  templateUrl: './product-grid.component.html',
  styleUrls: ['./product-grid.component.css']
})
export class ProductGridComponent {

  selectedCategory: Category;
  selectedProduct: Product;

  constructor(public shop: ShopService, public config: ApiConfig) {
  }

  get filteredCategories() {
    for (let category of this.shop.categories) {
      if (category.uuid === this.selectedCategory?.uuid) return [category];
    }
    return this.shop.categories;
  }
}
