import {Component, Input} from '@angular/core';
import {Order} from '../../../../models/order.model';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {first} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {updateObject} from '../../../../helper/object.helper';

@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.css']
})
export class EditOrderComponent {
  @Input() selectedOrder: Order;

  loading = false;

  constructor(public dashboard: DashboardService, private alert: ToastrService) {
  }

  updateOrderStatus(status) {
    this.loading = true;

    this.dashboard.updateOrderStatus({order: this.selectedOrder.uuid, status: status})
      .pipe(first())
      .subscribe(
        result => {
          this.loading = false;
          updateObject(this.selectedOrder, result);
          this.alert.success('Die Bestellung wurde erfolgreich aktualisiert.');
        },
        error => {
          this.loading = false;
          this.alert.error(error);
        }
      );
  }
}
