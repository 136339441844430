<div class="row pt-2">
  <div class="col-12">
    <button (click)="selectedTaxClass = undefined"
            class="btn btn-success btn-block text-uppercase shadow-sm float-end text-center"
            data-bs-target="#createModal" data-bs-toggle="modal"><i class="fas fa-plus"></i> Neue
      Steuerklasse
    </button>
  </div>
</div>

<app-loading *ngIf="!dashboard.responses.taxClasses; else taxClassesTemplate"></app-loading>
<ng-template #taxClassesTemplate>
  <ng-container *ngIf="dashboard.taxClasses.length; else nothingFound">
    <ul class="list-group mt-5 mb-5">
      <li *ngFor="let taxclass of dashboard.taxClasses;" class="list-group-item">
        <div class="row">
          <div class="col-6 p-0 py-2">
            <h4>{{taxclass.name}}</h4>
            <p class="text-muted mb-2">Prozentsatz: {{taxclass.percentage}}%</p>
          </div>
          <div class="col-6 p-0">
            <button (click)="selectedTaxClass = taxclass"
                    class="btn btn-dark btn-block text-uppercase shadow-sm float-end text-center"
                    data-bs-target="#createModal" data-bs-toggle="modal"><i
              class="fas fa-sliders-h"></i></button>
            <button (click)="deleteTaxClass(taxclass)"
                    class="btn btn-danger btn-block text-uppercase shadow-sm float-end text-center me-2"><i
              class="fas fa-trash"></i></button>
          </div>
        </div>
      </li>
    </ul>
  </ng-container>
  <ng-template #nothingFound>
    <app-nothing-found></app-nothing-found>
  </ng-template>
</ng-template>

<div aria-hidden="true" class="modal fade" id="createModal" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="container p-5 pt-0">
        <app-create-tax-class [selectedTaxClass]="selectedTaxClass"></app-create-tax-class>
      </div>
    </div>
  </div>
</div>
