export class TaxClass {
  shop: string;
  uuid: string;
  name: string;
  percentage: number;

  constructor(shop: string, uuid: string, name: string, percentage: number) {
    this.shop = shop;
    this.uuid = uuid;
    this.name = name;
    this.percentage = percentage;
  }
}
