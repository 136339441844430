import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../services/api/auth.service';
import {Router} from '@angular/router';
import {Address} from '../../../../models/address.model';
import {first} from 'rxjs/operators';
import {DashboardService} from '../../../../services/api/dashboard.service';
import {ShopUpdate} from '../../../../models/shop.model';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-edit-shop',
  templateUrl: './edit-shop.component.html',
  styleUrls: ['./edit-shop.component.css']
})
export class EditShopComponent implements OnInit {

  shopForm: UntypedFormGroup;
  loading = false;

  constructor(private auth: AuthService, private router: Router, private formBuilder: UntypedFormBuilder, private alert: ToastrService, public dashboard: DashboardService) {
  }

  get form(): any {
    return this.shopForm.controls;
  }

  ngOnInit(): void {
    this.shopForm = this.formBuilder.group({
      name: [this.dashboard.selectedShop.name ?? '', Validators.required],
      logo: [this.dashboard.selectedShop.logo?.uuid ?? '', Validators.required],
      shortname: [this.dashboard.selectedShop.shortname ?? '', [Validators.required, Validators.minLength(6)]],
      description: [this.dashboard.selectedShop.description ?? '', Validators.required],
      address_name: [this.dashboard.selectedShop.address.name ?? '', Validators.required],
      address_surname: [this.dashboard.selectedShop.address.surname ?? '', Validators.required],
      company: [this.dashboard.selectedShop.address.company ?? '', Validators.required],
      street: [this.dashboard.selectedShop.address.street ?? '', Validators.required],
      number: [this.dashboard.selectedShop.address.number ?? '', Validators.required],
      code: [this.dashboard.selectedShop.address.code ?? '', Validators.required],
      city: [this.dashboard.selectedShop.address.city ?? '', Validators.required],
      email: [this.dashboard.selectedShop.address.email ?? '', [Validators.required, Validators.email]],
      phone: [this.dashboard.selectedShop.address.phone ?? '', Validators.required]
    });
  }

  updateShop(): void {
    if (this.shopForm.invalid) return;

    const addressModel = new Address(
      this.form.address_name.value,
      this.form.address_surname.value,
      this.form.company.value,
      this.form.street.value,
      this.form.number.value,
      this.form.code.value,
      this.form.city.value,
      undefined,
      this.form.email.value,
      this.form.phone.value
    );

    const shopModel = new ShopUpdate(
      this.form.logo.value,
      this.form.name.value,
      this.form.description.value,
      this.form.shortname.value,
      addressModel,
      undefined,
      undefined,
      undefined
    );

    this.loading = true;

    this.dashboard.updateShop(shopModel)
      .pipe(first())
      .subscribe(
        result => {
          this.alert.success('Die Shopeinstellungen wurden erfolgreich aktualisiert.');
          this.dashboard.selectedShop = result;
          this.loading = false;
        },
        error => {
          this.alert.error(error);
          this.loading = false;
        }
      )
  }
}
