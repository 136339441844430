import {Address} from './address.model';
import {ImageFile} from './file.model';
import {ShopHour} from './shophour.model';

export class Shop {
  uuid: string;
  owner: string;
  shortname: string;
  name: string; 
  description: string;
  address: Address;
  logo: ImageFile;
  active: boolean;
  open: boolean;
  api: string;
  createdAt: string;
  updatedAt: string;
  currency: string;
  currencySymbol: string;
  fullfillment: number;
  hours: ShopHour[];

  constructor(uuid: string, owner: string, shortname: string, description: string, address: Address, logo: ImageFile, active: boolean, open: boolean, api: string, currency: string, currencySymbol: string, fullfillment: number, hours: ShopHour[]) {
    this.uuid = uuid;
    this.owner = owner;
    this.shortname = shortname;
    this.description = description;
    this.address = address;
    this.logo = logo;
    this.active = active;
    this.open = open;
    this.api = api;
    this.currency = currency;
    this.currencySymbol = currencySymbol;
    this.fullfillment = fullfillment;
    this.hours = hours;
  }

}

export class ShopUpdate {
  logo: string;
  name: string;
  description: string;
  shortname: string;
  fullfillment: number;
  active: boolean;
  open: boolean;
  address: Address;

  constructor(logo: string, name: string, description: string, shortname: string, address: Address, active: boolean, open: boolean, fullfillment: number) {
    this.logo = logo;
    this.name = name;
    this.fullfillment = fullfillment;
    this.description = description;
    this.shortname = shortname;
    this.address = address;
    this.active = active;
    this.open = open;
  }
}

export class ShopCreate {
  name: string;
  description: string;
  shortname: string;
  currency: string;

  address: Address;

  constructor(name: string, description: string, shortname: string, address: Address, currency: string) {
    this.name = name;
    this.description = description;
    this.shortname = shortname;
    this.address = address;
    this.currency = currency;
  }
}
