import {Component, Input} from '@angular/core';
import {User} from '../../../models/user.model';

@Component({
  selector: 'app-user-shop-overview',
  templateUrl: './user-shop-overview.component.html',
  styleUrls: ['./user-shop-overview.component.css']
})
export class UserShopOverview {
  @Input() selectedUser: User;

  constructor() {
  }
}
